// @ts-nocheck
import client from "../../utils/axios";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { ResourceReservationGetExtendTimeOptionsQueryResponse, ResourceReservationGetExtendTimeOptionsPathParams, ResourceReservationGetExtendTimeOptions401 } from "../models/ResourceReservationGetExtendTimeOptions";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type ResourceReservationGetExtendTimeOptionsClient = typeof client<ResourceReservationGetExtendTimeOptionsQueryResponse, ResourceReservationGetExtendTimeOptions401, never>;
type ResourceReservationGetExtendTimeOptions = {
    data: ResourceReservationGetExtendTimeOptionsQueryResponse;
    error: ResourceReservationGetExtendTimeOptions401;
    request: never;
    pathParams: ResourceReservationGetExtendTimeOptionsPathParams;
    queryParams: never;
    headerParams: never;
    response: ResourceReservationGetExtendTimeOptionsQueryResponse;
    client: {
        parameters: Partial<Parameters<ResourceReservationGetExtendTimeOptionsClient>[0]>;
        return: Awaited<ReturnType<ResourceReservationGetExtendTimeOptionsClient>>;
    };
};
export const resourceReservationGetExtendTimeOptionsQueryKey = (id: ResourceReservationGetExtendTimeOptionsPathParams["id"]) => ["api", "v5", "resource-reservations", id, "extend-time-options"] as const;
export type ResourceReservationGetExtendTimeOptionsQueryKey = ReturnType<typeof resourceReservationGetExtendTimeOptionsQueryKey>;
export function resourceReservationGetExtendTimeOptionsQueryOptions(id: ResourceReservationGetExtendTimeOptionsPathParams["id"], options: ResourceReservationGetExtendTimeOptions["client"]["parameters"] = {}) {
    const queryKey = resourceReservationGetExtendTimeOptionsQueryKey(id);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ResourceReservationGetExtendTimeOptions["data"], ResourceReservationGetExtendTimeOptions["error"]>({
                method: "get",
                url: `/api/v5/resource-reservations/${id}/extend-time-options`,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 延长会议时间选项
 * @link /api/v5/resource-reservations/:id/extend-time-options
 */
export function useResourceReservationGetExtendTimeOptions<TData = ResourceReservationGetExtendTimeOptions["response"], TQueryData = ResourceReservationGetExtendTimeOptions["response"], TQueryKey extends QueryKey = ResourceReservationGetExtendTimeOptionsQueryKey>(id: ResourceReservationGetExtendTimeOptionsPathParams["id"], options: {
    query?: Partial<QueryObserverOptions<ResourceReservationGetExtendTimeOptions["response"], ResourceReservationGetExtendTimeOptions["error"], TData, TQueryData, TQueryKey>>;
    client?: ResourceReservationGetExtendTimeOptions["client"]["parameters"];
} = {}): UseQueryResult<TData, ResourceReservationGetExtendTimeOptions["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? resourceReservationGetExtendTimeOptionsQueryKey(id);
    const query = useQuery({
        ...resourceReservationGetExtendTimeOptionsQueryOptions(id, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, ResourceReservationGetExtendTimeOptions["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const resourceReservationGetExtendTimeOptionsSuspenseQueryKey = (id: ResourceReservationGetExtendTimeOptionsPathParams["id"]) => ["api", "v5", "resource-reservations", id, "extend-time-options"] as const;
export type ResourceReservationGetExtendTimeOptionsSuspenseQueryKey = ReturnType<typeof resourceReservationGetExtendTimeOptionsSuspenseQueryKey>;
export function resourceReservationGetExtendTimeOptionsSuspenseQueryOptions(id: ResourceReservationGetExtendTimeOptionsPathParams["id"], options: ResourceReservationGetExtendTimeOptions["client"]["parameters"] = {}) {
    const queryKey = resourceReservationGetExtendTimeOptionsSuspenseQueryKey(id);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ResourceReservationGetExtendTimeOptions["data"], ResourceReservationGetExtendTimeOptions["error"]>({
                method: "get",
                url: `/api/v5/resource-reservations/${id}/extend-time-options`,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 延长会议时间选项
 * @link /api/v5/resource-reservations/:id/extend-time-options
 */
export function useResourceReservationGetExtendTimeOptionsSuspense<TData = ResourceReservationGetExtendTimeOptions["response"], TQueryKey extends QueryKey = ResourceReservationGetExtendTimeOptionsSuspenseQueryKey>(id: ResourceReservationGetExtendTimeOptionsPathParams["id"], options: {
    query?: Partial<UseSuspenseQueryOptions<ResourceReservationGetExtendTimeOptions["response"], ResourceReservationGetExtendTimeOptions["error"], TData, TQueryKey>>;
    client?: ResourceReservationGetExtendTimeOptions["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, ResourceReservationGetExtendTimeOptions["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? resourceReservationGetExtendTimeOptionsSuspenseQueryKey(id);
    const query = useSuspenseQuery({
        ...resourceReservationGetExtendTimeOptionsSuspenseQueryOptions(id, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, ResourceReservationGetExtendTimeOptions["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}