import { useTranslation } from 'react-i18next' // src/components/AdminRoute.tsx
import { useAtom } from 'jotai'
import React from 'react'

import { kbMessage } from '@/components/atoms/KBMessageGlobal'
import { hasAdminRoleAtom } from '@/store'
import { isAuthenticatedAtom } from '@/store/auth.atom'

interface AdminRouteProps {
  element: JSX.Element
}

const AdminRoute: React.FC<AdminRouteProps> = ({ element }) => {
  const { t } = useTranslation()
  const [isAuthenticated] = useAtom(isAuthenticatedAtom)
  const [hasAdminRole] = useAtom(hasAdminRoleAtom)

  if (!isAuthenticated) {
    const redirectUri = window.location.href
    return (
      <Navigate
        to={`/login?error=not_authorized&redirect_uri=${encodeURIComponent(
          redirectUri
        )}`}
      />
    )
  } else if (!hasAdminRole) {
    kbMessage.error(t('common.notAuthorized'))
    return <Navigate to="/user/dashboard" />
  }
  return element
}

export default AdminRoute
