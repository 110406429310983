import { TReservationReserveRule } from '@/api'
import { KBModal } from '@/components/atoms'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { ModalProps } from 'antd'
import { t } from 'i18next'
interface ReservationRulesModalProps {
  data?: TReservationReserveRule
  classNames?: ModalProps['classNames']
}

function SchedulerReservationNoticeModal(props: ReservationRulesModalProps) {
  const { data, classNames } = props
  const modal = useModal()

  return (
    <KBModal
      modal={modal}
      title={t('common.reservationNotice')}
      footer={null}
      width={672}
      classNames={{
        wrapper: 'tw-z-[9999999999]',
        mask: 'tw-z-[1001]',
        ...classNames,
      }}
    >
      <div className="tw-h-[500px]">
        <div
          className="tw-mb-9 tw-max-h-[444px] tw-overflow-y-auto"
          dangerouslySetInnerHTML={{ __html: data?.notice ?? '' }}
        />
      </div>
    </KBModal>
  )
}

export default NiceModal.create(SchedulerReservationNoticeModal)
