import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useTranslation } from 'react-i18next'

import {
  KBButton,
  KBFlex,
  KBIcon,
  KBModal,
  KBTypography,
} from '@/components/atoms'
import { kbMessage } from '@/components/atoms/KBMessageGlobal'
import { Input } from 'antd'
import copyToClipboard from 'copy-to-clipboard'
interface ShareModalProps {
  url?: string
}
function ShareModal(props: ShareModalProps) {
  const { url = '' } = props
  const { t } = useTranslation()
  const modal = useModal()
  const params = useParams()

  return (
    <KBModal modal={modal} title={t('common.share')} footer={null} width={512}>
      {/* <KBTypography.Text className="tw-mb-1 tw-block tw-text-base tw-font-bold">
        {t('common.copyReservationLink')}
      </KBTypography.Text> */}
      <KBTypography.Text type="secondary" className="tw-mb-6 tw-block ">
        {t('common.copyReservationLinkTip')}
      </KBTypography.Text>
      <KBFlex gap={0}>
        <Input
          readOnly
          size="large"
          value={url}
          className="tw-rounded-[6px_0px_0px_6px]"
        />

        <KBButton
          className="-tw-ml-[1px]  tw-rounded-[0px_6px_6px_0px] tw-p-2"
          icon={<KBIcon name="copy" />}
          onClick={() => {
            copyToClipboard(url)
            kbMessage.success(t('common.copySuccess'))
          }}
        />
      </KBFlex>
    </KBModal>
  )
}

export default NiceModal.create(ShareModal)
