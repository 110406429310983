import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useTranslation } from 'react-i18next'

import {
  useResourceReservationAdminCheckin,
  useResourceReservationAdminCheckout,
  useResourceReservationCheckin,
  useResourceReservationCheckout,
} from '@/api'
import {
  KBButton,
  KBFlex,
  KBImage,
  KBModal,
  KBTypography,
} from '@/components/atoms'
import { kbMessage } from '@/components/atoms/KBMessageGlobal'
import useInvalidate from '@/hooks/useInvalidate'
import { isAdminClient } from '@/utils/userEnvironment'
const { Text } = KBTypography
/**
 * title:标题
 * content：描述
 */
interface KBResourcesSignModalProps {
  title?: string
  content?: string
  type?: 'signIn' | 'signOut'
  time?: string
  resourcesName?: string
  reservationId?: number
}
const KBReservationSignModal = (props: KBResourcesSignModalProps) => {
  const { t } = useTranslation()
  const {
    title,
    content,
    type = 'signIn',
    time,
    resourcesName,
    reservationId,
  } = props
  const modal = useModal()

  const invalidateAdminBookReservations = useInvalidate([
    'admin',
    'resource-reservations',
  ])

  const invalidateUserBookReservations = useInvalidate([
    'resource-reservations',
  ])
  const invalidateReservations = () => {
    isAdminClient()
      ? invalidateAdminBookReservations()
      : invalidateUserBookReservations()
  }
  // 签到
  const { mutate: checkIn } = (
    isAdminClient()
      ? useResourceReservationAdminCheckin
      : useResourceReservationCheckin
  )({
    mutation: {
      onSuccess: () => {
        // refetch()
      },
    },
  })
  // 签退
  const { mutate: checkout } = (
    isAdminClient()
      ? useResourceReservationAdminCheckout
      : useResourceReservationCheckout
  )({
    mutation: {
      onSuccess: () => {
        // refetch()
        // invalidateReservations()
      },
    },
  })

  const submit = () => {
    if (type === 'signIn') {
      checkIn(
        { id: reservationId! },
        {
          onSuccess: () => {
            kbMessage.success(t('common.signInSuccess'))
            modal.hide()
          },
        }
      )
    } else {
      checkout(
        { id: reservationId! },
        {
          onSuccess: () => {
            kbMessage.success(t('common.signOutSuccess'))
            modal.hide()
          },
        }
      )
    }
  }
  return (
    <KBModal title={title} modal={modal} footer={null} width={384}>
      <KBFlex align="center" vertical>
        <KBImage
          src={
            type === 'signIn'
              ? '/reservation/signIn.gif'
              : '/reservation/signOut.png'
          }
          preview={false}
          width={169}
          className="tw-mb-4"
        />
        <Text className="tw-mb-1 tw-block tw-text-2xl tw-font-bold">
          {type === 'signIn' ? t('common.sureSignIn') : t('common.sureSignOut')}
        </Text>
        <Text className="tw-block tw-text-lg tw-font-bold">{time}</Text>
        <Text className="tw-mb-[48px] tw-block tw-text-lg tw-font-bold">
          {resourcesName}
        </Text>
        <KBButton type="primary" className="tw-w-full" onClick={submit}>
          {title}
        </KBButton>
      </KBFlex>
    </KBModal>
  )
}

export default NiceModal.create(KBReservationSignModal)
