import { Pagination, PaginationProps } from 'antd'

import { cn } from '@/utils/tailwind.helper'

export interface KBPaginationProps extends PaginationProps {}

export function KBPagination(props: KBPaginationProps) {
  const { className, ...rest } = props
  return (
    <Pagination
      pageSize={20} // default page size is 20
      showSizeChanger={false}
      hideOnSinglePage
      className={cn('tw-my-6 tw-text-right', className)}
      {...rest}
    ></Pagination>
  )
}
