// @ts-nocheck
import client from "../../utils/axios";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { ResourceReservationAdminGetExtendTimeOptionsQueryResponse, ResourceReservationAdminGetExtendTimeOptionsPathParams, ResourceReservationAdminGetExtendTimeOptions401 } from "../models/ResourceReservationAdminGetExtendTimeOptions";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type ResourceReservationAdminGetExtendTimeOptionsClient = typeof client<ResourceReservationAdminGetExtendTimeOptionsQueryResponse, ResourceReservationAdminGetExtendTimeOptions401, never>;
type ResourceReservationAdminGetExtendTimeOptions = {
    data: ResourceReservationAdminGetExtendTimeOptionsQueryResponse;
    error: ResourceReservationAdminGetExtendTimeOptions401;
    request: never;
    pathParams: ResourceReservationAdminGetExtendTimeOptionsPathParams;
    queryParams: never;
    headerParams: never;
    response: ResourceReservationAdminGetExtendTimeOptionsQueryResponse;
    client: {
        parameters: Partial<Parameters<ResourceReservationAdminGetExtendTimeOptionsClient>[0]>;
        return: Awaited<ReturnType<ResourceReservationAdminGetExtendTimeOptionsClient>>;
    };
};
export const resourceReservationAdminGetExtendTimeOptionsQueryKey = (id: ResourceReservationAdminGetExtendTimeOptionsPathParams["id"]) => ["api", "v5", "admin", "resource-reservations", id, "extend-time-options"] as const;
export type ResourceReservationAdminGetExtendTimeOptionsQueryKey = ReturnType<typeof resourceReservationAdminGetExtendTimeOptionsQueryKey>;
export function resourceReservationAdminGetExtendTimeOptionsQueryOptions(id: ResourceReservationAdminGetExtendTimeOptionsPathParams["id"], options: ResourceReservationAdminGetExtendTimeOptions["client"]["parameters"] = {}) {
    const queryKey = resourceReservationAdminGetExtendTimeOptionsQueryKey(id);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ResourceReservationAdminGetExtendTimeOptions["data"], ResourceReservationAdminGetExtendTimeOptions["error"]>({
                method: "get",
                url: `/api/v5/admin/resource-reservations/${id}/extend-time-options`,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 延长会议时间选项
 * @link /api/v5/admin/resource-reservations/:id/extend-time-options
 */
export function useResourceReservationAdminGetExtendTimeOptions<TData = ResourceReservationAdminGetExtendTimeOptions["response"], TQueryData = ResourceReservationAdminGetExtendTimeOptions["response"], TQueryKey extends QueryKey = ResourceReservationAdminGetExtendTimeOptionsQueryKey>(id: ResourceReservationAdminGetExtendTimeOptionsPathParams["id"], options: {
    query?: Partial<QueryObserverOptions<ResourceReservationAdminGetExtendTimeOptions["response"], ResourceReservationAdminGetExtendTimeOptions["error"], TData, TQueryData, TQueryKey>>;
    client?: ResourceReservationAdminGetExtendTimeOptions["client"]["parameters"];
} = {}): UseQueryResult<TData, ResourceReservationAdminGetExtendTimeOptions["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? resourceReservationAdminGetExtendTimeOptionsQueryKey(id);
    const query = useQuery({
        ...resourceReservationAdminGetExtendTimeOptionsQueryOptions(id, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, ResourceReservationAdminGetExtendTimeOptions["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const resourceReservationAdminGetExtendTimeOptionsSuspenseQueryKey = (id: ResourceReservationAdminGetExtendTimeOptionsPathParams["id"]) => ["api", "v5", "admin", "resource-reservations", id, "extend-time-options"] as const;
export type ResourceReservationAdminGetExtendTimeOptionsSuspenseQueryKey = ReturnType<typeof resourceReservationAdminGetExtendTimeOptionsSuspenseQueryKey>;
export function resourceReservationAdminGetExtendTimeOptionsSuspenseQueryOptions(id: ResourceReservationAdminGetExtendTimeOptionsPathParams["id"], options: ResourceReservationAdminGetExtendTimeOptions["client"]["parameters"] = {}) {
    const queryKey = resourceReservationAdminGetExtendTimeOptionsSuspenseQueryKey(id);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<ResourceReservationAdminGetExtendTimeOptions["data"], ResourceReservationAdminGetExtendTimeOptions["error"]>({
                method: "get",
                url: `/api/v5/admin/resource-reservations/${id}/extend-time-options`,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 延长会议时间选项
 * @link /api/v5/admin/resource-reservations/:id/extend-time-options
 */
export function useResourceReservationAdminGetExtendTimeOptionsSuspense<TData = ResourceReservationAdminGetExtendTimeOptions["response"], TQueryKey extends QueryKey = ResourceReservationAdminGetExtendTimeOptionsSuspenseQueryKey>(id: ResourceReservationAdminGetExtendTimeOptionsPathParams["id"], options: {
    query?: Partial<UseSuspenseQueryOptions<ResourceReservationAdminGetExtendTimeOptions["response"], ResourceReservationAdminGetExtendTimeOptions["error"], TData, TQueryKey>>;
    client?: ResourceReservationAdminGetExtendTimeOptions["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, ResourceReservationAdminGetExtendTimeOptions["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? resourceReservationAdminGetExtendTimeOptionsSuspenseQueryKey(id);
    const query = useSuspenseQuery({
        ...resourceReservationAdminGetExtendTimeOptionsSuspenseQueryOptions(id, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, ResourceReservationAdminGetExtendTimeOptions["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}