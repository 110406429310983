import { useTranslation } from 'react-i18next'
const AuthFailure = () => {
  const { t } = useTranslation()
  // get error from query params
  const [searchParams] = useSearchParams()
  const error = searchParams.get('error') || 'unknown'
  return (
    <div>
      <h1>{t('auth.failure.title')}</h1>
      <p>{t('auth.failure.description', { error })}</p>
      <a href="/login">{t('auth.failure.link')}</a>
    </div>
  )
}

export default AuthFailure
