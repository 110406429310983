import {
  KBButton,
  KBDropdown,
  KBDropdownProps,
  KBIcon,
} from '@/components/atoms'
import {
  getRecurrenceIntervalDescription,
  getRecurrenceIntervalOptions,
  RecurrenceByMonthInterval,
  RecurrenceByYearInterval,
  RecurrenceInterval,
} from '@/components/organisms/KBRecurrenceEditModal/constants'
import { RecurrenceInfo } from '@/components/organisms/KBRecurrenceEditModal/KBRecurrenceEditModal'
import NiceModal from '@ebay/nice-modal-react'
import dayjs, { Dayjs } from '@wox/dayjs'
import { ButtonProps } from 'antd'
import { FunctionComponent } from 'react'

interface KBRecurrenceDropdownProps extends Omit<KBDropdownProps, 'menu'> {
  disabled?: boolean
  value?: RecurrenceInfo
  buttonProps?: ButtonProps
  startDate?: Dayjs
  reserveEndDate?: Dayjs
  isShowYear?: boolean
  onChange?: (recurrenceInfo?: RecurrenceInfo) => void
}

const KBRecurrenceDropdown: FunctionComponent<KBRecurrenceDropdownProps> = (
  props
) => {
  const {
    value,
    disabled,
    startDate,
    reserveEndDate,
    buttonProps,
    onChange,
    isShowYear = false,
    ...restProps
  } = props

  const onChangeRecurrence = (key: RecurrenceInterval) => {
    if (key !== RecurrenceInterval.noRecurrence) {
      let defaultRecurrenceInfo: RecurrenceInfo | undefined
      const startDay = dayjs(startDate)
      if (value?.intervalType === key) {
        // 类型不变保留以前的值
        defaultRecurrenceInfo = value
      } else if (key === RecurrenceInterval.day) {
        defaultRecurrenceInfo = {
          // ...defaultRecurrenceInfo,
          interval: 1,
          startDate: startDay,
          endDate: startDay.add(1, 'month'),
          intervalType: key,
        }
      } else if (key === RecurrenceInterval.week) {
        defaultRecurrenceInfo = {
          interval: 1,
          selectedWeekdays: [startDay.day()],
          startDate: startDay,
          endDate: startDay.add(1, 'month'),
          // ...defaultRecurrenceInfo,
          intervalType: key,
        }
      } else if (key === RecurrenceInterval.month) {
        defaultRecurrenceInfo = {
          interval: 1,
          monthRecurrenceByMonthInterval: RecurrenceByMonthInterval.day,
          monthDay: startDay.date(),
          startDate: startDay,
          endDate: startDay.add(1, 'month'),
          // ...defaultRecurrenceInfo,
          intervalType: key,
        }
      } else if (key === RecurrenceInterval.year) {
        defaultRecurrenceInfo = {
          interval: 1,
          yearRecurrenceByYearInterval: RecurrenceByYearInterval.day,
          monthDay: startDay.date(),
          startDate: startDay,
          endDate: startDay.add(1, 'year'),
          month: startDay.month() + 1,
          // ...defaultRecurrenceInfo,
          intervalType: key,
        }
      }
      NiceModal.show('KBRecurrenceEditModal', {
        isShowYear: isShowYear,
        intervalType: key,
        onConfirm: (info: RecurrenceInfo) => {
          onChange?.(info)
        },
        recurrenceInfo: defaultRecurrenceInfo,
        reserveEndDate,
      })
    } else {
      // 不重复预定
      onChange?.(undefined)
    }
  }

  const items = isShowYear
    ? getRecurrenceIntervalOptions()
    : getRecurrenceIntervalOptions()?.filter(
        (item) => item?.key !== RecurrenceInterval.year
      )

  return (
    <KBDropdown
      menu={{
        items: items,
        onClick: (e) => {
          const key = +e.key as RecurrenceInterval
          onChangeRecurrence(key)
        },
      }}
      placement="bottom"
      trigger={['click']}
      disabled={disabled}
      {...restProps}
    >
      <KBButton icon={<KBIcon name="repeat" />} {...buttonProps} type="text">
        {getRecurrenceIntervalDescription(value?.intervalType)}
      </KBButton>
    </KBDropdown>
  )
}

export default KBRecurrenceDropdown
