import { Switch, SwitchProps } from 'antd'

export interface KBSwitchProps extends SwitchProps {}

export function KBSwitch(props: KBSwitchProps) {
  return <Switch {...props}></Switch>
}

export function KBReverseSwitch(props: KBSwitchProps) {
  const { value, onChange, ...restProps } = props
  return (
    <Switch
      value={!value}
      onChange={(checked, event) => {
        console.log('onChange', checked)
        onChange && onChange(!checked, event)
      }}
      {...restProps}
    ></Switch>
  )
}
