import { KBFlex } from '@/components/atoms/KBFlex'
import {
  KBProgressLoading,
  KBProgressLoadingProps,
} from '@/components/atoms/KBProgressLoading'
import { KBSpin } from '@/components/atoms/KBSpin'
import { LoadingType } from '@/components/atoms/KBTable'
import { cn } from '@/utils/tailwind.helper'

export interface KBSuspenseLoadingProps extends KBProgressLoadingProps {
  loadingType?: LoadingType
  fullScreen?: boolean
}

export const KBSuspenseLoading = (props: KBSuspenseLoadingProps) => {
  const { loadingType = LoadingType.Spin, fullScreen = false } = props
  return loadingType === LoadingType.Progress ? (
    <KBFlex
      justify="flex-start"
      align="flex-start"
      className="tw-m-auto tw-h-screen tw-w-screen"
    >
      <KBFlex
        justify="center"
        align="center"
        className="tw-m-auto tw-h-auto tw-w-auto"
      >
        <img src="/woxLogo.svg" title="logo" />
        <KBProgressLoading />
      </KBFlex>
    </KBFlex>
  ) : (
    <KBFlex
      justify="center"
      align="center"
      className={cn(
        'tw-m-auto tw-h-[250px] tw-w-full',
        fullScreen && 'tw-h-screen tw-w-screen'
      )}
    >
      <KBSpin className="tw-mt-[90px]" />
    </KBFlex>
  )
}
