import { KBButton, KBFlex } from '@/components/atoms'
import {
  KBRecurrenceEditModalProps,
  RecurrenceInfo,
} from '@/components/organisms/KBRecurrenceEditModal/KBRecurrenceEditModal'
import { localeAtom } from '@/store'
import { cn } from '@/utils/tailwind.helper'
import { nameOfDay, weekdays } from '@wox/dayjs'
import { useAtom } from 'jotai'
import { FunctionComponent } from 'react'

interface KBRecurrenceByWeekProps extends KBRecurrenceEditModalProps {
  recurrenceInfo: RecurrenceInfo
  setRecurrenceInfo: (info: RecurrenceInfo) => void
}

const KBRecurrenceByWeek: FunctionComponent<KBRecurrenceByWeekProps> = (
  props
) => {
  const { recurrenceInfo, setRecurrenceInfo } = props
  const [locale] = useAtom(localeAtom)
  console.log('recurrenceInfo', recurrenceInfo)
  const weekdayButtons = weekdays().map((weekday) => {
    const isSelected = recurrenceInfo?.selectedWeekdays?.includes(weekday)
    return (
      <KBButton
        type={isSelected ? 'primary' : 'text'}
        className={cn(
          'tw-w-10 tw-px-0',
          isSelected ? '' : 'tw-bg-[var(--wox-color-fill-tertiary)]'
        )}
        autoInsertSpace={false}
        onClick={() => {
          if (
            isSelected &&
            Number(recurrenceInfo?.selectedWeekdays?.length) > 1
          ) {
            setRecurrenceInfo({
              ...recurrenceInfo,
              selectedWeekdays:
                recurrenceInfo?.selectedWeekdays?.filter(
                  (day) => day !== weekday
                ) || [],
            })
          } else if (!isSelected) {
            setRecurrenceInfo({
              ...recurrenceInfo,
              selectedWeekdays: [
                ...(recurrenceInfo?.selectedWeekdays || []),
                weekday,
              ].sort((a, b) => a - b),
            })
          }
        }}
      >
        {nameOfDay(weekday, {
          weekdayFormat: 'short',
          locale,
        })}
      </KBButton>
    )
  })

  return (
    <KBFlex justify="flex-start" className=" tw-mt-3 tw-pl-[88px]" gap={6}>
      {weekdayButtons}
    </KBFlex>
  )
}

export default KBRecurrenceByWeek
