import { Typography } from 'antd'
import { TextProps } from 'antd/es/typography/Text'
import { cva } from 'class-variance-authority'
import { clsx } from 'clsx'
import { forwardRef } from 'react'

export const KBTextSizeConfig = {
  3: 'tw-text-xs',
  2: 'tw-text-sm',
  1: 'tw-text-base',
}

export const KBTextWeightConfig = {
  thin: 'tw-font-normal',
  middle: 'tw-font-medium',
  bold: 'tw-font-semibold',
  bigger: 'tw-font-bold',
}
export interface KBTextProps extends TextProps {
  /**
   * 控制字体大小
   */
  level?: keyof typeof KBTextSizeConfig
  /**
   * 控制字体粗细
   */
  weight?: keyof typeof KBTextWeightConfig
}

const KBTextClass = cva('', {
  variants: {
    level: KBTextSizeConfig,
    weight: KBTextWeightConfig,
  },
})

/**
 * KBText component that wraps Ant Design's Typography.Text
 * @param {KBTextProps} props - The props passed to the KBText component.
 */
const KBText = forwardRef<HTMLSpanElement, KBTextProps>((props, ref) => {
  const {
    level = 2,
    weight = 'thin',
    className,
    children,
    strong,
    ...otherProps
  } = props
  return (
    <Typography.Text
      ref={ref}
      className={clsx(
        KBTextClass({ level, weight: strong ? 'bold' : weight }),
        className
      )}
      {...otherProps}
    >
      {children || null}
    </Typography.Text>
  )
})

KBText.displayName = 'KBText'

export { KBText }
