import { DefaultOptionType, SelectProps } from 'antd/es/select'
import { useTranslation } from 'react-i18next'

import {
  SimpleUserEntity,
  UserBookEntity,
  UserEntity,
  UserFindManyQueryParams,
  useUserAdminAutoComplete,
  useUserAdminFindMany,
  useUserAutoComplete,
  useUserBookFindMany,
  useUserFindMany,
} from '@/api'
import { KBFlex } from '@/components/atoms'
import { kbMessage } from '@/components/atoms/KBMessageGlobal'
import { KBSelect, KBSelectProps } from '@/components/atoms/KBSelect'
import { KBTag } from '@/components/atoms/KBTag'
import { KBUser } from '@/components/organisms/KBUser/KBUser'
import { CACHE_TTL, MAX_PAGINATION_LIMIT } from '@/utils/constants'
import { isValidEmail } from '@/utils/string'
import { cn } from '@/utils/tailwind.helper'
import { isUserClient } from '@/utils/userEnvironment'
import dayjs from '@wox/dayjs'

type TagRender = SelectProps['tagRender']

export interface KBUserSelectProps extends KBSelectProps {
  usersQueryParams?: UserFindManyQueryParams
  /**
   * 包含，可以控制选择内部和外部联系人，默认包含内部联系人
   */
  includes?: ('internal' | 'external')[]
  enableDeliveryNicknameSearch?: boolean // 新增属性,是否对快递昵称进行模糊查询
  showNickname?: boolean // 新增属性,是否显示快递昵称
  // 新增一个属性，控制是否允许在回车时添加新选项
  enableAddNewOptionOnEnter?: boolean
}

export function getUserSelectOptions(
  users: SimpleUserEntity[],
  mode?: 'multiple' | 'tags',
  showNickname?: boolean
): DefaultOptionType[] {
  const isMultiple = mode && ['multiple', 'tags'].includes(mode)
  return users.map((user) => ({
    label: (
      // select为单选模式时，上下会有几px的差距，所有这里包一层
      <KBFlex>
        <KBUser
          key={user.id}
          size={32}
          user={user}
          showTooltip={false}
          gapNumber={isMultiple ? 4 : 8}
          showEmail={true}
          showNickname={showNickname}
          className="tw-py-1"
          // showDepartment
        />
      </KBFlex>
    ),

    value: user.id,
    user: user,
  }))
}

export const tagRender: TagRender = (props) => {
  const { label, closable, onClose } = props
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }
  return (
    <KBTag
      className="tw-my-0.5 tw-h-6 tw-rounded-full tw-py-1 tw-pl-1.5 tw-pr-2 "
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      bordered={false}
    >
      {typeof label === 'string' ? label.replace('...', '') : label}
    </KBTag>
  )
}

/**
 * 选择人员组件，默认过滤离职和未激活的人员，可以传入state覆盖默认值active
 */
export function KBUserSelect(props: KBUserSelectProps) {
  const { t } = useTranslation()
  const {
    className,
    mode,
    remoteSearch = false,
    placeholder = 'Select a person',
    allowClear = true,
    usersQueryParams = {},
    includes = ['internal'],
    enableDeliveryNicknameSearch = false, // 默认值为 false
    showNickname,
    options,
    onChange,
    enableAddNewOptionOnEnter = true,
    ...otherProps
  } = props
  const [search, setSearch] = useState('')
  const [userOptions, setUserOptions] = useState<DefaultOptionType[]>(
    options || []
  )

  // console.log('userOptions:', userOptions)

  const isMultiple = mode && ['multiple', 'tags'].includes(mode)
  const [selectedUsers, setSelectedUsers] = useState<SimpleUserEntity[]>([])

  // console.log('remoteSearch', search)
  const queryOptions = {
    query: {
      enabled: !remoteSearch,

      staleTime: CACHE_TTL,
    },
  }

  const remoteSearchResponse = isUserClient()
    ? useUserAutoComplete(
        {
          state: 'active',
          ...usersQueryParams,
          name: search,
        },
        {
          query: {
            enabled: false,
            staleTime: CACHE_TTL,
          },
        }
      )
    : useUserAdminAutoComplete(
        {
          state: 'active',
          ...usersQueryParams,
          name: search,
        },
        {
          query: {
            enabled: false,
            staleTime: CACHE_TTL,
          },
        }
      )

  const response = isUserClient()
    ? useUserFindMany(
        {
          state: 'active',
          ...usersQueryParams,
          limit: MAX_PAGINATION_LIMIT,
        },
        queryOptions
      )
    : useUserAdminFindMany(
        {
          state: 'active',
          ...usersQueryParams,
          limit: MAX_PAGINATION_LIMIT,
        },
        queryOptions
      )

  const externalResponse = useUserBookFindMany(
    {},
    { query: { staleTime: CACHE_TTL } }
  )

  useEffect(() => {
    if (remoteSearch) {
      if (remoteSearchResponse.data) {
        setUserOptions(
          getUserSelectOptions(
            remoteSearchResponse.data || [],
            mode,
            showNickname
          )
        )
      } else {
        setUserOptions(options || [])
      }
    }
  }, [mode, remoteSearch, remoteSearchResponse.data])

  useEffect(() => {
    if (!remoteSearch) {
      const list = []
      if (response.data && includes.includes('internal')) {
        list.push(
          ...getUserSelectOptions(
            response.data?.result || [],
            mode,
            showNickname
          )
        )
      }
      if (externalResponse.data && includes.includes('external')) {
        list.push(
          ...getUserSelectOptions(
            (externalResponse.data?.result || []).map((i: UserBookEntity) => ({
              ...i,
              // 为了防止id冲突，暂时把外部的id改成负数
              id: 0 - i.id,
            })),
            mode,
            showNickname
          )
        )
      }

      if (list.length) {
        setUserOptions(list)
      } else {
        setUserOptions(options || [])
      }
    }
  }, [mode, remoteSearch, response.data, externalResponse.data])

  const handleRemoteSearch = (value: string) => {
    setSearch(value)
    setTimeout(() => {
      remoteSearchResponse.refetch()
    }, 50)
  }

  const handleChange = (
    value: number | number[],
    option: DefaultOptionType
  ) => {
    // console.log('handle change', value, userOptions)
    const arrayValue = value ? (Array.isArray(value) ? value : [value]) : []
    const newUsers = (userOptions || [])
      .filter((option) => arrayValue.includes(option.user.id))
      .map((option) => option.user)

    setSelectedUsers(newUsers)
    onChange?.(value, option)
  }

  const handleEnterValue = (value?: string) => {
    if (!enableAddNewOptionOnEnter) return false

    if (!value) return false
    if (!isValidEmail(value)) {
      kbMessage.error(t('common.pleaseEnterValidEmail'))
      return false
    }

    const user = {
      id: dayjs().valueOf(),
      name: value,
      email: value,
    } as SimpleUserEntity
    // console.log('option', option)
    setUserOptions((prevOptions) => [
      ...prevOptions,
      ...getUserSelectOptions([user], mode, showNickname),
    ])
    setSelectedUsers((prev) => [...prev, user])
    return true
  }

  // console.log('userOptions', userOptions)
  // console.log('selectedUsers', selectedUsers)

  return (
    <KBSelect
      labelRender={(LabelInValueType) => {
        const currentUser = userOptions?.find(
          (item) => item?.value === LabelInValueType?.value
        )?.user
        if (currentUser) {
          return (
            <KBUser
              size={isMultiple ? 16 : 24}
              showTooltip={false}
              gapNumber={isMultiple ? 4 : 8}
              user={currentUser}
              showName
            />
          )
        } else {
          return LabelInValueType?.value
        }
      }}
      placeholder={placeholder}
      value={selectedUsers.map((user) => user.id)}
      showSearchInput
      searchFn={(option, searchValue) => {
        if (remoteSearch) return false

        const users: (UserEntity | UserBookEntity)[] = [
          ...(response.data?.result || []),
          ...(externalResponse.data?.result || []),
        ]
        // local search
        const user = users.find(
          (user) => user.id === Math.abs(option.value as number)
        )
        return (
          user?.name?.toLocaleLowerCase().includes(searchValue) ||
          user?.email?.toLocaleLowerCase().includes(searchValue) ||
          (enableDeliveryNicknameSearch &&
            (user as UserEntity)?.delivery_nickname
              ?.toLocaleLowerCase()
              .includes(searchValue)) || // 新增对 delivery_nickname 的查询
          false
        )
      }}
      remoteSearch={remoteSearch}
      remoteSearchFn={handleRemoteSearch}
      options={userOptions}
      tagRender={tagRender}
      className={cn('tw-w-[200px]', className)}
      mode={mode}
      allowClear={allowClear}
      onEnter={handleEnterValue}
      onChange={handleChange}
      // suffixIcon={null}
      {...otherProps}
    ></KBSelect>
  )
}
