import { ReservationShowEntity } from '@/api'
import { KBForm, KBIcon, KBInput, KBTooltip } from '@/components/atoms'
import { KBResourceReservationFormProps } from '@/components/molecules/KBResourceReservationForm/KBResourceReservationForm'
import { useTranslation } from 'react-i18next'

interface SubjectInputProps {
  resourcesList: KBResourceReservationFormProps['resourcesList']
  reservationInfo?: ReservationShowEntity
  errorsMessages?: string
}
/**
 * 会议主题组件
 */
const SubjectInput: React.FC<SubjectInputProps> = (props) => {
  const { t } = useTranslation()
  const { resourcesList, errorsMessages } = props

  const showSubject = useMemo(() => {
    return (resourcesList || []).some((i) => {
      const rules = i.reservation_reserve_rule?.rules
      return rules?.subject
    })
  }, [resourcesList])

  return showSubject ? (
    <KBForm.Item
      name="subject"
      label={
        <KBTooltip title={t('common.subject')}>
          <KBIcon name="pen-line" size={24} />
        </KBTooltip>
      }
      dependencies={['resourceIds']}
      validateStatus={Boolean(errorsMessages?.length) ? 'error' : undefined}
      help={errorsMessages}
    >
      <KBInput placeholder={t('reservation.subjectPlaceholder')} />
    </KBForm.Item>
  ) : null
}

export default SubjectInput
