import { KBFlex, KBIcon, KBText } from '@/components/atoms'
import { cn } from '@/utils/tailwind.helper'
import { useTranslation } from 'react-i18next'

export type KBEmptySize = 'small' | 'large'
export interface KBEmptyProps {
  title?: string
  /**
   * 是否为行内展示，显示
   */
  size?: KBEmptySize
}

export const KBEmpty = (props: KBEmptyProps) => {
  const { t } = useTranslation()
  const { title = t('common.noData'), size = 'large' } = props
  return (
    <KBFlex
      vertical
      justify="center"
      align="center"
      className={cn('tw-m-auto', size === 'large' ? 'tw-px-8 tw-py-10' : '')}
    >
      {size === 'large' && (
        <img
          src="/empty/empty.svg"
          alt="empty"
          className="tw-h-[200px] tw-w-[200px]"
        />
      )}
      {size === 'large' && (
        <KBText level={1} weight="bigger">
          {title}
        </KBText>
      )}
      {size === 'small' && (
        <KBFlex
          gap={4}
          justify="center"
          align="center"
          className="tw-py-6 tw-text-[var(--wox-color-text)]"
        >
          <KBIcon name="info" />
          <KBText weight="middle">{title}</KBText>
        </KBFlex>
      )}
    </KBFlex>
  )
}
