import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useTranslation } from 'react-i18next'

import {
  ServiceItemEntity,
  useLocationFindById,
  useServiceItemFindMany,
} from '@/api'
import {
  KBFlex,
  KBImage,
  KBInput,
  KBInputNumber,
  KBModal,
  KBTable,
  KBText,
} from '@/components/atoms'
import { getRandomImageSrc } from '@/components/utils'
import useCurrentLocationId from '@/hooks/useCurrentLocationId'
import { formatPrice } from '@/utils/format'

type ServiceItemEntityIncludeValue = {
  value: number
} & ServiceItemEntity
export interface ServiceItem {
  serviceItemId: number
  quantity: number
  total?: number
}

export interface TotalInfo {
  serviceTypeId?: number
  resourceId?: number
  serviceItems: ServiceItem[]
}
interface AddServiceProps {
  resourceId?: number
  serviceTypeId?: number
  value?: ServiceItem[]
  onConfirm?: (totalInfo: TotalInfo) => void
}

function AddService(props: AddServiceProps) {
  const { t } = useTranslation()
  const { serviceTypeId, resourceId, value, onConfirm } = props
  const modal = useModal()
  const [searchText, setSearchText] = useState<string>('')
  const totalInfoRef = useRef<ServiceItem[]>(value ? [...value] : [])
  const { data: serviceItems } = useServiceItemFindMany({
    service_type_id: serviceTypeId,
  })

  const locationId = useCurrentLocationId()
  // 是否应该全局Location对象？
  const { data: location } = useLocationFindById(locationId!, {
    query: {
      enabled: !!locationId,
    },
  })

  const getColumns = (onChange: (info: ServiceItem) => void) => [
    {
      title: t('common.serviceName'),
      key: 'name',
      render(record: ServiceItemEntityIncludeValue) {
        return (
          <KBFlex gap={12}>
            <KBImage
              preview={false}
              className="tw-h-[42px] tw-w-[42px] tw-bg-slate-100"
              src={record.image ? record.image : getRandomImageSrc()}
            />

            <KBFlex vertical gap={4}>
              <KBText weight="bold">{record.name}</KBText>
              <KBText
                level={3}
                className="tw-text-[var(--wox-color-text-description)]"
              >
                {record.price
                  ? `${t('common.price', { price: formatPrice(record.price, location?.amount_unit, location?.language) })}`
                  : ''}
              </KBText>
            </KBFlex>
          </KBFlex>
        )
      },
    },
    {
      title: t('common.quantity'),
      key: 'age',
      render(record: ServiceItemEntityIncludeValue) {
        return (
          <KBInputNumber
            min={0}
            defaultValue={record.value || 0}
            onChange={(value) =>
              onChange({
                quantity: value as number,
                serviceItemId: record.id,
                total: Number(value) * Number(record.price),
              })
            }
          />
        )
      },
    },
  ]

  const serviceListData = useMemo(() => {
    let arr = []
    if (searchText) {
      arr =
        serviceItems?.result.filter((serviceItem: ServiceItemEntity) =>
          searchText
            ? serviceItem.name
                .toLocaleLowerCase()
                .includes(searchText.toLocaleLowerCase())
            : true
        ) || []
    } else {
      arr = serviceItems?.result || []
    }

    return arr.map((i) => ({
      ...i,
      value: value?.find((j) => j.serviceItemId === i.id)?.quantity,
    }))
  }, [searchText, serviceItems])

  function onChange(info: ServiceItem) {
    const index = totalInfoRef.current.findIndex(
      (i) => i.serviceItemId === info.serviceItemId
    )
    if (index > -1) {
      totalInfoRef.current.splice(index, 1, info)
    } else {
      totalInfoRef.current.push(info)
    }
  }
  function onOk() {
    modal.hide()
    onConfirm?.({
      serviceTypeId,
      resourceId,
      serviceItems: totalInfoRef.current,
    })
  }

  return (
    <KBModal title={t('common.addService')} modal={modal} onOk={onOk}>
      <KBInput
        placeholder={t('common.searchService')}
        className="tw-mb-5"
        onChange={(event) => setSearchText(event.target.value)}
      />

      <KBTable columns={getColumns(onChange)} dataSource={serviceListData} />
    </KBModal>
  )
}

const AddServiceModal = NiceModal.create(AddService)

export default AddServiceModal
