interface EnumValueOption {
  /**
   * @description  The order position of each enum on the page
   */

  position?: number

  /**
   * @description  Count of enum
   */

  num?: number

  /**
   * @description  Whether to hide this enum on the page
   */

  hiddenSelect?: boolean

  /**
   * @description  The icon associated with the enum
   */

  icon?: string

  /**
   * @description  The backgroundColor associated with the enum
   */

  backgroundColor?: string

  /**
   * @description  The color associated with the enum
   */

  color?: string
}
export interface ModelEnumObjects {
  [key: string]: EnumValueOption
}
export const NotificationChannelEnumObjects: ModelEnumObjects = {
  App: {
    position: 0,
  },
  Sms: {
    position: 1,
  },
  Email: {
    position: 2,
  },
  Wechat: {
    position: 3,
  },
  Slack: {
    position: 4,
  },
  Feishu: {
    position: 5,
  },
  QiyeWechat: {
    position: 6,
  },
}
export const NotificationChannelEnums = [
  'App',
  'Sms',
  'Email',
  'Wechat',
  'Slack',
  'Feishu',
  'QiyeWechat',
]
export const enum NotificationChannelEnum {
  App = 'App',
  Sms = 'Sms',
  Email = 'Email',
  Wechat = 'Wechat',
  Slack = 'Slack',
  Feishu = 'Feishu',
  QiyeWechat = 'QiyeWechat',
}
export const NotificationTemplateEventNameEnumObjects: ModelEnumObjects = {
  Test: {
    position: 0,
  },
  SpaceExpiring: {
    position: 1,
  },
  ReservationStartHostReminder: {
    position: 2,
  },
  ReservationStartAttendeeReminder: {
    position: 3,
  },
  ReservationEndSoonReminder: {
    position: 4,
  },
  ReservationCreatedHostReminder: {
    position: 5,
  },
  ReservationCreatedAttendeeReminder: {
    position: 6,
  },
  SyncCalendarFailed: {
    position: 7,
  },
  ReservationRecurrentCreatedHostReminder: {
    position: 8,
  },
  ReservationRecurrentCreatedAttendeeReminder: {
    position: 9,
  },
  ReservationResourceOrTimeUpdated: {
    position: 10,
  },
  ReservationHostUpdatedOldHostReminder: {
    position: 11,
  },
  ReservationHostUpdatedNewHostReminder: {
    position: 12,
  },
  ReservationAttendeeUpdatedOldAttendeeReminder: {
    position: 13,
  },
  ReservationAttendeeUpdatedNewAttendeeReminder: {
    position: 14,
  },
  ReservationServiceUpdated: {
    position: 15,
  },
  ReservationApprovalReminder: {
    position: 16,
  },
  ReservationApprovalPendingReminder: {
    position: 17,
  },
  ReservationCancelledHostReminder: {
    position: 18,
  },
  ReservationCancelledAttendeeReminder: {
    position: 19,
  },
  ReservationCancelledTaskReminder: {
    position: 20,
  },
  ReservationRecurrentCancelledHostReminder: {
    position: 21,
  },
  ReservationRecurrentCancelledAttendeeReminder: {
    position: 22,
  },
  ReservationCheckinReminder: {
    position: 23,
  },
  ReservationNormalToRecurrent: {
    position: 24,
  },
  MeetingReservationReminder: {
    position: 25,
  },
  MeetingReservationTerminated: {
    position: 26,
  },
  MeetingReservationUpdated: {
    position: 27,
  },
  MeetingReservationCancelled: {
    position: 28,
  },
  MeetingReservationCancellation: {
    position: 29,
  },
  DeskReservationCreated: {
    position: 30,
  },
  DeskReservationReminder: {
    position: 31,
  },
  DeskReservationCancelled: {
    position: 32,
  },
  DeskReservationAutoTerminated: {
    position: 33,
  },
  SaasTransactionCreditSuccess: {
    position: 34,
  },
  SaasRenewSuccess: {
    position: 35,
  },
  SaasRenewFailed: {
    position: 36,
  },
  SaasSubscriptionExpired: {
    position: 37,
  },
  SaasSubscriptionPastDue: {
    position: 38,
  },
  SaasSubscriptionTrialExpiring: {
    position: 39,
  },
  VisitorRemindApprove: {
    position: 40,
  },
  VisitorSendInvite: {
    position: 41,
  },
  VisitorSignin: {
    position: 42,
  },
  VisitorSignout: {
    position: 43,
  },
  VisitorRemind: {
    position: 44,
  },
  VisitorLeaveRemind: {
    position: 45,
  },
  VisitorExternalAttendeesUpdated: {
    position: 46,
  },
  VisitorExternalAttendeesCancelled: {
    position: 47,
  },
  VisitorDisapprovedForHost: {
    position: 48,
  },
  VisitorApprovedForHost: {
    position: 49,
  },
  VisitorSigninForHost: {
    position: 50,
  },
  VisitorSigninForCreator: {
    position: 51,
  },
  VisitorSatisfactionSurvey: {
    position: 52,
  },
  VisitorNotSignout: {
    position: 53,
  },
  VisitorAcceptInvitation: {
    position: 54,
  },
  VisitorSignNDA: {
    position: 55,
  },
  VisitorApprovedForVisitor: {
    position: 56,
  },
  VisitorDisapprovedForVisitor: {
    position: 57,
  },
  VisitorWatchListRemind: {
    position: 58,
  },
  TaskCreatedCreator: {
    position: 59,
  },
  TaskCreatedAssignTo: {
    position: 60,
  },
  TaskCompleted: {
    position: 61,
  },
  TaskExpired: {
    position: 62,
  },
  TaskUpdateInfo: {
    position: 63,
  },
  TaskRejected: {
    position: 64,
  },
  TaskCreatedRecurrentToCreator: {
    position: 65,
  },
  BlacklistViolation: {
    position: 66,
  },
  BlacklistViolationDepartment: {
    position: 67,
  },
  BlacklistViolationInvalid: {
    position: 68,
  },
  BlacklistByUserRule: {
    position: 69,
  },
  BlacklistByDepartmentRule: {
    position: 70,
  },
  BlacklistInvalid: {
    position: 71,
  },
  WaitlistCreated: {
    position: 72,
  },
  WaitlistPendingConfirm: {
    position: 73,
  },
  WaitlistConfirmed: {
    position: 74,
  },
  WaitlistTimeOutUnconfirmed: {
    position: 75,
  },
  WaitlistByOther: {
    position: 76,
  },
  WaitlistFailByRule: {
    position: 77,
  },
  WaitlistUpdate: {
    position: 78,
  },
  DeliveryCreated: {
    position: 79,
  },
  DeliveryExpiredToReceiver: {
    position: 80,
  },
  DeliveryExpiredToAssignTo: {
    position: 81,
  },
  DeliveryRejected: {
    position: 82,
  },
  DeliveryAssignedUpdate: {
    position: 83,
  },
  SpaceCreateApply: {
    position: 84,
  },
  UserCreatedRemind: {
    position: 85,
  },
  TaskBeforeExpiredRemind: {
    position: 86,
  },
  DeliveryBeforeExpiredRemindToReceiver: {
    position: 87,
  },
  DeliveryBeforeExpiredRemindToAssignTo: {
    position: 88,
  },
  DeliveryAreaChangeRemind: {
    position: 89,
  },
  ApprovalRequestCreated: {
    position: 90,
  },
}
export const NotificationTemplateEventNameEnums = [
  'Test',
  'SpaceExpiring',
  'ReservationStartHostReminder',
  'ReservationStartAttendeeReminder',
  'ReservationEndSoonReminder',
  'ReservationCreatedHostReminder',
  'ReservationCreatedAttendeeReminder',
  'SyncCalendarFailed',
  'ReservationRecurrentCreatedHostReminder',
  'ReservationRecurrentCreatedAttendeeReminder',
  'ReservationResourceOrTimeUpdated',
  'ReservationHostUpdatedOldHostReminder',
  'ReservationHostUpdatedNewHostReminder',
  'ReservationAttendeeUpdatedOldAttendeeReminder',
  'ReservationAttendeeUpdatedNewAttendeeReminder',
  'ReservationServiceUpdated',
  'ReservationApprovalReminder',
  'ReservationApprovalPendingReminder',
  'ReservationCancelledHostReminder',
  'ReservationCancelledAttendeeReminder',
  'ReservationCancelledTaskReminder',
  'ReservationRecurrentCancelledHostReminder',
  'ReservationRecurrentCancelledAttendeeReminder',
  'ReservationCheckinReminder',
  'ReservationNormalToRecurrent',
  'MeetingReservationReminder',
  'MeetingReservationTerminated',
  'MeetingReservationUpdated',
  'MeetingReservationCancelled',
  'MeetingReservationCancellation',
  'DeskReservationCreated',
  'DeskReservationReminder',
  'DeskReservationCancelled',
  'DeskReservationAutoTerminated',
  'SaasTransactionCreditSuccess',
  'SaasRenewSuccess',
  'SaasRenewFailed',
  'SaasSubscriptionExpired',
  'SaasSubscriptionPastDue',
  'SaasSubscriptionTrialExpiring',
  'VisitorRemindApprove',
  'VisitorSendInvite',
  'VisitorSignin',
  'VisitorSignout',
  'VisitorRemind',
  'VisitorLeaveRemind',
  'VisitorExternalAttendeesUpdated',
  'VisitorExternalAttendeesCancelled',
  'VisitorDisapprovedForHost',
  'VisitorApprovedForHost',
  'VisitorSigninForHost',
  'VisitorSigninForCreator',
  'VisitorSatisfactionSurvey',
  'VisitorNotSignout',
  'VisitorAcceptInvitation',
  'VisitorSignNDA',
  'VisitorApprovedForVisitor',
  'VisitorDisapprovedForVisitor',
  'VisitorWatchListRemind',
  'TaskCreatedCreator',
  'TaskCreatedAssignTo',
  'TaskCompleted',
  'TaskExpired',
  'TaskUpdateInfo',
  'TaskRejected',
  'TaskCreatedRecurrentToCreator',
  'BlacklistViolation',
  'BlacklistViolationDepartment',
  'BlacklistViolationInvalid',
  'BlacklistByUserRule',
  'BlacklistByDepartmentRule',
  'BlacklistInvalid',
  'WaitlistCreated',
  'WaitlistPendingConfirm',
  'WaitlistConfirmed',
  'WaitlistTimeOutUnconfirmed',
  'WaitlistByOther',
  'WaitlistFailByRule',
  'WaitlistUpdate',
  'DeliveryCreated',
  'DeliveryExpiredToReceiver',
  'DeliveryExpiredToAssignTo',
  'DeliveryRejected',
  'DeliveryAssignedUpdate',
  'SpaceCreateApply',
  'UserCreatedRemind',
  'TaskBeforeExpiredRemind',
  'DeliveryBeforeExpiredRemindToReceiver',
  'DeliveryBeforeExpiredRemindToAssignTo',
  'DeliveryAreaChangeRemind',
  'ApprovalRequestCreated',
]
export const enum NotificationTemplateEventNameEnum {
  Test = 'Test',
  SpaceExpiring = 'SpaceExpiring',
  ReservationStartHostReminder = 'ReservationStartHostReminder',
  ReservationStartAttendeeReminder = 'ReservationStartAttendeeReminder',
  ReservationEndSoonReminder = 'ReservationEndSoonReminder',
  ReservationCreatedHostReminder = 'ReservationCreatedHostReminder',
  ReservationCreatedAttendeeReminder = 'ReservationCreatedAttendeeReminder',
  SyncCalendarFailed = 'SyncCalendarFailed',
  ReservationRecurrentCreatedHostReminder = 'ReservationRecurrentCreatedHostReminder',
  ReservationRecurrentCreatedAttendeeReminder = 'ReservationRecurrentCreatedAttendeeReminder',
  ReservationResourceOrTimeUpdated = 'ReservationResourceOrTimeUpdated',
  ReservationHostUpdatedOldHostReminder = 'ReservationHostUpdatedOldHostReminder',
  ReservationHostUpdatedNewHostReminder = 'ReservationHostUpdatedNewHostReminder',
  ReservationAttendeeUpdatedOldAttendeeReminder = 'ReservationAttendeeUpdatedOldAttendeeReminder',
  ReservationAttendeeUpdatedNewAttendeeReminder = 'ReservationAttendeeUpdatedNewAttendeeReminder',
  ReservationServiceUpdated = 'ReservationServiceUpdated',
  ReservationApprovalReminder = 'ReservationApprovalReminder',
  ReservationApprovalPendingReminder = 'ReservationApprovalPendingReminder',
  ReservationCancelledHostReminder = 'ReservationCancelledHostReminder',
  ReservationCancelledAttendeeReminder = 'ReservationCancelledAttendeeReminder',
  ReservationCancelledTaskReminder = 'ReservationCancelledTaskReminder',
  ReservationRecurrentCancelledHostReminder = 'ReservationRecurrentCancelledHostReminder',
  ReservationRecurrentCancelledAttendeeReminder = 'ReservationRecurrentCancelledAttendeeReminder',
  ReservationCheckinReminder = 'ReservationCheckinReminder',
  ReservationNormalToRecurrent = 'ReservationNormalToRecurrent',
  MeetingReservationReminder = 'MeetingReservationReminder',
  MeetingReservationTerminated = 'MeetingReservationTerminated',
  MeetingReservationUpdated = 'MeetingReservationUpdated',
  MeetingReservationCancelled = 'MeetingReservationCancelled',
  MeetingReservationCancellation = 'MeetingReservationCancellation',
  DeskReservationCreated = 'DeskReservationCreated',
  DeskReservationReminder = 'DeskReservationReminder',
  DeskReservationCancelled = 'DeskReservationCancelled',
  DeskReservationAutoTerminated = 'DeskReservationAutoTerminated',
  SaasTransactionCreditSuccess = 'SaasTransactionCreditSuccess',
  SaasRenewSuccess = 'SaasRenewSuccess',
  SaasRenewFailed = 'SaasRenewFailed',
  SaasSubscriptionExpired = 'SaasSubscriptionExpired',
  SaasSubscriptionPastDue = 'SaasSubscriptionPastDue',
  SaasSubscriptionTrialExpiring = 'SaasSubscriptionTrialExpiring',
  VisitorRemindApprove = 'VisitorRemindApprove',
  VisitorSendInvite = 'VisitorSendInvite',
  VisitorSignin = 'VisitorSignin',
  VisitorSignout = 'VisitorSignout',
  VisitorRemind = 'VisitorRemind',
  VisitorLeaveRemind = 'VisitorLeaveRemind',
  VisitorExternalAttendeesUpdated = 'VisitorExternalAttendeesUpdated',
  VisitorExternalAttendeesCancelled = 'VisitorExternalAttendeesCancelled',
  VisitorDisapprovedForHost = 'VisitorDisapprovedForHost',
  VisitorApprovedForHost = 'VisitorApprovedForHost',
  VisitorSigninForHost = 'VisitorSigninForHost',
  VisitorSigninForCreator = 'VisitorSigninForCreator',
  VisitorSatisfactionSurvey = 'VisitorSatisfactionSurvey',
  VisitorNotSignout = 'VisitorNotSignout',
  VisitorAcceptInvitation = 'VisitorAcceptInvitation',
  VisitorSignNDA = 'VisitorSignNDA',
  VisitorApprovedForVisitor = 'VisitorApprovedForVisitor',
  VisitorDisapprovedForVisitor = 'VisitorDisapprovedForVisitor',
  VisitorWatchListRemind = 'VisitorWatchListRemind',
  TaskCreatedCreator = 'TaskCreatedCreator',
  TaskCreatedAssignTo = 'TaskCreatedAssignTo',
  TaskCompleted = 'TaskCompleted',
  TaskExpired = 'TaskExpired',
  TaskUpdateInfo = 'TaskUpdateInfo',
  TaskRejected = 'TaskRejected',
  TaskCreatedRecurrentToCreator = 'TaskCreatedRecurrentToCreator',
  BlacklistViolation = 'BlacklistViolation',
  BlacklistViolationDepartment = 'BlacklistViolationDepartment',
  BlacklistViolationInvalid = 'BlacklistViolationInvalid',
  BlacklistByUserRule = 'BlacklistByUserRule',
  BlacklistByDepartmentRule = 'BlacklistByDepartmentRule',
  BlacklistInvalid = 'BlacklistInvalid',
  WaitlistCreated = 'WaitlistCreated',
  WaitlistPendingConfirm = 'WaitlistPendingConfirm',
  WaitlistConfirmed = 'WaitlistConfirmed',
  WaitlistTimeOutUnconfirmed = 'WaitlistTimeOutUnconfirmed',
  WaitlistByOther = 'WaitlistByOther',
  WaitlistFailByRule = 'WaitlistFailByRule',
  WaitlistUpdate = 'WaitlistUpdate',
  DeliveryCreated = 'DeliveryCreated',
  DeliveryExpiredToReceiver = 'DeliveryExpiredToReceiver',
  DeliveryExpiredToAssignTo = 'DeliveryExpiredToAssignTo',
  DeliveryRejected = 'DeliveryRejected',
  DeliveryAssignedUpdate = 'DeliveryAssignedUpdate',
  SpaceCreateApply = 'SpaceCreateApply',
  UserCreatedRemind = 'UserCreatedRemind',
  TaskBeforeExpiredRemind = 'TaskBeforeExpiredRemind',
  DeliveryBeforeExpiredRemindToReceiver = 'DeliveryBeforeExpiredRemindToReceiver',
  DeliveryBeforeExpiredRemindToAssignTo = 'DeliveryBeforeExpiredRemindToAssignTo',
  DeliveryAreaChangeRemind = 'DeliveryAreaChangeRemind',
  ApprovalRequestCreated = 'ApprovalRequestCreated',
}
export const ApprovalRecordSyncProviderEnumObjects: ModelEnumObjects = {
  qiyeWechat: {
    position: 0,
  },
  dingtalk: {
    position: 1,
  },
}
export const ApprovalRecordSyncProviderEnums = ['qiyeWechat', 'dingtalk']
export const enum ApprovalRecordSyncProviderEnum {
  qiyeWechat = 'qiyeWechat',
  dingtalk = 'dingtalk',
}
export const ApprovalRecordStatusEnumObjects: ModelEnumObjects = {
  approving: {
    color: '--wox-color-alert-warning',
    backgroundColor: '--wox-color-alert-warning-bg',
    icon: 'circle-alert',
    position: 0,
  },
  approved: {
    color: '--wox-color-alert-success',
    backgroundColor: '--wox-color-alert-success-bg',
    icon: 'circle-check',
    position: 1,
  },
  rejected: {
    color: '--wox-color-alert-error',
    backgroundColor: '--wox-color-alert-error-bg',
    icon: 'triangle-alert',
    position: 2,
  },
  terminated: {
    color: '--wox-color-text',
    backgroundColor: '--wox-color-fill-quaternary',
    icon: 'circle-alert',
    position: 3,
  },
}
export const ApprovalRecordStatusEnums = [
  'approving',
  'approved',
  'rejected',
  'terminated',
]
export const enum ApprovalRecordStatusEnum {
  approving = 'approving',
  approved = 'approved',
  rejected = 'rejected',
  terminated = 'terminated',
}
export const ApprovalRecordResourceEnumObjects: ModelEnumObjects = {
  visitor: {
    color: 'gray',
    position: 0,
  },
  resourceReservation: {
    color: 'gray',
    position: 1,
  },
}
export const ApprovalRecordResourceEnums = ['visitor', 'resourceReservation']
export const enum ApprovalRecordResourceEnum {
  visitor = 'visitor',
  resourceReservation = 'resourceReservation',
}
export const CustomFieldTypeEnumObjects: ModelEnumObjects = {
  Text: {
    position: 1,
  },
  Textarea: {
    position: 6,
  },
  Number: {
    position: 7,
  },
  Email: {
    position: 8,
  },
  Phone: {
    position: 9,
  },
  Address: {
    position: 10,
  },
  Select: {
    position: 2,
  },
  MultiSelect: {
    position: 3,
  },
  Checkbox: {
    position: 4,
  },
  Radio: {
    position: 5,
  },
}
export const CustomFieldTypeEnums = [
  'Text',
  'Textarea',
  'Number',
  'Email',
  'Phone',
  'Address',
  'Select',
  'MultiSelect',
  'Checkbox',
  'Radio',
]
export const enum CustomFieldTypeEnum {
  Text = 'Text',
  Textarea = 'Textarea',
  Number = 'Number',
  Email = 'Email',
  Phone = 'Phone',
  Address = 'Address',
  Select = 'Select',
  MultiSelect = 'MultiSelect',
  Checkbox = 'Checkbox',
  Radio = 'Radio',
}
export const CustomFieldOwnerTypeEnumObjects: ModelEnumObjects = {
  UserType: {
    position: 0,
  },
  ResourceType: {
    position: 1,
  },
  VisitorType: {
    position: 2,
  },
  ReservationPolicyType: {
    position: 3,
  },
}
export const CustomFieldOwnerTypeEnums = [
  'UserType',
  'ResourceType',
  'VisitorType',
  'ReservationPolicyType',
]
export const enum CustomFieldOwnerTypeEnum {
  UserType = 'UserType',
  ResourceType = 'ResourceType',
  VisitorType = 'VisitorType',
  ReservationPolicyType = 'ReservationPolicyType',
}
export const DeviceTypeEnumObjects: ModelEnumObjects = {
  android: {
    position: 0,
  },
  ios: {
    position: 1,
  },
}
export const DeviceTypeEnums = ['android', 'ios']
export const enum DeviceTypeEnum {
  android = 'android',
  ios = 'ios',
}
export const EmployeeWorkStatusStateEnumObjects: ModelEnumObjects = {
  WorkOffice: {
    position: 0,
  },
  WorkHome: {
    position: 1,
  },
  Rest: {
    position: 2,
  },
  Unknown: {
    position: 3,
  },
}
export const EmployeeWorkStatusStateEnums = [
  'WorkOffice',
  'WorkHome',
  'Rest',
  'Unknown',
]
export const enum EmployeeWorkStatusStateEnum {
  WorkOffice = 'WorkOffice',
  WorkHome = 'WorkHome',
  Rest = 'Rest',
  Unknown = 'Unknown',
}
export const FeatureNameEnumObjects: ModelEnumObjects = {
  Map: {
    position: 0,
  },
  Dashboard: {
    position: 1,
  },
  WorkStatus: {
    position: 2,
  },
  Delivery: {
    position: 3,
  },
  Task: {
    position: 4,
  },
  Resource: {
    position: 5,
  },
  Announcement: {
    position: 6,
  },
  AppLink: {
    position: 7,
  },
  Visitor: {
    position: 8,
  },
  AddressBook: {
    position: 9,
  },
}
export const FeatureNameEnums = [
  'Map',
  'Dashboard',
  'WorkStatus',
  'Delivery',
  'Task',
  'Resource',
  'Announcement',
  'AppLink',
  'Visitor',
  'AddressBook',
]
export const enum FeatureNameEnum {
  Map = 'Map',
  Dashboard = 'Dashboard',
  WorkStatus = 'WorkStatus',
  Delivery = 'Delivery',
  Task = 'Task',
  Resource = 'Resource',
  Announcement = 'Announcement',
  AppLink = 'AppLink',
  Visitor = 'Visitor',
  AddressBook = 'AddressBook',
}
export const AreaUnitEnumObjects: ModelEnumObjects = {
  meter: {
    position: 0,
  },
  foot: {
    position: 1,
  },
}
export const AreaUnitEnums = ['meter', 'foot']
export const enum AreaUnitEnum {
  meter = 'meter',
  foot = 'foot',
}
export const TimeFormatEnumObjects: ModelEnumObjects = {
  Twelve: {
    position: 0,
  },
  TwentyFour: {
    position: 1,
  },
}
export const TimeFormatEnums = ['Twelve', 'TwentyFour']
export const enum TimeFormatEnum {
  Twelve = 'Twelve',
  TwentyFour = 'TwentyFour',
}
export const DashboardTimeModeEnumObjects: ModelEnumObjects = {
  FullDay: {
    position: 0,
  },
  TimeSlot: {
    position: 1,
  },
  HalfDay: {
    position: 2,
  },
}
export const DashboardTimeModeEnums = ['FullDay', 'TimeSlot', 'HalfDay']
export const enum DashboardTimeModeEnum {
  FullDay = 'FullDay',
  TimeSlot = 'TimeSlot',
  HalfDay = 'HalfDay',
}
export const PadOSEnumObjects: ModelEnumObjects = {
  android: {
    position: 0,
  },
  ios: {
    position: 1,
  },
}
export const PadOSEnums = ['android', 'ios']
export const enum PadOSEnum {
  android = 'android',
  ios = 'ios',
}
export const PadAppTypeEnumObjects: ModelEnumObjects = {
  visitor: {
    position: 0,
  },
  meetingroom: {
    position: 1,
  },
  office: {
    position: 2,
  },
  panel: {
    position: 3,
  },
  signage: {
    position: 4,
  },
  locker: {
    position: 5,
  },
  meetingroom_pro: {
    position: 6,
  },
}
export const PadAppTypeEnums = [
  'visitor',
  'meetingroom',
  'office',
  'panel',
  'signage',
  'locker',
  'meetingroom_pro',
]
export const enum PadAppTypeEnum {
  visitor = 'visitor',
  meetingroom = 'meetingroom',
  office = 'office',
  panel = 'panel',
  signage = 'signage',
  locker = 'locker',
  meetingroom_pro = 'meetingroom_pro',
}
export const PadSubTypeEnumObjects: ModelEnumObjects = {
  front_desk: {
    position: 0,
  },
  meeting_display: {
    position: 1,
  },
  isaac: {
    position: 2,
  },
  tv_screen: {
    position: 3,
  },
  office_display: {
    position: 4,
  },
  meeting_panel: {
    position: 5,
  },
  office_panel: {
    position: 6,
  },
  digital_signage: {
    position: 7,
  },
  locker: {
    position: 8,
  },
  desk_panel: {
    position: 9,
  },
  pad: {
    position: 10,
  },
  pad_light: {
    position: 11,
  },
  pad_light_all: {
    position: 12,
  },
  visitor_pad: {
    position: 13,
  },
  visitor_machine: {
    position: 14,
  },
  visitor_double_faced: {
    position: 15,
  },
}
export const PadSubTypeEnums = [
  'front_desk',
  'meeting_display',
  'isaac',
  'tv_screen',
  'office_display',
  'meeting_panel',
  'office_panel',
  'digital_signage',
  'locker',
  'desk_panel',
  'pad',
  'pad_light',
  'pad_light_all',
  'visitor_pad',
  'visitor_machine',
  'visitor_double_faced',
]
export const enum PadSubTypeEnum {
  front_desk = 'front_desk',
  meeting_display = 'meeting_display',
  isaac = 'isaac',
  tv_screen = 'tv_screen',
  office_display = 'office_display',
  meeting_panel = 'meeting_panel',
  office_panel = 'office_panel',
  digital_signage = 'digital_signage',
  locker = 'locker',
  desk_panel = 'desk_panel',
  pad = 'pad',
  pad_light = 'pad_light',
  pad_light_all = 'pad_light_all',
  visitor_pad = 'visitor_pad',
  visitor_machine = 'visitor_machine',
  visitor_double_faced = 'visitor_double_faced',
}
export const PadLockTypeEnumObjects: ModelEnumObjects = {
  serial: {
    position: 0,
  },
  usb: {
    position: 1,
  },
  remote: {
    position: 2,
  },
  io: {
    position: 3,
  },
}
export const PadLockTypeEnums = ['serial', 'usb', 'remote', 'io']
export const enum PadLockTypeEnum {
  serial = 'serial',
  usb = 'usb',
  remote = 'remote',
  io = 'io',
}
export const PadStateEnumObjects: ModelEnumObjects = {
  enabled: {
    color: 'green',
    position: 0,
  },
  disabled: {
    color: 'red',
    position: 1,
  },
}
export const PadStateEnums = ['enabled', 'disabled']
export const enum PadStateEnum {
  enabled = 'enabled',
  disabled = 'disabled',
}
export const PadCommunicationModeEnumObjects: ModelEnumObjects = {
  socket: {
    position: 0,
  },
  iot: {
    position: 1,
  },
  all: {
    position: 2,
  },
}
export const PadCommunicationModeEnums = ['socket', 'iot', 'all']
export const enum PadCommunicationModeEnum {
  socket = 'socket',
  iot = 'iot',
  all = 'all',
}
export const AreaTypeEnumObjects: ModelEnumObjects = {
  delivery: {
    position: 0,
  },
  other: {
    position: 1,
  },
}
export const AreaTypeEnums = ['delivery', 'other']
export const enum AreaTypeEnum {
  delivery = 'delivery',
  other = 'other',
}
export const RoleLevelEnumObjects: ModelEnumObjects = {
  SPACE: {
    position: 0,
  },
  LOCATION: {
    position: 1,
  },
}
export const RoleLevelEnums = ['SPACE', 'LOCATION']
export const enum RoleLevelEnum {
  SPACE = 'SPACE',
  LOCATION = 'LOCATION',
}
export const PermissionTypeEnumObjects: ModelEnumObjects = {
  ONLY_SPACE: {
    position: 0,
  },
  ONLY_LOCATION: {
    position: 1,
  },
  ALL: {
    position: 2,
  },
}
export const PermissionTypeEnums = ['ONLY_SPACE', 'ONLY_LOCATION', 'ALL']
export const enum PermissionTypeEnum {
  ONLY_SPACE = 'ONLY_SPACE',
  ONLY_LOCATION = 'ONLY_LOCATION',
  ALL = 'ALL',
}
export const CustomerPlanLevelEnumObjects: ModelEnumObjects = {
  Team: {
    position: 0,
  },
  Premium: {
    color: 'blue',
    position: 1,
  },
  Enterprise: {
    color: 'orange',
    position: 2,
  },
}
export const CustomerPlanLevelEnums = ['Team', 'Premium', 'Enterprise']
export const enum CustomerPlanLevelEnum {
  Team = 'Team',
  Premium = 'Premium',
  Enterprise = 'Enterprise',
}
export const CustomerPricingIntervalEnumObjects: ModelEnumObjects = {
  Monthly: {
    position: 0,
  },
  Yearly: {
    position: 1,
  },
}
export const CustomerPricingIntervalEnums = ['Monthly', 'Yearly']
export const enum CustomerPricingIntervalEnum {
  Monthly = 'Monthly',
  Yearly = 'Yearly',
}
export const CustomerSubscriptionStatusEnumObjects: ModelEnumObjects = {
  Inactive: {
    color: 'red',
    position: 0,
  },
  Active: {
    color: 'green',
    position: 1,
  },
  Suspended: {
    color: '#D1D5DB',
    position: 2,
  },
  Canceled: {
    color: '#D1D5DB',
    position: 3,
  },
}
export const CustomerSubscriptionStatusEnums = [
  'Inactive',
  'Active',
  'Suspended',
  'Canceled',
]
export const enum CustomerSubscriptionStatusEnum {
  Inactive = 'Inactive',
  Active = 'Active',
  Suspended = 'Suspended',
  Canceled = 'Canceled',
}
export const ServiceTimeEnumObjects: ModelEnumObjects = {
  before_reservation: {
    color: 'description-gray',
    icon: 'alarm-clock',
    position: 0,
  },
  after_reservation: {
    color: 'description-gray',
    icon: 'alarm-clock',
    position: 1,
  },
  within_reservation: {
    color: 'description-gray',
    icon: 'alarm-clock',
    position: 2,
  },
}
export const ServiceTimeEnums = [
  'before_reservation',
  'after_reservation',
  'within_reservation',
]
export const enum ServiceTimeEnum {
  before_reservation = 'before_reservation',
  after_reservation = 'after_reservation',
  within_reservation = 'within_reservation',
}
export const SpaceStatusEnumObjects: ModelEnumObjects = {
  active: {
    position: 0,
  },
  init: {
    position: 1,
  },
  rejected: {
    position: 2,
  },
}
export const SpaceStatusEnums = ['active', 'init', 'rejected']
export const enum SpaceStatusEnum {
  active = 'active',
  init = 'init',
  rejected = 'rejected',
}
export const SpaceLevelEnumObjects: ModelEnumObjects = {
  Standard: {
    position: 0,
  },
  Premium: {
    position: 1,
  },
  Enterprise: {
    position: 2,
  },
}
export const SpaceLevelEnums = ['Standard', 'Premium', 'Enterprise']
export const enum SpaceLevelEnum {
  Standard = 'Standard',
  Premium = 'Premium',
  Enterprise = 'Enterprise',
}
export const TaskCategoryTypeEnumObjects: ModelEnumObjects = {
  Repair: {
    position: 0,
  },
  Meeting: {
    position: 1,
  },
  Cleaning: {
    position: 2,
  },
  Other: {
    position: 3,
  },
}
export const TaskCategoryTypeEnums = ['Repair', 'Meeting', 'Cleaning', 'Other']
export const enum TaskCategoryTypeEnum {
  Repair = 'Repair',
  Meeting = 'Meeting',
  Cleaning = 'Cleaning',
  Other = 'Other',
}
export const UserStateEnumObjects: ModelEnumObjects = {
  unconfirmed: {
    color: 'orange',
    position: 0,
  },
  active: {
    color: 'green',
    position: 1,
  },
  dismissed: {
    color: 'red',
    position: 2,
  },
}
export const UserStateEnums = ['unconfirmed', 'active', 'dismissed']
export const enum UserStateEnum {
  unconfirmed = 'unconfirmed',
  active = 'active',
  dismissed = 'dismissed',
}
export const UserGenderEnumObjects: ModelEnumObjects = {
  notSet: {
    position: 0,
  },
  male: {
    position: 1,
  },
  female: {
    position: 2,
  },
}
export const UserGenderEnums = ['notSet', 'male', 'female']
export const enum UserGenderEnum {
  notSet = 'notSet',
  male = 'male',
  female = 'female',
}
export const UserEmployeeTypeEnumObjects: ModelEnumObjects = {
  fulltime: {
    position: 0,
  },
  parttime: {
    position: 1,
  },
  contractor: {
    position: 2,
  },
  external: {
    position: 3,
  },
}
export const UserEmployeeTypeEnums = [
  'fulltime',
  'parttime',
  'contractor',
  'external',
]
export const enum UserEmployeeTypeEnum {
  fulltime = 'fulltime',
  parttime = 'parttime',
  contractor = 'contractor',
  external = 'external',
}
export const UserSyncProviderTypeEnumObjects: ModelEnumObjects = {
  SCIM: {
    position: 0,
  },
  GOOGLE_DIRECTORY: {
    position: 1,
  },
  MICROSOFT_ENTRA_ID: {
    position: 2,
  },
}
export const UserSyncProviderTypeEnums = [
  'SCIM',
  'GOOGLE_DIRECTORY',
  'MICROSOFT_ENTRA_ID',
]
export const enum UserSyncProviderTypeEnum {
  SCIM = 'SCIM',
  GOOGLE_DIRECTORY = 'GOOGLE_DIRECTORY',
  MICROSOFT_ENTRA_ID = 'MICROSOFT_ENTRA_ID',
}
export const ObjectAuditTypeEnumObjects: ModelEnumObjects = {
  User: {
    position: 0,
  },
  Resource: {
    position: 1,
  },
  ResourceReservation: {
    position: 2,
  },
  Task: {
    position: 3,
  },
  Visitor: {
    position: 4,
  },
  Delivery: {
    position: 5,
  },
  ResourceType: {
    position: 6,
  },
}
export const ObjectAuditTypeEnums = [
  'User',
  'Resource',
  'ResourceReservation',
  'Task',
  'Visitor',
  'Delivery',
  'ResourceType',
]
export const enum ObjectAuditTypeEnum {
  User = 'User',
  Resource = 'Resource',
  ResourceReservation = 'ResourceReservation',
  Task = 'Task',
  Visitor = 'Visitor',
  Delivery = 'Delivery',
  ResourceType = 'ResourceType',
}
export const ObjectAuditActionEnumObjects: ModelEnumObjects = {
  Create: {
    position: 0,
  },
  Update: {
    position: 1,
  },
  Delete: {
    position: 2,
  },
  Login: {
    position: 3,
  },
  Logout: {
    position: 4,
  },
  ChangePassword: {
    position: 5,
  },
  LoginFailed: {
    position: 6,
  },
  Sync: {
    position: 7,
  },
  SyncFailed: {
    position: 8,
  },
}
export const ObjectAuditActionEnums = [
  'Create',
  'Update',
  'Delete',
  'Login',
  'Logout',
  'ChangePassword',
  'LoginFailed',
  'Sync',
  'SyncFailed',
]
export const enum ObjectAuditActionEnum {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete',
  Login = 'Login',
  Logout = 'Logout',
  ChangePassword = 'ChangePassword',
  LoginFailed = 'LoginFailed',
  Sync = 'Sync',
  SyncFailed = 'SyncFailed',
}
export const SecurityAuditActionEnumObjects: ModelEnumObjects = {
  Login: {
    position: 0,
  },
  Logout: {
    position: 1,
  },
  LoginFailed: {
    position: 2,
  },
  LoginInvalidPassword: {
    position: 3,
  },
}
export const SecurityAuditActionEnums = [
  'Login',
  'Logout',
  'LoginFailed',
  'LoginInvalidPassword',
]
export const enum SecurityAuditActionEnum {
  Login = 'Login',
  Logout = 'Logout',
  LoginFailed = 'LoginFailed',
  LoginInvalidPassword = 'LoginInvalidPassword',
}
export const SecurityAuditClientTypeEnumObjects: ModelEnumObjects = {
  Web: {
    position: 0,
  },
  AdminPortal: {
    position: 1,
  },
  VisitorPortal: {
    position: 2,
  },
  App: {
    position: 3,
  },
  Outlook: {
    position: 4,
  },
  GoogleCalendar: {
    position: 5,
  },
  Cron: {
    position: 6,
  },
  Queue: {
    position: 7,
  },
  VisitorPad: {
    position: 8,
  },
  MeetingPad: {
    position: 9,
  },
  PartnerApi: {
    position: 10,
  },
  Ops: {
    position: 11,
  },
}
export const SecurityAuditClientTypeEnums = [
  'Web',
  'AdminPortal',
  'VisitorPortal',
  'App',
  'Outlook',
  'GoogleCalendar',
  'Cron',
  'Queue',
  'VisitorPad',
  'MeetingPad',
  'PartnerApi',
  'Ops',
]
export const enum SecurityAuditClientTypeEnum {
  Web = 'Web',
  AdminPortal = 'AdminPortal',
  VisitorPortal = 'VisitorPortal',
  App = 'App',
  Outlook = 'Outlook',
  GoogleCalendar = 'GoogleCalendar',
  Cron = 'Cron',
  Queue = 'Queue',
  VisitorPad = 'VisitorPad',
  MeetingPad = 'MeetingPad',
  PartnerApi = 'PartnerApi',
  Ops = 'Ops',
}
export const VisitorBlockItemTypeEnumObjects: ModelEnumObjects = {
  blacklist: {
    position: 0,
  },
  watchlist: {
    position: 1,
  },
}
export const VisitorBlockItemTypeEnums = ['blacklist', 'watchlist']
export const enum VisitorBlockItemTypeEnum {
  blacklist = 'blacklist',
  watchlist = 'watchlist',
}
export const VisitorBlockKeywordTypeEnumObjects: ModelEnumObjects = {
  phone_num: {
    color: 'gray',
    icon: 'smartphone',
    position: 0,
  },
  email: {
    color: 'gray',
    icon: 'mail',
    position: 1,
  },
  name: {
    color: 'gray',
    icon: 'user',
    position: 2,
  },
  company: {
    color: 'gray',
    icon: 'building-2',
    position: 3,
  },
}
export const VisitorBlockKeywordTypeEnums = [
  'phone_num',
  'email',
  'name',
  'company',
]
export const enum VisitorBlockKeywordTypeEnum {
  phone_num = 'phone_num',
  email = 'email',
  name = 'name',
  company = 'company',
}
export const VisitorPurposeEnumObjects: ModelEnumObjects = {
  BUSINESS: {
    position: 0,
  },
  CONTRACTOR: {
    position: 1,
  },
  INTERVIEW: {
    position: 2,
  },
  DELIVERY: {
    position: 3,
  },
  PERSONAL: {
    position: 4,
  },
  MEETING: {
    position: 5,
  },
  EVENT: {
    position: 6,
  },
  OTHER: {
    position: 7,
  },
}
export const VisitorPurposeEnums = [
  'BUSINESS',
  'CONTRACTOR',
  'INTERVIEW',
  'DELIVERY',
  'PERSONAL',
  'MEETING',
  'EVENT',
  'OTHER',
]
export const enum VisitorPurposeEnum {
  BUSINESS = 'BUSINESS',
  CONTRACTOR = 'CONTRACTOR',
  INTERVIEW = 'INTERVIEW',
  DELIVERY = 'DELIVERY',
  PERSONAL = 'PERSONAL',
  MEETING = 'MEETING',
  EVENT = 'EVENT',
  OTHER = 'OTHER',
}
export const InviteSectionTypeEnumObjects: ModelEnumObjects = {
  wifi: {
    position: 0,
  },
  lobby: {
    position: 1,
  },
  restroom: {
    position: 2,
  },
  storage: {
    position: 3,
  },
  waiting: {
    position: 4,
  },
  emergency: {
    position: 5,
  },
  beverage: {
    position: 6,
  },
  sign_out: {
    position: 7,
  },
  other: {
    position: 8,
  },
}
export const InviteSectionTypeEnums = [
  'wifi',
  'lobby',
  'restroom',
  'storage',
  'waiting',
  'emergency',
  'beverage',
  'sign_out',
  'other',
]
export const enum InviteSectionTypeEnum {
  wifi = 'wifi',
  lobby = 'lobby',
  restroom = 'restroom',
  storage = 'storage',
  waiting = 'waiting',
  emergency = 'emergency',
  beverage = 'beverage',
  sign_out = 'sign_out',
  other = 'other',
}
export const SignInSectionTypeEnumObjects: ModelEnumObjects = {
  traffic: {
    position: 0,
  },
  park: {
    position: 1,
  },
  elevator: {
    position: 2,
  },
  proscenium: {
    position: 3,
  },
  necessity: {
    position: 4,
  },
}
export const SignInSectionTypeEnums = [
  'traffic',
  'park',
  'elevator',
  'proscenium',
  'necessity',
]
export const enum SignInSectionTypeEnum {
  traffic = 'traffic',
  park = 'park',
  elevator = 'elevator',
  proscenium = 'proscenium',
  necessity = 'necessity',
}
export const GuideTypeEnumObjects: ModelEnumObjects = {
  invite: {
    position: 0,
  },
  sign_in: {
    position: 1,
  },
}
export const GuideTypeEnums = ['invite', 'sign_in']
export const enum GuideTypeEnum {
  invite = 'invite',
  sign_in = 'sign_in',
}
export const LawAgreementStatusEnumObjects: ModelEnumObjects = {
  enabled: {
    color: 'green',
    position: 0,
  },
  disabled: {
    color: 'red',
    position: 1,
  },
}
export const LawAgreementStatusEnums = ['enabled', 'disabled']
export const enum LawAgreementStatusEnum {
  enabled = 'enabled',
  disabled = 'disabled',
}
export const LawAgreementTypeEnumObjects: ModelEnumObjects = {
  html: {
    position: 0,
  },
  pdf: {
    position: 1,
  },
}
export const LawAgreementTypeEnums = ['html', 'pdf']
export const enum LawAgreementTypeEnum {
  html = 'html',
  pdf = 'pdf',
}
export const VisitorEmailNotificationStatusEnumObjects: ModelEnumObjects = {
  sending: {
    position: 0,
  },
  sent: {
    position: 1,
  },
  sent_error: {
    position: 2,
  },
  soft_bounce: {
    position: 3,
  },
  deliver: {
    position: 4,
  },
  open: {
    position: 5,
  },
  click: {
    position: 6,
  },
  invalid: {
    position: 7,
  },
  report_spam: {
    position: 8,
  },
}
export const VisitorEmailNotificationStatusEnums = [
  'sending',
  'sent',
  'sent_error',
  'soft_bounce',
  'deliver',
  'open',
  'click',
  'invalid',
  'report_spam',
]
export const enum VisitorEmailNotificationStatusEnum {
  sending = 'sending',
  sent = 'sent',
  sent_error = 'sent_error',
  soft_bounce = 'soft_bounce',
  deliver = 'deliver',
  open = 'open',
  click = 'click',
  invalid = 'invalid',
  report_spam = 'report_spam',
}
export const VisitorSmsNotificationStatusEnumObjects: ModelEnumObjects = {
  sending: {
    position: 0,
  },
  sent: {
    position: 1,
  },
  success: {
    position: 2,
  },
  bounced: {
    position: 3,
  },
}
export const VisitorSmsNotificationStatusEnums = [
  'sending',
  'sent',
  'success',
  'bounced',
]
export const enum VisitorSmsNotificationStatusEnum {
  sending = 'sending',
  sent = 'sent',
  success = 'success',
  bounced = 'bounced',
}
export const VisitorIdTypeEnumObjects: ModelEnumObjects = {
  none: {
    position: 0,
  },
  person_identification_card: {
    position: 1,
  },
  passport: {
    position: 2,
  },
  foreign_passport: {
    position: 3,
  },
  hk_or_macao_card: {
    position: 4,
  },
}
export const VisitorIdTypeEnums = [
  'none',
  'person_identification_card',
  'passport',
  'foreign_passport',
  'hk_or_macao_card',
]
export const enum VisitorIdTypeEnum {
  none = 'none',
  person_identification_card = 'person_identification_card',
  passport = 'passport',
  foreign_passport = 'foreign_passport',
  hk_or_macao_card = 'hk_or_macao_card',
}
export const VisitorIdCheckedSourceEnumObjects: ModelEnumObjects = {
  none: {
    position: 0,
  },
  manual: {
    position: 1,
  },
  pad: {
    position: 2,
  },
}
export const VisitorIdCheckedSourceEnums = ['none', 'manual', 'pad']
export const enum VisitorIdCheckedSourceEnum {
  none = 'none',
  manual = 'manual',
  pad = 'pad',
}
export const VisitorCategoryEnumObjects: ModelEnumObjects = {
  visitor: {
    position: 0,
  },
  employee: {
    position: 1,
  },
}
export const VisitorCategoryEnums = ['visitor', 'employee']
export const enum VisitorCategoryEnum {
  visitor = 'visitor',
  employee = 'employee',
}
export const VisitorUnifiedStatusEnumObjects: ModelEnumObjects = {
  approving: {
    color: '--wox-color-alert-warning',
    backgroundColor: '--wox-color-alert-warning-bg',
    icon: 'info',
    position: 0,
  },
  waiting_for_acceptance: {
    color: '--wox-color-alert-info',
    backgroundColor: '--wox-color-alert-info-bg',
    icon: 'info',
    position: 1,
  },
  waiting_for_sign: {
    color: '--wox-color-alert-warning',
    backgroundColor: '--wox-color-alert-warning-bg',
    icon: 'clock',
    position: 2,
  },
  signed_in: {
    color: '--wox-color-alert-success',
    backgroundColor: '--wox-color-alert-success-bg',
    icon: 'circle-check',
    position: 3,
  },
  signed_out: {
    color: '--wox-color-text',
    backgroundColor: '--wox-color-fill-quaternary',
    icon: 'check',
    position: 4,
  },
  cancelled: {
    color: '--wox-color-text',
    backgroundColor: '--wox-color-fill-quaternary',
    icon: 'circle-x',
    position: 5,
  },
}
export const VisitorUnifiedStatusEnums = [
  'approving',
  'waiting_for_acceptance',
  'waiting_for_sign',
  'signed_in',
  'signed_out',
  'cancelled',
]
export const enum VisitorUnifiedStatusEnum {
  approving = 'approving',
  waiting_for_acceptance = 'waiting_for_acceptance',
  waiting_for_sign = 'waiting_for_sign',
  signed_in = 'signed_in',
  signed_out = 'signed_out',
  cancelled = 'cancelled',
}
export const VisitorCancelReasonEnumObjects: ModelEnumObjects = {
  admin_cancel: {
    position: 0,
  },
  self_cancel: {
    position: 1,
  },
  approve_reject: {
    position: 2,
  },
  auto_cancel: {
    position: 3,
  },
}
export const VisitorCancelReasonEnums = [
  'admin_cancel',
  'self_cancel',
  'approve_reject',
  'auto_cancel',
]
export const enum VisitorCancelReasonEnum {
  admin_cancel = 'admin_cancel',
  self_cancel = 'self_cancel',
  approve_reject = 'approve_reject',
  auto_cancel = 'auto_cancel',
}
export const VisitorRequestTypeEnumObjects: ModelEnumObjects = {
  request: {
    position: 0,
  },
  invite: {
    position: 1,
  },
}
export const VisitorRequestTypeEnums = ['request', 'invite']
export const enum VisitorRequestTypeEnum {
  request = 'request',
  invite = 'invite',
}
export const VisitorRecurrenceTypeEnumObjects: ModelEnumObjects = {
  Occurance: {
    position: 0,
  },
  Exception: {
    position: 1,
  },
}
export const VisitorRecurrenceTypeEnums = ['Occurance', 'Exception']
export const enum VisitorRecurrenceTypeEnum {
  Occurance = 'Occurance',
  Exception = 'Exception',
}
export const VisitorRecordCycleTypeEnumObjects: ModelEnumObjects = {
  Daily: {
    position: 0,
  },
  Monthly: {
    position: 1,
  },
  Hourly: {
    position: 2,
  },
}
export const VisitorRecordCycleTypeEnums = ['Daily', 'Monthly', 'Hourly']
export const enum VisitorRecordCycleTypeEnum {
  Daily = 'Daily',
  Monthly = 'Monthly',
  Hourly = 'Hourly',
}
export const WebhookEventEnumObjects: ModelEnumObjects = {
  Ping: {
    position: 0,
  },
  Reservation: {
    position: 1,
  },
  Visitor: {
    position: 2,
  },
  Task: {
    position: 3,
  },
  Delivery: {
    position: 4,
  },
}
export const WebhookEventEnums = [
  'Ping',
  'Reservation',
  'Visitor',
  'Task',
  'Delivery',
]
export const enum WebhookEventEnum {
  Ping = 'Ping',
  Reservation = 'Reservation',
  Visitor = 'Visitor',
  Task = 'Task',
  Delivery = 'Delivery',
}
export const WebhookEventStatusEnumObjects: ModelEnumObjects = {
  Success: {
    color: 'green',
    position: 0,
  },
  Failed: {
    color: 'red',
    position: 1,
  },
}
export const WebhookEventStatusEnums = ['Success', 'Failed']
export const enum WebhookEventStatusEnum {
  Success = 'Success',
  Failed = 'Failed',
}
export const HolidayTypeEnumObjects: ModelEnumObjects = {
  public: {
    position: 0,
  },
  bank: {
    position: 1,
  },
  school: {
    position: 2,
  },
  optional: {
    position: 3,
  },
  observance: {
    position: 4,
  },
}
export const HolidayTypeEnums = [
  'public',
  'bank',
  'school',
  'optional',
  'observance',
]
export const enum HolidayTypeEnum {
  public = 'public',
  bank = 'bank',
  school = 'school',
  optional = 'optional',
  observance = 'observance',
}
export const ResourceListOrderTypeEnumObjects: ModelEnumObjects = {
  Resource: {
    position: 0,
  },
  ReservationRecord: {
    position: 1,
  },
  ReservationWaitlist: {
    position: 2,
  },
}
export const ResourceListOrderTypeEnums = [
  'Resource',
  'ReservationRecord',
  'ReservationWaitlist',
]
export const enum ResourceListOrderTypeEnum {
  Resource = 'Resource',
  ReservationRecord = 'ReservationRecord',
  ReservationWaitlist = 'ReservationWaitlist',
}
export const ResourceUsageModeEnumObjects: ModelEnumObjects = {
  shareable: {
    icon: 'users',
    position: 0,
  },
  exclusive: {
    icon: 'user',
    position: 1,
  },
}
export const ResourceUsageModeEnums = ['shareable', 'exclusive']
export const enum ResourceUsageModeEnum {
  shareable = 'shareable',
  exclusive = 'exclusive',
}
export const CustomFieldSourceTypeEnumObjects: ModelEnumObjects = {
  User: {
    position: 0,
  },
  Resource: {
    position: 1,
  },
  Visitor: {
    position: 2,
  },
  Reservation: {
    position: 3,
  },
}
export const CustomFieldSourceTypeEnums = [
  'User',
  'Resource',
  'Visitor',
  'Reservation',
]
export const enum CustomFieldSourceTypeEnum {
  User = 'User',
  Resource = 'Resource',
  Visitor = 'Visitor',
  Reservation = 'Reservation',
}
export const ResourceStateEnumObjects: ModelEnumObjects = {
  free: {
    color: 'green',
    position: 0,
  },
  assigned: {
    color: 'gray',
    position: 1,
  },
  disabled: {
    color: 'red',
    position: 2,
  },
}
export const ResourceStateEnums = ['free', 'assigned', 'disabled']
export const enum ResourceStateEnum {
  free = 'free',
  assigned = 'assigned',
  disabled = 'disabled',
}
export const ReservationTypeEnumObjects: ModelEnumObjects = {
  Reservation: {
    position: 0,
  },
  Service: {
    position: 1,
  },
}
export const ReservationTypeEnums = ['Reservation', 'Service']
export const enum ReservationTypeEnum {
  Reservation = 'Reservation',
  Service = 'Service',
}
export const ReservationRecurrenceTypeEnumObjects: ModelEnumObjects = {
  Occurance: {
    position: 0,
  },
  Exception: {
    position: 1,
  },
}
export const ReservationRecurrenceTypeEnums = ['Occurance', 'Exception']
export const enum ReservationRecurrenceTypeEnum {
  Occurance = 'Occurance',
  Exception = 'Exception',
}
export const ReservationStatusEnumObjects: ModelEnumObjects = {
  approving: {
    color: 'orange',
    backgroundColor: '#F97316',
    position: 0,
  },
  reserved: {
    color: 'blue',
    backgroundColor: '#9CA3AF',
    position: 1,
  },
  upcoming: {
    color: 'blue',
    backgroundColor: '#3B82F6',
    position: 2,
  },
  starting_soon: {
    color: 'cyan',
    backgroundColor: '#06B6D4',
    position: 3,
  },
  ongoing: {
    color: 'green',
    backgroundColor: '#10B981',
    position: 4,
  },
  ending_soon: {
    color: 'pink',
    backgroundColor: '#EC4899',
    position: 5,
  },
  terminated: {
    color: 'gray',
    backgroundColor: '#9CA3AF',
    position: 6,
  },
  cancelled: {
    color: 'gray',
    backgroundColor: '#9CA3AF',
    position: 7,
  },
}
export const ReservationStatusEnums = [
  'approving',
  'reserved',
  'upcoming',
  'starting_soon',
  'ongoing',
  'ending_soon',
  'terminated',
  'cancelled',
]
export const enum ReservationStatusEnum {
  approving = 'approving',
  reserved = 'reserved',
  upcoming = 'upcoming',
  starting_soon = 'starting_soon',
  ongoing = 'ongoing',
  ending_soon = 'ending_soon',
  terminated = 'terminated',
  cancelled = 'cancelled',
}
export const ReservationStatusIconEnumObjects: ModelEnumObjects = {
  approving: {
    color: '--wox-color-alert-warning',
    backgroundColor: '--wox-color-alert-warning-bg',
    icon: 'circle-alert',
    position: 0,
  },
  reserved: {
    color: '--wox-color-alert-info',
    backgroundColor: '--wox-color-alert-info-bg',
    icon: 'circle-check',
    position: 1,
  },
  upcoming: {
    color: '--wox-color-alert-success',
    backgroundColor: '--wox-color-alert-success-bg',
    icon: 'circle-check',
    position: 2,
  },
  starting_soon: {
    color: '--wox-color-alert-info',
    backgroundColor: '--wox-color-alert-info-bg',
    icon: 'info',
    position: 3,
  },
  ongoing: {
    color: '--wox-color-alert-success',
    backgroundColor: '--wox-color-alert-success-bg',
    icon: 'clock',
    position: 4,
  },
  ending_soon: {
    color: '--wox-color-alert-warning',
    backgroundColor: '--wox-color-alert-warning-bg',
    icon: 'clock',
    position: 5,
  },
  terminated: {
    color: '--wox-color-text',
    backgroundColor: '--wox-color-fill-quaternary',
    icon: 'clock',
    position: 6,
  },
  cancelled: {
    color: '--wox-color-text',
    backgroundColor: '--wox-color-fill-quaternary',
    icon: 'info',
    position: 7,
  },
}
export const ReservationStatusIconEnums = [
  'approving',
  'reserved',
  'upcoming',
  'starting_soon',
  'ongoing',
  'ending_soon',
  'terminated',
  'cancelled',
]
export const enum ReservationStatusIconEnum {
  approving = 'approving',
  reserved = 'reserved',
  upcoming = 'upcoming',
  starting_soon = 'starting_soon',
  ongoing = 'ongoing',
  ending_soon = 'ending_soon',
  terminated = 'terminated',
  cancelled = 'cancelled',
}
export const ReservationTimeModeEnumObjects: ModelEnumObjects = {
  free: {
    position: 0,
  },
  fullDay: {
    position: 1,
  },
}
export const ReservationTimeModeEnums = ['free', 'fullDay']
export const enum ReservationTimeModeEnum {
  free = 'free',
  fullDay = 'fullDay',
}
export const AttendeeStatusEnumObjects: ModelEnumObjects = {
  not_reply: {
    position: 0,
  },
  attend: {
    position: 1,
  },
  not_attend: {
    position: 2,
  },
  possible_attend: {
    position: 3,
  },
}
export const AttendeeStatusEnums = [
  'not_reply',
  'attend',
  'not_attend',
  'possible_attend',
]
export const enum AttendeeStatusEnum {
  not_reply = 'not_reply',
  attend = 'attend',
  not_attend = 'not_attend',
  possible_attend = 'possible_attend',
}
export const AttendeeRoleEnumObjects: ModelEnumObjects = {
  host: {
    color: 'pink',
    position: 0,
  },
  participant: {
    position: 1,
  },
  cc: {
    position: 2,
  },
}
export const AttendeeRoleEnums = ['host', 'participant', 'cc']
export const enum AttendeeRoleEnum {
  host = 'host',
  participant = 'participant',
  cc = 'cc',
}
export const TaskPriorityEnumObjects: ModelEnumObjects = {
  None: {
    color: 'gray',
    icon: 'x',
    position: 0,
  },
  Low: {
    color: 'gray',
    icon: 'x',
    position: 1,
  },
  Normal: {
    color: 'gray',
    icon: 'ellipsis',
    position: 2,
  },
  High: {
    color: 'red',
    icon: 'x',
    position: 3,
  },
  Urgent: {
    color: 'red',
    icon: 'check',
    position: 4,
  },
}
export const TaskPriorityEnums = ['None', 'Low', 'Normal', 'High', 'Urgent']
export const enum TaskPriorityEnum {
  None = 'None',
  Low = 'Low',
  Normal = 'Normal',
  High = 'High',
  Urgent = 'Urgent',
}
export const TaskStateEnumObjects: ModelEnumObjects = {
  Pending: {
    color: '--wox-color-alert-warning',
    backgroundColor: '--wox-color-alert-warning-bg',
    icon: 'clock',
    position: 0,
  },
  Received: {
    color: '--wox-color-alert-info',
    backgroundColor: '--wox-color-alert-info-bg',
    icon: 'circle-check',
    position: 1,
  },
  Completed: {
    color: '--wox-color-alert-success',
    backgroundColor: '--wox-color-alert-success-bg',
    icon: 'circle-check',
    position: 2,
  },
  Rejected: {
    color: '--wox-color-alert-error',
    backgroundColor: '--wox-color-alert-error-bg',
    icon: 'triangle-alert',
    position: 3,
  },
}
export const TaskStateEnums = ['Pending', 'Received', 'Completed', 'Rejected']
export const enum TaskStateEnum {
  Pending = 'Pending',
  Received = 'Received',
  Completed = 'Completed',
  Rejected = 'Rejected',
}
export const DeliveryPriorityEnumObjects: ModelEnumObjects = {
  None: {
    color: 'gray',
    icon: 'x',
    position: 0,
  },
  Low: {
    color: 'gray',
    icon: 'x',
    position: 1,
  },
  Normal: {
    color: 'gray',
    icon: 'ellipsis',
    position: 2,
  },
  High: {
    color: 'red',
    icon: 'x',
    position: 3,
  },
  Urgent: {
    color: 'red',
    icon: 'check',
    position: 4,
  },
}
export const DeliveryPriorityEnums = ['None', 'Low', 'Normal', 'High', 'Urgent']
export const enum DeliveryPriorityEnum {
  None = 'None',
  Low = 'Low',
  Normal = 'Normal',
  High = 'High',
  Urgent = 'Urgent',
}
export const DeliveryStateEnumObjects: ModelEnumObjects = {
  Pending: {
    color: '--wox-color-alert-warning',
    backgroundColor: '--wox-color-alert-warning-bg',
    icon: 'clock',
    position: 0,
  },
  PickedUp: {
    color: '--wox-color-alert-success',
    backgroundColor: '--wox-color-alert-success-bg',
    icon: 'circle-check',
    position: 1,
  },
  Rejected: {
    color: '--wox-color-alert-error',
    backgroundColor: '--wox-color-alert-error-bg',
    icon: 'triangle-alert',
    position: 2,
  },
}
export const DeliveryStateEnums = ['Pending', 'PickedUp', 'Rejected']
export const enum DeliveryStateEnum {
  Pending = 'Pending',
  PickedUp = 'PickedUp',
  Rejected = 'Rejected',
}
export const CalendarTypeEnumObjects: ModelEnumObjects = {
  Google: {
    position: 0,
  },
  Outlook: {
    position: 1,
  },
}
export const CalendarTypeEnums = ['Google', 'Outlook']
export const enum CalendarTypeEnum {
  Google = 'Google',
  Outlook = 'Outlook',
}
export const EmailTrackingTypeEnumObjects: ModelEnumObjects = {
  Reservation: {
    position: 0,
  },
  Visitor: {
    position: 1,
  },
  Task: {
    position: 2,
  },
  Delivery: {
    position: 3,
  },
  approval: {
    position: 4,
  },
  Blacklist: {
    position: 5,
  },
  Waitlist: {
    position: 6,
  },
  User: {
    position: 7,
  },
}
export const EmailTrackingTypeEnums = [
  'Reservation',
  'Visitor',
  'Task',
  'Delivery',
  'approval',
  'Blacklist',
  'Waitlist',
  'User',
]
export const enum EmailTrackingTypeEnum {
  Reservation = 'Reservation',
  Visitor = 'Visitor',
  Task = 'Task',
  Delivery = 'Delivery',
  approval = 'approval',
  Blacklist = 'Blacklist',
  Waitlist = 'Waitlist',
  User = 'User',
}
export const EmailRecordStatusEnumObjects: ModelEnumObjects = {
  OPENED: {
    position: 0,
  },
  REJECTED: {
    position: 1,
  },
  SENT: {
    position: 2,
  },
  DEFERRED: {
    position: 3,
  },
  DELIVERED: {
    position: 4,
  },
  BOUNCED: {
    position: 5,
  },
  DROPPED: {
    position: 6,
  },
  CLICKED: {
    position: 7,
  },
  BLOCKED: {
    position: 8,
  },
  SPAM: {
    position: 9,
  },
  UNSUBSCRIBED: {
    position: 10,
  },
  DELAYED: {
    position: 11,
  },
  COMPLAINT: {
    position: 12,
  },
  UNKNOWN: {
    position: 13,
  },
}
export const EmailRecordStatusEnums = [
  'OPENED',
  'REJECTED',
  'SENT',
  'DEFERRED',
  'DELIVERED',
  'BOUNCED',
  'DROPPED',
  'CLICKED',
  'BLOCKED',
  'SPAM',
  'UNSUBSCRIBED',
  'DELAYED',
  'COMPLAINT',
  'UNKNOWN',
]
export const enum EmailRecordStatusEnum {
  OPENED = 'OPENED',
  REJECTED = 'REJECTED',
  SENT = 'SENT',
  DEFERRED = 'DEFERRED',
  DELIVERED = 'DELIVERED',
  BOUNCED = 'BOUNCED',
  DROPPED = 'DROPPED',
  CLICKED = 'CLICKED',
  BLOCKED = 'BLOCKED',
  SPAM = 'SPAM',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
  DELAYED = 'DELAYED',
  COMPLAINT = 'COMPLAINT',
  UNKNOWN = 'UNKNOWN',
}
export const ExternalCalendarSubscriptionRenewStatusEnumObjects: ModelEnumObjects =
  {
    Success: {
      color: 'green',
      position: 0,
    },
    Error: {
      color: 'red',
      position: 1,
    },
  }
export const ExternalCalendarSubscriptionRenewStatusEnums = ['Success', 'Error']
export const enum ExternalCalendarSubscriptionRenewStatusEnum {
  Success = 'Success',
  Error = 'Error',
}
export const AppCategoryEnumObjects: ModelEnumObjects = {
  SSO: {
    icon: 'log-in',
    position: 1,
  },
  Calendar: {
    icon: 'calendar-days',
    position: 2,
  },
  FileStorage: {
    icon: 'folder',
    position: 3,
  },
  Directory: {
    icon: 'layout-list',
    position: 4,
  },
  HR: {
    icon: 'users',
    position: 5,
  },
  OA: {
    icon: 'monitor',
    position: 6,
  },
  Automation: {
    icon: 'refresh-cw',
    position: 7,
  },
  VideoMeeting: {
    icon: 'square-play',
    position: 8,
  },
  Notifications: {
    icon: 'bell',
    position: 9,
  },
  SmartHardware: {
    icon: 'cpu',
    position: 10,
  },
  Analytics: {
    icon: 'layout-grid',
    position: 11,
  },
  Other: {
    icon: 'layout-grid',
    position: 12,
  },
}
export const AppCategoryEnums = [
  'SSO',
  'Calendar',
  'FileStorage',
  'Directory',
  'HR',
  'OA',
  'Automation',
  'VideoMeeting',
  'Notifications',
  'SmartHardware',
  'Analytics',
  'Other',
]
export const enum AppCategoryEnum {
  SSO = 'SSO',
  Calendar = 'Calendar',
  FileStorage = 'FileStorage',
  Directory = 'Directory',
  HR = 'HR',
  OA = 'OA',
  Automation = 'Automation',
  VideoMeeting = 'VideoMeeting',
  Notifications = 'Notifications',
  SmartHardware = 'SmartHardware',
  Analytics = 'Analytics',
  Other = 'Other',
}
export const CalendarSyncDirectionEnumObjects: ModelEnumObjects = {
  Inbound: {
    position: 0,
  },
  Outbound: {
    position: 1,
  },
}
export const CalendarSyncDirectionEnums = ['Inbound', 'Outbound']
export const enum CalendarSyncDirectionEnum {
  Inbound = 'Inbound',
  Outbound = 'Outbound',
}
export const CalendarSyncStatusEnumObjects: ModelEnumObjects = {
  Success: {
    color: '--wox-color-alert-success',
    backgroundColor: '--wox-color-alert-success-bg',
    icon: 'circle-check',
    position: 0,
  },
  Error: {
    color: '--wox-color-alert-error',
    backgroundColor: '--wox-color-alert-error-bg',
    icon: 'circle-x',
    position: 1,
  },
}
export const CalendarSyncStatusEnums = ['Success', 'Error']
export const enum CalendarSyncStatusEnum {
  Success = 'Success',
  Error = 'Error',
}
export const CalendarSyncActionEnumObjects: ModelEnumObjects = {
  Create: {
    position: 0,
  },
  Update: {
    position: 1,
  },
  Delete: {
    position: 2,
  },
  Skip: {
    position: 3,
  },
}
export const CalendarSyncActionEnums = ['Create', 'Update', 'Delete', 'Skip']
export const enum CalendarSyncActionEnum {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete',
  Skip = 'Skip',
}
export const PolicyTypeEnumObjects: ModelEnumObjects = {
  ReservationReserveRule: {
    position: 0,
  },
  ReservationUpdateRule: {
    position: 1,
  },
  ReservationCheckInRule: {
    position: 2,
  },
  ReservationSecurityRule: {
    position: 3,
  },
  ReservationFormRule: {
    position: 4,
  },
  ReservationBlacklistRule: {
    position: 5,
  },
  TReservationServiceRule: {
    position: 6,
  },
}
export const PolicyTypeEnums = [
  'ReservationReserveRule',
  'ReservationUpdateRule',
  'ReservationCheckInRule',
  'ReservationSecurityRule',
  'ReservationFormRule',
  'ReservationBlacklistRule',
  'TReservationServiceRule',
]
export const enum PolicyTypeEnum {
  ReservationReserveRule = 'ReservationReserveRule',
  ReservationUpdateRule = 'ReservationUpdateRule',
  ReservationCheckInRule = 'ReservationCheckInRule',
  ReservationSecurityRule = 'ReservationSecurityRule',
  ReservationFormRule = 'ReservationFormRule',
  ReservationBlacklistRule = 'ReservationBlacklistRule',
  TReservationServiceRule = 'TReservationServiceRule',
}
export const ViolationTypeEnumObjects: ModelEnumObjects = {
  reserved_not_usage_count: {
    position: 0,
  },
  reserved_not_usage_minutes: {
    position: 1,
  },
}
export const ViolationTypeEnums = [
  'reserved_not_usage_count',
  'reserved_not_usage_minutes',
]
export const enum ViolationTypeEnum {
  reserved_not_usage_count = 'reserved_not_usage_count',
  reserved_not_usage_minutes = 'reserved_not_usage_minutes',
}
export const ViolationStatusEnumObjects: ModelEnumObjects = {
  active: {
    color: '--wox-color-alert-success',
    backgroundColor: '--wox-color-alert-success-bg',
    icon: 'circle-check',
    position: 0,
  },
  inactive: {
    color: '--wox-color-text',
    backgroundColor: '--wox-color-fill-quaternary',
    icon: 'circle-x',
    position: 1,
  },
}
export const ViolationStatusEnums = ['active', 'inactive']
export const enum ViolationStatusEnum {
  active = 'active',
  inactive = 'inactive',
}
export const BlacklistTypeEnumObjects: ModelEnumObjects = {
  user: {
    position: 0,
  },
  department: {
    position: 1,
  },
}
export const BlacklistTypeEnums = ['user', 'department']
export const enum BlacklistTypeEnum {
  user = 'user',
  department = 'department',
}
export const BlacklistSourceEnumObjects: ModelEnumObjects = {
  system: {
    position: 0,
  },
  admin: {
    position: 1,
  },
}
export const BlacklistSourceEnums = ['system', 'admin']
export const enum BlacklistSourceEnum {
  system = 'system',
  admin = 'admin',
}
export const BlacklistStatusEnumObjects: ModelEnumObjects = {
  effective: {
    color: '--wox-color-alert-success',
    backgroundColor: '--wox-color-alert-success-bg',
    icon: 'circle-check',
    position: 0,
  },
  unEffective: {
    color: '--wox-color-alert-warning',
    backgroundColor: '--wox-color-alert-warning-bg',
    icon: 'clock',
    position: 1,
  },
  expired: {
    color: '--wox-color-text',
    backgroundColor: '--wox-color-fill-quaternary',
    icon: 'circle-x',
    position: 2,
  },
}
export const BlacklistStatusEnums = ['effective', 'unEffective', 'expired']
export const enum BlacklistStatusEnum {
  effective = 'effective',
  unEffective = 'unEffective',
  expired = 'expired',
}
export const BlacklistOperationEnumObjects: ModelEnumObjects = {
  add_to_blacklist: {
    position: 0,
  },
  remove_from_blacklist: {
    position: 1,
  },
}
export const BlacklistOperationEnums = [
  'add_to_blacklist',
  'remove_from_blacklist',
]
export const enum BlacklistOperationEnum {
  add_to_blacklist = 'add_to_blacklist',
  remove_from_blacklist = 'remove_from_blacklist',
}
export const WaitlistStatusEnumObjects: ModelEnumObjects = {
  pending: {
    color: 'orange',
    icon: 'circle-ellipsis',
    position: 0,
  },
  pending_confirm: {
    color: 'blue',
    icon: 'circle-ellipsis',
    position: 1,
  },
  confirmed: {
    color: 'green',
    icon: 'circle-check',
    position: 2,
  },
  cancelled: {
    color: 'gray',
    icon: 'circle-x',
    position: 3,
  },
  failed: {
    color: 'gray',
    icon: 'circle-x',
    position: 4,
  },
}
export const WaitlistStatusEnums = [
  'pending',
  'pending_confirm',
  'confirmed',
  'cancelled',
  'failed',
]
export const enum WaitlistStatusEnum {
  pending = 'pending',
  pending_confirm = 'pending_confirm',
  confirmed = 'confirmed',
  cancelled = 'cancelled',
  failed = 'failed',
}
export const WaitlistStatusIconEnumObjects: ModelEnumObjects = {
  pending: {
    color: '--wox-color-alert-warning',
    backgroundColor: '--wox-color-alert-warning-bg',
    icon: 'clock',
    position: 0,
  },
  pending_confirm: {
    color: '--wox-color-alert-info',
    backgroundColor: '--wox-color-alert-info-bg',
    icon: 'info',
    position: 1,
  },
  confirmed: {
    color: '--wox-color-alert-success',
    backgroundColor: '--wox-color-alert-success-bg',
    icon: 'circle-check',
    position: 2,
  },
  cancelled: {
    color: '--wox-color-text',
    backgroundColor: '--wox-color-fill-quaternary',
    icon: 'info',
    position: 3,
  },
  failed: {
    color: '--wox-color-text',
    backgroundColor: '--wox-color-fill-quaternary',
    icon: 'info',
    position: 4,
  },
}
export const WaitlistStatusIconEnums = [
  'pending',
  'pending_confirm',
  'confirmed',
  'cancelled',
  'failed',
]
export const enum WaitlistStatusIconEnum {
  pending = 'pending',
  pending_confirm = 'pending_confirm',
  confirmed = 'confirmed',
  cancelled = 'cancelled',
  failed = 'failed',
}
export const EndReasonEnumObjects: ModelEnumObjects = {
  cancel_by_self: {
    position: 0,
  },
  timeout_unconfirmed: {
    position: 1,
  },
  waited_reservation_ended_normally: {
    position: 2,
  },
  waited_reservation_reserved_by_other: {
    position: 3,
  },
  waited_reservation_reserve_failed: {
    position: 4,
  },
  waitlist_reservation_cron_cancelled: {
    position: 5,
  },
  cancel_waitlist_reservation_by_self: {
    position: 6,
  },
  cancel_by_admin: {
    position: 7,
  },
  cancel_waitlist_reservation_by_admin: {
    position: 8,
  },
}
export const EndReasonEnums = [
  'cancel_by_self',
  'timeout_unconfirmed',
  'waited_reservation_ended_normally',
  'waited_reservation_reserved_by_other',
  'waited_reservation_reserve_failed',
  'waitlist_reservation_cron_cancelled',
  'cancel_waitlist_reservation_by_self',
  'cancel_by_admin',
  'cancel_waitlist_reservation_by_admin',
]
export const enum EndReasonEnum {
  cancel_by_self = 'cancel_by_self',
  timeout_unconfirmed = 'timeout_unconfirmed',
  waited_reservation_ended_normally = 'waited_reservation_ended_normally',
  waited_reservation_reserved_by_other = 'waited_reservation_reserved_by_other',
  waited_reservation_reserve_failed = 'waited_reservation_reserve_failed',
  waitlist_reservation_cron_cancelled = 'waitlist_reservation_cron_cancelled',
  cancel_waitlist_reservation_by_self = 'cancel_waitlist_reservation_by_self',
  cancel_by_admin = 'cancel_by_admin',
  cancel_waitlist_reservation_by_admin = 'cancel_waitlist_reservation_by_admin',
}
