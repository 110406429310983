import dayjs, { ESSENTIAL_TIME_ZONES, Timezone } from '@wox/dayjs'
import React from 'react'

import { KBSelect, KBSelectProps } from '@/components/atoms'
import { DefaultOptionType } from 'antd/es/select'

interface KBTimezoneOption extends DefaultOptionType {
  model: Timezone
}

interface KBTimezoneSelectProps extends KBSelectProps {
  // labelStyle?: 'original' | 'altName' | 'abbrev' | 'offsetHidden'
  // displayValue?: 'GMT' | 'UTC'
}
const KBTimezoneSelect: React.FC<KBTimezoneSelectProps> = (props) => {
  const { value, onChange, ...restProps } = props // default timezone
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const [timezone, setTimezone] = useState(browserTimeZone)

  const options: KBTimezoneOption[] = useMemo(
    () =>
      ESSENTIAL_TIME_ZONES.map((timezone: Timezone) => {
        // console.log('>>>ESSENTIAL_TIME_ZONES', timezone.value)
        const offset = dayjs().tz(timezone.value).utcOffset()
        // format offset to +08:00 style
        const hours = Math.floor(offset / 60)
        const minutes = Math.abs(offset % 60)
          .toString()
          .padStart(2, '0')
        const formattedOffset =
          offset > 0 ? `+${hours}:${minutes}` : `${hours}:${minutes}`
        const label = `UTC${formattedOffset} ${timezone.value}`
        return {
          value: timezone.value,
          // offset会根据夏令时变化，不能写死，需要动态计算
          label,
          model: timezone,
        }
      }),
    []
  )

  useEffect(() => {
    const selectedTimezone = options.find(
      (option) => option.value === value
    )?.model
    setTimezone(selectedTimezone?.value || browserTimeZone)
  }, [value])

  return (
    <KBSelect
      defaultValue={timezone}
      popupMatchSelectWidth={false}
      {...restProps}
      value={timezone}
      labelRender={(option) => {
        return option.value
      }}
      optionRender={(option) => {
        return option.label
      }}
      options={options}
      showSearchInput
      searchFn={(option, text) => {
        if (text) {
          return (
            String(option?.value).toLowerCase()?.includes(text.toLowerCase()) ||
            String(option?.label).toLowerCase()?.includes(text.toLowerCase())
          )
        }
        return false
      }}
      // dropdownStyle={{
      //   width: 420,
      // }}
      showSearch={false}
      onChange={(value, option) => {
        if (onChange) {
          const timezone = (option as KBTimezoneOption).model.value
          onChange(timezone, option)
        }
      }}
    />
  )
}

export default KBTimezoneSelect
