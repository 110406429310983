// @ts-nocheck
import client from "../../utils/axios";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { CustomerSubscriptionAdminFindCalculateCountQueryResponse, CustomerSubscriptionAdminFindCalculateCountQueryParams, CustomerSubscriptionAdminFindCalculateCount401 } from "../models/CustomerSubscriptionAdminFindCalculateCount";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type CustomerSubscriptionAdminFindCalculateCountClient = typeof client<CustomerSubscriptionAdminFindCalculateCountQueryResponse, CustomerSubscriptionAdminFindCalculateCount401, never>;
type CustomerSubscriptionAdminFindCalculateCount = {
    data: CustomerSubscriptionAdminFindCalculateCountQueryResponse;
    error: CustomerSubscriptionAdminFindCalculateCount401;
    request: never;
    pathParams: never;
    queryParams: CustomerSubscriptionAdminFindCalculateCountQueryParams;
    headerParams: never;
    response: CustomerSubscriptionAdminFindCalculateCountQueryResponse;
    client: {
        parameters: Partial<Parameters<CustomerSubscriptionAdminFindCalculateCountClient>[0]>;
        return: Awaited<ReturnType<CustomerSubscriptionAdminFindCalculateCountClient>>;
    };
};
export const customerSubscriptionAdminFindCalculateCountQueryKey = (params?: CustomerSubscriptionAdminFindCalculateCount["queryParams"]) => ["api", "v5", "admin", "subscriptions", "calculate-count", ...(params ? [params] : [])] as const;
export type CustomerSubscriptionAdminFindCalculateCountQueryKey = ReturnType<typeof customerSubscriptionAdminFindCalculateCountQueryKey>;
export function customerSubscriptionAdminFindCalculateCountQueryOptions(params?: CustomerSubscriptionAdminFindCalculateCount["queryParams"], options: CustomerSubscriptionAdminFindCalculateCount["client"]["parameters"] = {}) {
    const queryKey = customerSubscriptionAdminFindCalculateCountQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<CustomerSubscriptionAdminFindCalculateCount["data"], CustomerSubscriptionAdminFindCalculateCount["error"]>({
                method: "get",
                url: `/api/v5/admin/subscriptions/calculate-count`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 获取CustomerSubscriptionEntity详情
 * @link /api/v5/admin/subscriptions/calculate-count
 */
export function useCustomerSubscriptionAdminFindCalculateCount<TData = CustomerSubscriptionAdminFindCalculateCount["response"], TQueryData = CustomerSubscriptionAdminFindCalculateCount["response"], TQueryKey extends QueryKey = CustomerSubscriptionAdminFindCalculateCountQueryKey>(params?: CustomerSubscriptionAdminFindCalculateCount["queryParams"], options: {
    query?: Partial<QueryObserverOptions<CustomerSubscriptionAdminFindCalculateCount["response"], CustomerSubscriptionAdminFindCalculateCount["error"], TData, TQueryData, TQueryKey>>;
    client?: CustomerSubscriptionAdminFindCalculateCount["client"]["parameters"];
} = {}): UseQueryResult<TData, CustomerSubscriptionAdminFindCalculateCount["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? customerSubscriptionAdminFindCalculateCountQueryKey(params);
    const query = useQuery({
        ...customerSubscriptionAdminFindCalculateCountQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, CustomerSubscriptionAdminFindCalculateCount["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const customerSubscriptionAdminFindCalculateCountSuspenseQueryKey = (params?: CustomerSubscriptionAdminFindCalculateCount["queryParams"]) => ["api", "v5", "admin", "subscriptions", "calculate-count", ...(params ? [params] : [])] as const;
export type CustomerSubscriptionAdminFindCalculateCountSuspenseQueryKey = ReturnType<typeof customerSubscriptionAdminFindCalculateCountSuspenseQueryKey>;
export function customerSubscriptionAdminFindCalculateCountSuspenseQueryOptions(params?: CustomerSubscriptionAdminFindCalculateCount["queryParams"], options: CustomerSubscriptionAdminFindCalculateCount["client"]["parameters"] = {}) {
    const queryKey = customerSubscriptionAdminFindCalculateCountSuspenseQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<CustomerSubscriptionAdminFindCalculateCount["data"], CustomerSubscriptionAdminFindCalculateCount["error"]>({
                method: "get",
                url: `/api/v5/admin/subscriptions/calculate-count`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 获取CustomerSubscriptionEntity详情
 * @link /api/v5/admin/subscriptions/calculate-count
 */
export function useCustomerSubscriptionAdminFindCalculateCountSuspense<TData = CustomerSubscriptionAdminFindCalculateCount["response"], TQueryKey extends QueryKey = CustomerSubscriptionAdminFindCalculateCountSuspenseQueryKey>(params?: CustomerSubscriptionAdminFindCalculateCount["queryParams"], options: {
    query?: Partial<UseSuspenseQueryOptions<CustomerSubscriptionAdminFindCalculateCount["response"], CustomerSubscriptionAdminFindCalculateCount["error"], TData, TQueryKey>>;
    client?: CustomerSubscriptionAdminFindCalculateCount["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, CustomerSubscriptionAdminFindCalculateCount["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? customerSubscriptionAdminFindCalculateCountSuspenseQueryKey(params);
    const query = useSuspenseQuery({
        ...customerSubscriptionAdminFindCalculateCountSuspenseQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, CustomerSubscriptionAdminFindCalculateCount["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}