// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { VisitorCancelMutationRequest, VisitorCancelMutationResponse, VisitorCancelPathParams, VisitorCancel401, VisitorCancel406, VisitorCancel409 } from "../models/VisitorCancel";
import type { UseMutationOptions } from "@tanstack/react-query";

 type VisitorCancelClient = typeof client<VisitorCancelMutationResponse, VisitorCancel401 | VisitorCancel406 | VisitorCancel409, VisitorCancelMutationRequest>;
type VisitorCancel = {
    data: VisitorCancelMutationResponse;
    error: VisitorCancel401 | VisitorCancel406 | VisitorCancel409;
    request: VisitorCancelMutationRequest;
    pathParams: VisitorCancelPathParams;
    queryParams: never;
    headerParams: never;
    response: VisitorCancelMutationResponse;
    client: {
        parameters: Partial<Parameters<VisitorCancelClient>[0]>;
        return: Awaited<ReturnType<VisitorCancelClient>>;
    };
};
/**
 * @summary 访客取消
 * @link /api/v5/visitors/:id/cancel
 */
export function useVisitorCancel(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<VisitorCancel["response"], VisitorCancel["error"], {
        id: VisitorCancelPathParams["id"];
        data?: VisitorCancel["request"];
    }>;
    client?: VisitorCancel["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({ id, data }) => {
            const res = await client<VisitorCancel["data"], VisitorCancel["error"], VisitorCancel["request"]>({
                method: "post",
                url: `/api/v5/visitors/${id}/cancel`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "visitors"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}