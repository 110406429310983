// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { ResourceReservationCheckinMutationResponse, ResourceReservationCheckinPathParams, ResourceReservationCheckin401, ResourceReservationCheckin406, ResourceReservationCheckin409 } from "../models/ResourceReservationCheckin";
import type { UseMutationOptions } from "@tanstack/react-query";

 type ResourceReservationCheckinClient = typeof client<ResourceReservationCheckinMutationResponse, ResourceReservationCheckin401 | ResourceReservationCheckin406 | ResourceReservationCheckin409, never>;
type ResourceReservationCheckin = {
    data: ResourceReservationCheckinMutationResponse;
    error: ResourceReservationCheckin401 | ResourceReservationCheckin406 | ResourceReservationCheckin409;
    request: never;
    pathParams: ResourceReservationCheckinPathParams;
    queryParams: never;
    headerParams: never;
    response: ResourceReservationCheckinMutationResponse;
    client: {
        parameters: Partial<Parameters<ResourceReservationCheckinClient>[0]>;
        return: Awaited<ReturnType<ResourceReservationCheckinClient>>;
    };
};
/**
 * @summary checkin reservation
 * @link /api/v5/resource-reservations/:id/checkin
 */
export function useResourceReservationCheckin(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<ResourceReservationCheckin["response"], ResourceReservationCheckin["error"], {
        id: ResourceReservationCheckinPathParams["id"];
        data?: ResourceReservationCheckin["request"];
    }>;
    client?: ResourceReservationCheckin["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({ id }) => {
            const res = await client<ResourceReservationCheckin["data"], ResourceReservationCheckin["error"], ResourceReservationCheckin["request"]>({
                method: "patch",
                url: `/api/v5/resource-reservations/${id}/checkin`,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "resource-reservations"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}