// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { UploadUploadImageMutationResponse } from "../models/UploadUploadImage";
import type { UseMutationOptions } from "@tanstack/react-query";

 type UploadUploadImageClient = typeof client<UploadUploadImageMutationResponse, never, FormData>;
type UploadUploadImage = {
    data: UploadUploadImageMutationResponse;
    error: never;
    request: FormData;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: UploadUploadImageMutationResponse;
    client: {
        parameters: Partial<Parameters<UploadUploadImageClient>[0]>;
        return: Awaited<ReturnType<UploadUploadImageClient>>;
    };
};
/**
 * @link /api/v5/upload/single-image
 */
export function useUploadUploadImage(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<UploadUploadImage["response"], UploadUploadImage["error"], {
        data?: UploadUploadImage["request"];
    }>;
    client?: UploadUploadImage["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({ data }) => {
            const res = await client<UploadUploadImage["data"], UploadUploadImage["error"], UploadUploadImage["request"]>({
                method: "post",
                url: `/api/v5/upload/single-image`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "upload"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}