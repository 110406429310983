import { LucideProps } from 'lucide-react'
import dynamicIconImports from 'lucide-react/dynamicIconImports'
import { Suspense, lazy } from 'react'

const LoadingIcon = ({ size }: Pick<KBIconProps, 'size'>) => (
  <div style={{ width: size, height: size }} />
)

export type IconName = keyof typeof dynamicIconImports

export interface KBIconProps extends Omit<LucideProps, 'ref'> {
  name: IconName
}

const IconComponent = ({
  name,
  size = 16,
  strokeWidth = 2,
  ...props
}: KBIconProps) => {
  const LucideIcon = useMemo(() => lazy(dynamicIconImports[name]), [name])

  return (
    <Suspense fallback={<LoadingIcon size={size} />}>
      <LucideIcon size={size} strokeWidth={strokeWidth} {...props} />
    </Suspense>
  )
}

export const KBIcon = memo(IconComponent)
