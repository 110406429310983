// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AuthMeMutationResponse, AuthMe401 } from "../models/AuthMe";
import type { UseMutationOptions } from "@tanstack/react-query";

 type AuthMeClient = typeof client<AuthMeMutationResponse, AuthMe401, never>;
type AuthMe = {
    data: AuthMeMutationResponse;
    error: AuthMe401;
    request: never;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: AuthMeMutationResponse;
    client: {
        parameters: Partial<Parameters<AuthMeClient>[0]>;
        return: Awaited<ReturnType<AuthMeClient>>;
    };
};
/**
 * @summary 获取当前用户登录信息
 * @link /api/v5/auth/me
 */
export function useAuthMe(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<AuthMe["response"], AuthMe["error"], {
        data?: AuthMe["request"];
    }>;
    client?: AuthMe["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({}) => {
            const res = await client<AuthMe["data"], AuthMe["error"], AuthMe["request"]>({
                method: "post",
                url: `/api/v5/auth/me`,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "auth"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}