import { useTranslation } from 'react-i18next'
import { KBFlex, KBText, KBTooltip } from '@/components/atoms'
import { AlarmClock, CircleArrowLeft, CircleArrowRight } from 'lucide-react'
import { FC } from 'react'

interface ServiceTime {
  service_time: string
  required_minutes?: number
}

interface KBServiceTimeIconProps {
  serviceType?: ServiceTime
}
const KBServiceTimeIcon: FC<KBServiceTimeIconProps> = (props) => {
  const { t } = useTranslation()
  const { serviceType } = props
  if (!serviceType) {
    return null
  }

  switch (serviceType?.service_time) {
    case 'before_reservation':
      return (
        <KBFlex gap={4} align="center">
          <KBTooltip title={t('state.before_reservation')}>
            <CircleArrowLeft className="tw-flex tw-text-gray-500" size={18} />
          </KBTooltip>
          <KBText className="tw-text-gray-500">
            {t('common.minutes', {
              minutes: serviceType?.required_minutes || 0,
            })}
          </KBText>
        </KBFlex>
      )

    case 'after_reservation':
      return (
        <KBFlex gap={4} align="center">
          <KBTooltip title={t('state.after_reservation')}>
            <CircleArrowRight className="tw-text-gray-500" size={18} />
          </KBTooltip>
          <KBText className="tw-text-gray-500">
            {t('common.minutes', {
              minutes: serviceType?.required_minutes || 0,
            })}
          </KBText>
        </KBFlex>
      )

    case 'within_reservation':
      return (
        <KBTooltip title={t('state.within_reservation')}>
          <AlarmClock size={18} />
        </KBTooltip>
      )

    default:
      return null
  }
}

export default KBServiceTimeIcon
