import { useAtom } from 'jotai'
import React from 'react'
import { Navigate } from 'react-router-dom'

import { isAuthenticatedAtom } from '@/store/auth.atom'

interface ProtectedRouteProps {
  element: JSX.Element
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const [isAuthenticated] = useAtom(isAuthenticatedAtom)
  console.log('isAuthenticated', isAuthenticated)
  return isAuthenticated ? element : <Navigate to="/login" />
}

export default ProtectedRoute
