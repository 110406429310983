/**
 * Server backend URL: https://api.woxday.com
 */
export const BACKEND_URL = import.meta.env.VITE_BACKEND_URL
export const API_VERSION = import.meta.env.VITE_API_VERSION || 'v5'

export const BRAND = import.meta.env.VITE_BRAND
export const REGION = import.meta.env.VITE_REGION
export const NODE_ENV = import.meta.env.VITE_NODE_ENV
export const H5_URL = import.meta.env.VITE_H5_URL

export const USER_HELP_URL = import.meta.env.VITE_USER_HELP_URL
export const ADMIN_HELP_URL = import.meta.env.VITE_ADMIN_HELP_URL
export const TERMS_URL = import.meta.env.VITE_TERMS_URL
export const PRIVACY_URL = import.meta.env.VITE_PRIVACY_URL

export const CONTACT_EMAIL_ADDRESS = import.meta.env.VITE_CONTACT_EMAIL_ADDRESS

export const CLOUDFLARE_IMAGE_URL = import.meta.env.VITE_CLOUDFLARE_IMAGE_URL
export const GOOGLE_MAP_API_KEY = import.meta.env.VITE_GOOGLE_MAP_API_KEY

export const DEFAULT_BRAND_COLOR = '#004C3E'
export const HASHID_SALT =
  import.meta.env.VITE_HASHID_SALT || 'WoxIsTheBestInTheWorld'

/**
 * 分页API最大的单页数，用于使用paginateAPI但返回全部数据的情况
 */
export const MAX_PAGINATION_LIMIT = 1000

export const DEFAULT_PAGE_SIZE = 20
/**
 * Prod environment, including staging
 */
export const isProdEnv =
  import.meta.env.VITE_NODE_ENV === 'production' ||
  import.meta.env.VITE_NODE_ENV === 'staging'

/**
 * Development environment, including local and dev
 */
export const isDevEnv = !isProdEnv

/**
 * Check if deployment is China region
 */
export const isChinaRegion = import.meta.env.VITE_REGION === 'China'

/**
 * 常用颜色
 */
export const COLOR_PALETTES = [
  '#5470c6',
  '#91cc75',
  '#fac858',
  '#ee6666',
  '#73c0de',
  '#3ba272',
  '#fc8452',
  '#9a60b4',
  '#ea7ccc',
]

/**
 * 常用日期table列宽
 */
export const TIME_COL_WIDTH = 80 // 12:00 AM
export const SHORT_DATE_COL_WIDTH = 50 // 12/31
export const LONG_DATE_COL_WIDTH = 120 // 12/31/2022
export const DATE_RANGE_COL_WIDTH = 200 // 12/30/2022 - 12/31/2022
export const SHORT_DATETIME_COL_WIDTH = 120 // 12/31 12:00 AM
export const MEDIUM_DATETIME_COL_WIDTH = 150 // 12/31/2022 12:00
export const LONG_DATETIME_COL_WIDTH = 180 // 12/31/2022 12:00 AM
export const DATETIME_RANGE_COL_WIDTH = 300 // 12/31/2022 12:00 - 12/31/2022 14:00

/**
 * 接口缓存时间
 */
export const CACHE_TTL = 900000
