import { useLocationAdminFindById } from '@/api'
import { KBSuspenseLoading } from '@/components/atoms/KBSuspenseLoading'
import useCurrentLocationId from '@/hooks/useCurrentLocationId'
import { locationAtom } from '@/store'
import { useSetAtom } from 'jotai'
import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

export const LocationLayout: React.FC = () => {
  const currentLocationId = useCurrentLocationId()
  const setLocation = useSetAtom(locationAtom)
  const { data: currentLocation, isLoading } = useLocationAdminFindById(
    currentLocationId!
  )

  useEffect(() => {
    setLocation(currentLocation)
  }, [currentLocation])

  return isLoading ? <KBSuspenseLoading /> : <Outlet />
}
