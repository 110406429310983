import { useTranslation } from 'react-i18next'
import {
  VisitorEntity,
  VisitorFindManyQueryParams,
  useVisitorAdminFindManyWithSecurityToken,
} from '@/api'
import { KBFlex, KBPagination } from '@/components/atoms'
import { KBSearchInput } from '@/components/atoms/KBSearchInput'
import { KBSuspenseLoading } from '@/components/atoms/KBSuspenseLoading'
import PageContentLayout from '@/components/layouts/PageContentLayout'
import { KBEmpty } from '@/components/organisms/KBEmpty'
import KBVisitorListItem from '@/components/organisms/KBVisitorListItem/KBVisitorListItem'
import dayjs from '@wox/dayjs'
import { AnimatePresence } from 'framer-motion'

/**
 * 保安查看访客列表页面。该页面是公开的，使用token进行加密保护
 * http://localhost:5173/security/visitors?token=d9c544dfcb1b9b03&location_id=5
 *
 * @returns
 */
const VisitorSecurityListPage = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const currentLocationId = searchParams.get('location_id')

  useEffect(() => {
    if (!token) {
      // Redirect or show error if token is not present
      console.error('Security token is missing')
      return
    }
  }, [token])

  const [filter, setFilter] = useState<VisitorFindManyQueryParams>({
    expect_visit_start_at: dayjs().startOf('day').format(),
    expect_visit_end_at: dayjs().endOf('day').format(),
    host_user_id: undefined,
    name: undefined,
    page: 1,
  })

  const {
    data,
    isLoading: isLoadingVisitors,
    refetch: refetchVisitor,
  } = useVisitorAdminFindManyWithSecurityToken(
    {
      ...filter,
      location_id: +currentLocationId!,
      security_token: token!,
    },
    {
      query: {
        staleTime: 0,
        enabled: !!token && !!currentLocationId,
      },
    }
  )

  const visitors = data?.result as VisitorEntity[]

  return (
    <PageContentLayout icon="contact-round" title={t('common.visitorCheckIn')}>
      <KBFlex justify="space-between">
        <KBFlex gap={20}>
          <KBSearchInput
            onChange={(e) => {
              const value = e.target.value
              setFilter({
                ...filter,
                name: value === '' ? undefined : value,
                page: 1,
              })
              // setPage(1)
            }}
            placeholder={t('common.searchNamePhoneEmail')}
            style={{ width: 200 }}
            size="large"
          />
        </KBFlex>
      </KBFlex>
      <KBFlex vertical gap={0}>
        {isLoadingVisitors ? (
          <KBSuspenseLoading />
        ) : visitors?.length === 0 ? (
          <KBEmpty />
        ) : (
          <>
            <AnimatePresence>
              {visitors?.map((visitor) => (
                <KBVisitorListItem
                  key={`${visitor.id}`}
                  visitor={visitor}
                  readonly
                />
              ))}
            </AnimatePresence>
          </>
        )}
        <KBPagination
          total={data?.meta?.count || 0}
          hideOnSinglePage
          current={filter.page}
          onChange={(page) => {
            setFilter({
              ...filter,
              page,
            })
          }}
        />
      </KBFlex>
    </PageContentLayout>
  )
}

export default VisitorSecurityListPage
