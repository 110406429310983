import { FunctionComponent } from 'react'

export interface KBSvgIconProps {
  src: string
  color?: string
  height?: number
  width?: number
}

const KBSvgIcon: FunctionComponent<KBSvgIconProps> = (props) => {
  const { src, height = 16, width = 16, color } = props
  return (
    <img
      src={src}
      height={height}
      width={width}
      alt=""
      style={{ color: color }}
    />
  )
}

export default KBSvgIcon
