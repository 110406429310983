// @ts-nocheck
import { Form, FormProps } from 'antd'
import { useNavigate } from 'react-router-dom'

export interface KBFormProps extends FormProps {
  onValuesChange?: (changedValues: unknown, allValues: unknown) => void
  onSubmit?: (values: unknown) => void
}

export function KBForm(props: FormProps) {
  const { children, onValuesChange, onSubmit, ...restProps } = props

  const [isFormDirty, setIsFormDirty] = useState(false)
  const navigate = useNavigate()

  // useBlocker(
  //   ({ currentLocation, nextLocation }) =>
  //     isFormDirty && currentLocation.pathname !== nextLocation.pathname
  // )

  const handleValuesChange = (changedValues: unknown, allValues: unknown) => {
    setIsFormDirty(true)
    if (onValuesChange) {
      onValuesChange(changedValues, allValues)
    }
  }

  const handleFinish = (values: unknown) => {
    setIsFormDirty(false)
    if (onSubmit) {
      onSubmit(values)
    }
  }

  return (
    <Form
      size="large"
      onValuesChange={handleValuesChange}
      onFinish={handleFinish}
      {...restProps}
    >
      {children}
    </Form>
  )
}

KBForm.Item = Form.Item
KBForm.List = Form.List
KBForm.useForm = Form.useForm
KBForm.useWatch = Form.useWatch
