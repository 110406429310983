import dayjs, { Dayjs, findClosestTimeWithStep } from '@wox/dayjs'
import { useTranslation } from 'react-i18next'

import {
  CreateResourceReservationWithMultipleTimesDtoTimeMode,
  ReservationShowEntity,
  UpdateResourceReservationDtoAction,
} from '@/api'
import {
  KBDatePicker,
  KBFlex,
  KBForm,
  KBIcon,
  KBSpace,
  KBSwitch,
  KBText,
  KBTooltip,
} from '@/components/atoms'

import { KBDateTimeRangeSelect } from '@/components/atoms/KBDateTimeRangeSelect'
import { KBResourceReservationFormProps } from '@/components/molecules/KBResourceReservationForm/KBResourceReservationForm'
import { getRecurrenceDescription } from '@/components/organisms/KBRecurrenceEditModal/constants'
import KBRecurrenceDropdown from '@/components/organisms/KBRecurrenceEditModal/KBRecurrenceDropdown'
import KBRecurrenceEditModal, {
  RecurrenceInfo,
} from '@/components/organisms/KBRecurrenceEditModal/KBRecurrenceEditModal'
import KBTimezoneSelect from '@/components/organisms/KBTimezoneSelect/KBTimezoneSelect'
import { ReservationTimeModeEnum } from '@/enums/modelEnums'
import { localeAtom } from '@/store'
import { useAtom } from 'jotai'

export interface TimeSlotItem {
  startAt?: Dayjs
  endAt?: Dayjs
  timeMode?: CreateResourceReservationWithMultipleTimesDtoTimeMode
  timeZone?: string
  recurrenceInfo?: RecurrenceInfo
  multipleDates?: Dayjs[]
}

export interface TimeSlotSelectProps {
  id?: string
  step?: number
  recurringEditType?: UpdateResourceReservationDtoAction
  value?: TimeSlotItem
  onChange?: (data?: TimeSlotItem) => void
  reservationInfo?: ReservationShowEntity
  resourcesList: KBResourceReservationFormProps['resourcesList']
  showTimeSelect?: boolean
  allowRecurrence?: boolean
}

export const TimeSlots: React.FC<TimeSlotSelectProps> = (props) => {
  const { t } = useTranslation()
  const startAt = props.value?.startAt || dayjs()
  const {
    step = 30,
    value = {
      startAt: startAt, // findClosestTimeWithStep(startAt, step),
      endAt:
        props.value?.endAt ||
        findClosestTimeWithStep(startAt.add(step, 'minute'), step),
      timeMode: ReservationTimeModeEnum.free,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    id,
    recurringEditType,
    reservationInfo,
    resourcesList,
    showTimeSelect = true,
    allowRecurrence = true,
    onChange,
  } = props
  const [locale] = useAtom(localeAtom)
  const [startTimeBeforeFullDay, setStartTimeBeforeFullDay] = useState<
    Dayjs | undefined
  >()

  const TimeModesDescription = {
    [ReservationTimeModeEnum.free]: t('reservation.timeByFree'),
    [ReservationTimeModeEnum.fullDay]: t('reservation.timeByFullDay'),
  }

  const allTimeModes = [
    {
      key: ReservationTimeModeEnum.free,
      label: (
        <KBText>{TimeModesDescription[ReservationTimeModeEnum.free]}</KBText>
      ),
    },
    {
      key: ReservationTimeModeEnum.fullDay,
      label: (
        <KBText>{TimeModesDescription[ReservationTimeModeEnum.fullDay]}</KBText>
      ),
    },
  ]

  const [timeModeOptions, setTimeModeOptions] = useState(allTimeModes)

  const onTimeModeChange = (key: string) => {
    // 根据时间模式修改上面的时间选择器
    if (
      value.timeMode === ReservationTimeModeEnum.fullDay &&
      startTimeBeforeFullDay
    ) {
      value.startAt = startTimeBeforeFullDay
    }
    if (key === ReservationTimeModeEnum.fullDay) {
      // 切换全天后，保存之前选择的开始时间 - 因为全天会把开始时间初始成0点
      setStartTimeBeforeFullDay(value.startAt)
      onChange?.({
        ...value,
        timeMode: key,
        startAt: (value.startAt || dayjs()).startOf('day'),
        endAt: dayjs(value.endAt || dayjs()).endOf('day'),
      })
    } else {
      const startAt = dayjs()
      const endAt = findClosestTimeWithStep(startAt.add(1, 'hour'), step)

      onChange?.({
        ...value,
        timeMode: key as ReservationTimeModeEnum,
        startAt,
        endAt,
      })
    }
  }

  const timeZoneChange = (val: string) => {
    onChange?.({ ...value, timeZone: val })
  }

  const recurrenceDisabled = useMemo(() => {
    return (
      Boolean(recurringEditType) ||
      Number(value.multipleDates?.length) > 1 ||
      value.multipleDates?.length === 0 ||
      !allowRecurrence
    )
  }, [recurringEditType, value, allowRecurrence])

  const showRecurrence = useMemo(() => {
    return (
      (resourcesList || []).some((i) => {
        const rules = i.reservation_reserve_rule?.rules
        return rules?.allow_recurring
      }) || !resourcesList?.length
    )
  }, [resourcesList])

  const renderTimePicker = () => {
    if (value.multipleDates) {
      return (
        <KBDatePicker
          value={value.multipleDates}
          multiple
          maxTagCount={'responsive'}
          className=" tw-w-full"
          disabledDate={(date) => date.isBefore(dayjs().startOf('day'))}
          onChange={(val) => {
            let recurrenceInfo = value?.recurrenceInfo
            if (val?.length !== 1) {
              // 日期变化时清空周期性规则
              recurrenceInfo = undefined
            }
            onChange?.({
              ...value,
              multipleDates: val?.map((i: Dayjs) => dayjs(i.format())),
              recurrenceInfo,
            })
          }}
        />
      )
    }

    return (
      <KBDateTimeRangeSelect
        value={[value.startAt, value.endAt]}
        allowClear={false}
        step={step}
        hideTimePicker={value.timeMode !== ReservationTimeModeEnum.free}
        strictlyUsingValue
        // startUseCurrentTime={value.timeMode === ReservationTimeModeEnum.free}
        // endUseCurrentTime={value.timeMode === ReservationTimeModeEnum.free}
        disabledDate={(date) => {
          return date.isBefore(dayjs(), 'day')
        }}
        startDisabledTime={(date) => date.isBefore(dayjs(), 'minute')}
        onChange={(start, end) => {
          let recurrenceInfo = value?.recurrenceInfo
          if (
            !start?.isSame(value.startAt, 'day') ||
            !end?.isSame(value.endAt, 'day')
          ) {
            // 日期变化时清空周期性规则
            recurrenceInfo = undefined
          }
          onChange?.({
            ...value,
            startAt: start,
            endAt: end,
            recurrenceInfo,
          })
        }}
      />
    )
  }

  const reSelectTimeMode = () => {
    if (resourcesList.length === 1) {
      const [resource] = resourcesList
      const result = []

      if (resource.resource_type.book_by_day_only) {
        // 按天预定的资源类型
        result.push(allTimeModes[1])
      } else {
        result.push(...allTimeModes)
        // 非按天预定得资源类型
        // if (allow_free_time_mode_reserve) {
        //   result.push(allTimeModes[0])
        // }
        // if (allow_reserve_by_full_day) {
        //   result.push(allTimeModes[1])
        // }
        // if (allow_reserve_by_across_day) {
        //   result.push(allTimeModes[2])
        // }
      }

      // if (value?.timeMode && result.every((i) => i?.key !== value?.timeMode)) {
      //   console.log('触发重新选择时间', value?.timeMode, result)
      //   // 如果修改后之前的选项没了，那么自定义到自由预定 -- 初始化时，默认为自由预定，如果资源不能自由预定，这里会onChange到可选的时间模式
      //   onTimeModeChange(result?.[0]?.key || ReservationTimeModeEnum.free)
      // }
      setTimeModeOptions(result)
    } else {
      setTimeModeOptions([...allTimeModes])
    }
  }

  useEffect(() => {
    // 隐藏不能选择的时间模式--自动定位到能选的时间模式
    reSelectTimeMode()
  }, [resourcesList])

  return (
    <>
      <KBFlex id={id} align="start" vertical gap={6}>
        <KBFlex vertical gap={8} className="tw-w-full">
          {showTimeSelect && (
            <KBFlex gap={12}>
              {renderTimePicker()}
              {timeModeOptions.length > 1 &&
                !Boolean(value.multipleDates?.length) && (
                  <KBSpace size={8}>
                    <KBSwitch
                      checked={
                        value?.timeMode === ReservationTimeModeEnum.fullDay
                      }
                      size="small"
                      onChange={(checked) =>
                        onTimeModeChange(
                          checked
                            ? ReservationTimeModeEnum.fullDay
                            : ReservationTimeModeEnum.free
                        )
                      }
                    />

                    <KBText>{t('common.fullDay')}</KBText>
                  </KBSpace>
                )}
            </KBFlex>
          )}

          <KBFlex gap={8}>
            {showRecurrence && (
              <KBRecurrenceDropdown
                value={value.recurrenceInfo}
                startDate={
                  value.multipleDates
                    ? value.multipleDates?.[0]?.startOf('day')
                    : value.startAt
                }
                reserveEndDate={
                  value.multipleDates
                    ? value.multipleDates?.[0]?.endOf('day')
                    : value.endAt
                }
                disabled={recurrenceDisabled}
                onChange={(info) => {
                  onChange?.({ ...value, recurrenceInfo: info })
                }}
              />
            )}

            {value.timeMode === ReservationTimeModeEnum.free && (
              <KBForm.Item className="tw-mb-0">
                <KBTimezoneSelect
                  className="tw-w-[191px]"
                  value={value.timeZone}
                  onChange={(value) => timeZoneChange(value)}
                  variant="borderless"
                />
              </KBForm.Item>
            )}
          </KBFlex>
          {value.recurrenceInfo && (
            <KBText className=" tw-text-gray-500">
              {getRecurrenceDescription(value.recurrenceInfo, locale)}
            </KBText>
          )}
        </KBFlex>
      </KBFlex>
      <KBRecurrenceEditModal id="KBRecurrenceEditModal" />
    </>
  )
}

/**
 * 时间组件
 */
const TimeSlotSelect: React.FC<
  TimeSlotSelectProps & {
    errorsMessages?: string
  }
> = (props) => {
  const { t } = useTranslation()
  const { errorsMessages } = props

  return (
    <KBForm.Item
      name="timeData"
      label={
        <KBTooltip title={t('common.timeMode')}>
          <KBIcon size={24} name="clock" />
        </KBTooltip>
      }
      dependencies={['resourceIds']}
      validateStatus={Boolean(errorsMessages?.length) ? 'error' : undefined}
      help={errorsMessages}
    >
      <TimeSlots {...props} />
    </KBForm.Item>
  )
}

export default TimeSlotSelect
