import NiceModal, { useModal } from '@ebay/nice-modal-react'
import dayjs, { Dayjs } from '@wox/dayjs'
import { useTranslation } from 'react-i18next'

import {
  RecurringEventDto,
  ResourceReservationValidateRecurrenceTimeConflictMutationResponse,
  useResourceReservationAdminValidateRecurrenceTimeConflict,
  useResourceReservationValidateRecurrenceTimeConflict,
} from '@/api'
import {
  KBDatePicker,
  KBFlex,
  KBInputNumber,
  KBModal,
  KBSelect,
  KBText,
} from '@/components/atoms'
import KBRecurrenceByMonth from '@/components/organisms/KBRecurrenceEditModal/KBRecurrenceByMonth'
import KBRecurrenceByWeek from '@/components/organisms/KBRecurrenceEditModal/KBRecurrenceByWeek'
import KBRecurrenceByYear from '@/components/organisms/KBRecurrenceEditModal/KBRecurrenceByYear'
import { localeAtom } from '@/store'
import { isUserClient } from '@/utils/userEnvironment'
import { useAtom } from 'jotai'
import { pick } from 'radash'
import {
  RecurrenceByMonthInterval,
  RecurrenceByYearInterval,
  RecurrenceInterval,
  getRecurrenceDescription,
  recurrenceInfoToDto,
} from './constants'

export interface RecurrenceInfo {
  /**
   * @description 重复类型, 用于回显
   */
  intervalType: RecurrenceInterval
  /**
   * @description 重复间隔
   */
  interval?: number
  /**
   * @description 开始时间
   */
  startDate: Dayjs
  /**
   * @description 结束时间
   */
  endDate: Dayjs
  /**
   * @description 按周预定，选择的周几
   */
  selectedWeekdays?: number[]
  /**
   * @description 按月/年预定，每月几号
   */
  monthDay?: number
  /**
   * @description 按月/年预定，第几周
   */
  monthWeekNumber?: number
  /**
   * @description 按月/年预定，周几
   */
  monthWeekDay?: number
  /**
   * @description 按年预定，几月
   */
  month?: number
  /**
   * @description 按月预定，重复方式
   */
  monthRecurrenceByMonthInterval?: RecurrenceByMonthInterval
  /**
   * @description 按年预定，重复方式
   */
  yearRecurrenceByYearInterval?: RecurrenceByYearInterval
}

export interface KBRecurrenceEditModalProps {
  intervalType?: RecurrenceInterval
  startTime?: Dayjs
  endTime?: Dayjs
  recurrenceInfo?: RecurrenceInfo
  reserveEndDate?: Dayjs
  onConfirm?: (info: RecurrenceInfo) => void
  isShowYear?: boolean
}

function KBRecurrenceEditModal(props: KBRecurrenceEditModalProps) {
  const { t } = useTranslation()
  const {
    intervalType = RecurrenceInterval.day,
    startTime = dayjs(),
    endTime = dayjs().startOf('month').add(1, 'M'),
    reserveEndDate,
    isShowYear = false,
    onConfirm,
  } = props
  const modal = useModal()
  const [recurrentValidateMessage, setRecurrentValidateMessage] = useState('')
  const recurrenceIntervalOptions = [
    { label: t('common.day'), value: RecurrenceInterval.day },
    { label: t('common.week'), value: RecurrenceInterval.week },
    { label: t('common.month'), value: RecurrenceInterval.month },
    // 暂时去掉按年预定
    { label: t('common.year'), value: RecurrenceInterval.year },
  ]
  const [locale] = useAtom(localeAtom)

  const defaultRecurrenceInfo = {
    intervalType: intervalType || RecurrenceInterval.noRecurrence,
    startDate: startTime,
    endDate: endTime,
    interval: 1,
  }
  const [recurrenceInfo, setRecurrenceInfo] = useState<RecurrenceInfo>(
    props.recurrenceInfo || defaultRecurrenceInfo
  )

  // useEffect(() => {
  //   setRecurrenceInfo({
  //     ...defaultRecurrenceInfo,
  //     ...props.recurrenceInfo,
  //     startDate: startTime,
  //     endDate: endTime,
  //   })
  // }, [props.recurrenceInfo, startTime, endTime, intervalType])

  const { mutate: validateRule, isPending } = isUserClient()
    ? useResourceReservationValidateRecurrenceTimeConflict()
    : useResourceReservationAdminValidateRecurrenceTimeConflict()

  function onOk() {
    validateRule(
      {
        data: {
          start_at: recurrenceInfo.startDate.format(),
          end_at: dayjs(reserveEndDate).format(),
          recurring_event: recurrenceInfoToDto(
            recurrenceInfo
          ) as RecurringEventDto,
        },
      },
      {
        onSuccess() {
          onConfirm?.(recurrenceInfo)
          modal.hide()
        },
        onError(
          e: ResourceReservationValidateRecurrenceTimeConflictMutationResponse
        ) {
          const errors = e?.response?.data?.message
          setRecurrentValidateMessage(errors)
        },
      }
    )
  }

  console.log('>>> recurrenceInfo', recurrenceInfo)

  return (
    <KBModal width={512} title={t('recurrence.edit')} modal={modal} onOk={onOk}>
      <div>
        <KBFlex align="center" gap={8}>
          <KBText className="tw-w-20 tw-flex-shrink-0">
            {t('reservation.recurrenceInterval')}
          </KBText>
          {recurrenceInfo.intervalType !== RecurrenceInterval.year && (
            <KBInputNumber
              className="tw-flex-1"
              value={recurrenceInfo.interval}
              precision={0}
              min={1}
              onChange={(value) =>
                setRecurrenceInfo({
                  ...recurrenceInfo,
                  // 不能为负数或者空
                  interval: (value || 1) as number,
                })
              }
            />
          )}

          <KBSelect
            options={
              isShowYear
                ? recurrenceIntervalOptions
                : recurrenceIntervalOptions?.filter(
                    (item) => item.value !== RecurrenceInterval.year
                  )
            }
            className={
              recurrenceInfo.intervalType === RecurrenceInterval.year
                ? 'tw-w-full'
                : 'tw-w-20'
            }
            defaultValue={recurrenceInfo.intervalType}
            onChange={(value: RecurrenceInterval) => {
              const startDate = dayjs(recurrenceInfo.startDate)
              if (value === RecurrenceInterval.day) {
                setRecurrenceInfo({
                  ...pick(recurrenceInfo, ['startDate', 'endDate', 'interval']),
                  intervalType: value,
                })
              } else if (value === RecurrenceInterval.week) {
                setRecurrenceInfo({
                  ...pick(recurrenceInfo, ['startDate', 'endDate', 'interval']),
                  selectedWeekdays: [startDate.day()],
                  intervalType: value,
                })
              } else if (value === RecurrenceInterval.month) {
                setRecurrenceInfo({
                  ...pick(recurrenceInfo, ['startDate', 'endDate', 'interval']),
                  monthRecurrenceByMonthInterval: RecurrenceByMonthInterval.day,
                  monthDay: startDate.date(),
                  intervalType: value,
                })
              } else if (value === RecurrenceInterval.year) {
                setRecurrenceInfo({
                  ...pick(recurrenceInfo, ['startDate', 'endDate']),
                  interval: 1,
                  yearRecurrenceByYearInterval: RecurrenceByYearInterval.day,
                  monthDay: startDate.date(),
                  // monthWeekNumber: 1,
                  month: startDate.month() + 1,
                  // monthWeekDay: dayjs().weekday(),
                  // ...recurrenceInfo,
                  intervalType: value,
                })
              }
            }}
          />
        </KBFlex>

        {/* 按周重复 */}
        {recurrenceInfo.intervalType === RecurrenceInterval.week && (
          <KBRecurrenceByWeek
            {...props}
            recurrenceInfo={recurrenceInfo}
            setRecurrenceInfo={setRecurrenceInfo}
          />
        )}

        {/* 按月重复 */}
        {recurrenceInfo.intervalType === RecurrenceInterval.month && (
          <KBRecurrenceByMonth
            {...props}
            recurrenceInfo={recurrenceInfo}
            setRecurrenceInfo={setRecurrenceInfo}
          />
        )}

        {/* 按年预定 */}
        {recurrenceInfo.intervalType === RecurrenceInterval.year && (
          <KBRecurrenceByYear
            {...props}
            recurrenceInfo={recurrenceInfo}
            setRecurrenceInfo={setRecurrenceInfo}
          />
        )}

        <KBFlex align="center" gap={8} className="tw-my-6">
          <KBText className=" tw-w-20">{t('recurrence.endDate')}</KBText>
          <KBDatePicker
            className="tw-flex-1"
            allowClear={false}
            value={recurrenceInfo.endDate}
            disabledDate={(date) =>
              date.isBefore(dayjs(recurrenceInfo.startDate), 'day')
            }
            onChange={(value) =>
              setRecurrenceInfo({ ...recurrenceInfo, endDate: value })
            }
          />
        </KBFlex>
        <KBText className="tw-text-[var(--wox-color-text-description)]">
          {getRecurrenceDescription(recurrenceInfo, locale)}
        </KBText>
        {recurrentValidateMessage && (
          <KBFlex className="">
            <KBText type="danger">{recurrentValidateMessage}</KBText>
          </KBFlex>
        )}
      </div>
    </KBModal>
  )
}

export default NiceModal.create(KBRecurrenceEditModal)
