// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AuthForgotPasswordMutationRequest, AuthForgotPasswordMutationResponse } from "../models/AuthForgotPassword";
import type { UseMutationOptions } from "@tanstack/react-query";

 type AuthForgotPasswordClient = typeof client<AuthForgotPasswordMutationResponse, never, AuthForgotPasswordMutationRequest>;
type AuthForgotPassword = {
    data: AuthForgotPasswordMutationResponse;
    error: never;
    request: AuthForgotPasswordMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: AuthForgotPasswordMutationResponse;
    client: {
        parameters: Partial<Parameters<AuthForgotPasswordClient>[0]>;
        return: Awaited<ReturnType<AuthForgotPasswordClient>>;
    };
};
/**
 * @summary 用户忘记密码，发送验证邮件，用于用户找回密码
 * @link /api/v5/auth/email/forgot-password
 */
export function useAuthForgotPassword(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<AuthForgotPassword["response"], AuthForgotPassword["error"], {
        data?: AuthForgotPassword["request"];
    }>;
    client?: AuthForgotPassword["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({ data }) => {
            const res = await client<AuthForgotPassword["data"], AuthForgotPassword["error"], AuthForgotPassword["request"]>({
                method: "post",
                url: `/api/v5/auth/email/forgot-password`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "auth"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}