import { KBSuspenseLoading } from '@/components/atoms/KBSuspenseLoading'
import { convertLocaleNameToDayjsLocale } from '@/utils/datetime/diffTime'
import {
  ILocale,
  SUPPORTED_LOCALES,
} from '@/utils/localization/supported_locales'
import { formatStorage } from '@wox/dayjs'
import { ConfigProvider, ThemeConfig } from 'antd'
import { Locale } from 'antd/es/locale'
import originalDayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'
import { useAtom } from 'jotai'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { localeAtom } from '../store'

originalDayjs.extend(updateLocale)
interface LocaleProviderProps {
  theme?: ThemeConfig
  children: React.ReactNode
}

export function LocaleProvider({ theme, children }: LocaleProviderProps) {
  const [locale] = useAtom(localeAtom)
  const [antdLocale, setAntdLocale] = useState<Locale | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const { i18n } = useTranslation()
  useEffect(() => {
    setIsLoading(true)

    const loadLocaleData = async () => {
      const localeData =
        SUPPORTED_LOCALES.find((l: ILocale) => l.value === locale) ||
        SUPPORTED_LOCALES.find((l: ILocale) => l.value === 'en-US')!

      const antDResult = await localeData.antD()
      const localeName = localeData.value // 'zh-cn'
      setAntdLocale(antDResult.default)

      // 更新dayjs的locale，antd的locale更新后会自动更新dayjs的locale
      const dayjsLocale = convertLocaleNameToDayjsLocale(localeName)
      originalDayjs.locale(dayjsLocale)
      originalDayjs.updateLocale(dayjsLocale, {
        weekStart: formatStorage.get()?.firstDayOfWeek || 0, // first day of week
      })
      i18n.changeLanguage(localeName)
      setIsLoading(false)
    }
    loadLocaleData()
  }, [locale])
  if (isLoading) {
    return <KBSuspenseLoading fullScreen />
  }
  return (
    <ConfigProvider locale={antdLocale} theme={theme}>
      {children}
    </ConfigProvider>
  )
}
