import { IconName, KBIcon, KBIconProps } from '@/components/atoms/KBIcon'
import { cn } from '@/utils/tailwind.helper'
import { App, ButtonProps, Modal, message as antdMessage, theme } from 'antd'
import { ArgsProps } from 'antd/es/message'
import type { MessageInstance } from 'antd/es/message/interface'
import { ModalStaticFunctions } from 'antd/es/modal/confirm'
import { HookAPI } from 'antd/es/modal/useModal'
import { Toaster, toast } from 'sonner'
import './index.css'
const { useToken } = theme
interface CustomModalIconItem {
  key: keyof Omit<ModalStaticFunctions, 'warn'>
  iconName: KBIconProps['name']
  iconClassName: string
  iconContainerClassName: string
  okButtonProps?: ButtonProps
}
;[]

const CustomModalIcons: CustomModalIconItem[] = [
  {
    key: 'warning',
    iconName: 'lightbulb',
    iconClassName: 'tw-text-orange-900',
    iconContainerClassName: 'tw-bg-orange-100',
  },
  // confirm样式同warning
  {
    key: 'confirm',
    iconName: 'lightbulb',
    iconClassName: 'tw-text-orange-900',
    iconContainerClassName: 'tw-bg-orange-100',
    okButtonProps: {
      danger: true,
    },
  },
  {
    key: 'error',
    iconName: 'lightbulb',
    iconClassName: 'tw-text-red-900',
    iconContainerClassName: 'tw-bg-red-100',
  },
  {
    key: 'info',
    iconName: 'lightbulb',
    iconClassName: 'tw-text-blue-900',
    iconContainerClassName: 'tw-bg-blue-100',
  },
  {
    key: 'success',
    iconName: 'lightbulb',
    iconClassName: 'tw-text-green-900',
    iconContainerClassName: 'tw-bg-green-100',
  },
]

type MessageType = 'success' | 'error' | 'warning' | 'info' | 'loading'

let kbMessage: MessageInstance = antdMessage
// let kbNotification: NotificationInstance = antdNotification
let kbNotification: typeof toast = toast

interface CustomMessageOptions extends Omit<ArgsProps, 'icon'> {
  iconName?: IconName
  iconClassName?: string
}

/**
 * 重写antd modal的静态方法 加入了默认icon
 */
let kbModal: HookAPI

function KBMessageGlobal() {
  const staticFunction = App.useApp()
  const [messageApi, contextHolder] = antdMessage.useMessage()
  const [modal, modalContextHolder] = Modal.useModal()
  const { token } = useToken()

  const rewriteModalStaticFunctions = (modal: HookAPI) => {
    CustomModalIcons.forEach((item) => {
      const {
        key,
        iconContainerClassName,
        iconClassName,
        iconName,
        okButtonProps,
      } = item
      const _iconContainerClassName = `tw-flex-none tw-mr-4 tw-w-10 tw-h-10 tw-flex tw-justify-center tw-items-center tw-rounded-full ${iconContainerClassName}`
      const oldFunction = modal[key]

      modal[key] = (params) =>
        oldFunction({
          transitionName: 'ant-fade',
          icon: (
            <div className={_iconContainerClassName}>
              <KBIcon
                name={iconName}
                size={20}
                className={iconClassName}
              ></KBIcon>
            </div>
          ),

          // confirm的确定按钮默认为红色，用到的地方可以传入新的覆盖
          okButtonProps: okButtonProps,
          ...params,
        })
    })

    return modal
  }
  const rewriteMessageStaticFunctions = (
    message: MessageInstance
  ): MessageInstance => {
    const customMessage = (type: keyof typeof antdMessage) => {
      return (
        content: React.ReactNode,
        options: CustomMessageOptions = {
          content,
        }
      ) => {
        const iconMapping: Record<MessageType, IconName> = {
          success: 'check',
          error: 'x',
          warning: 'circle-alert',
          info: 'info',
          loading: 'loader-circle',
        }
        const iconName: IconName =
          options.iconName || iconMapping[type as MessageType]

        const _content = (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <KBIcon
              name={iconName}
              className={cn('tw-mr-2', type === 'loading' && 'tw-animate-spin')}
              strokeWidth={2}
            />

            {content}
          </div>
        )

        const _options = { ...options, content: _content }
        message.open({
          duration: options.duration ?? 3,
          className: cn('kb-message', options.className),
          ..._options,
        })
      }
    }

    return {
      ...message,
      success: customMessage('success'),
      error: customMessage('error'),
      info: customMessage('info'),
      warning: customMessage('warning'),
      loading: customMessage('loading'),
    } as MessageInstance
  }

  useEffect(() => {
    kbMessage = rewriteMessageStaticFunctions(messageApi)
    // kbNotification = staticFunction.notification
    kbNotification = toast
    kbModal = rewriteModalStaticFunctions(modal)
  })

  return (
    <>
      {contextHolder}
      {modalContextHolder}
      <Toaster position="top-right" richColors />
    </>
  )
}

export { kbMessage, kbModal, kbNotification }
export default KBMessageGlobal
