import { useAtom } from 'jotai'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { isAuthenticatedAtom } from '@/store'

const RedirectToDashboard: React.FC = () => {
  const navigate = useNavigate()
  const [isAuthenticated] = useAtom(isAuthenticatedAtom)

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/user/dashboard')
    } else {
      console.log('redirect to login')
      navigate('/login')
    }
  }, [isAuthenticated, navigate])

  return null
}

export default RedirectToDashboard
