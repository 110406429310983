import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useTranslation } from 'react-i18next'

import { KBInput, KBModal } from '@/components/atoms'

interface AddServiceNoteProps {
  serviceTypeId?: number
  resourceId?: number
  value?: string
  onConfirm?: (
    value: string,
    resourceId?: number,
    serviceTypeId?: number
  ) => void
}
function AddServiceNote(props: AddServiceNoteProps) {
  const { t } = useTranslation()
  const { resourceId, serviceTypeId, value = '', onConfirm } = props
  const modal = useModal()
  const [note, setNote] = useState(value)

  const _onConfirm = () => {
    modal.hide()
    onConfirm?.(note, resourceId, serviceTypeId)
  }

  return (
    <KBModal modal={modal} title={t('common.addRemarks')} onOk={_onConfirm}>
      <KBInput.TextArea
        placeholder={t('common.serviceNotePlaceholder')}
        value={note}
        rows={4}
        onChange={(event) => {
          setNote(event.target.value)
        }}
      />
    </KBModal>
  )
}

const AddServiceNoteModal = NiceModal.create(AddServiceNote)

export default AddServiceNoteModal
