import type { UploadFile, UploadProps } from 'antd'
import { Modal, Upload, theme } from 'antd'
import { UploadListType } from 'antd/es/upload/interface'
import { nanoid } from 'nanoid'
import { useEffect, useRef, useState } from 'react'
import { Cropper, CropperRef } from 'react-advanced-cropper'
import 'react-advanced-cropper/dist/style.css'
import 'react-advanced-cropper/dist/themes/corners.css'
import { useTranslation } from 'react-i18next'

import { UploadEntity, useUploadUploadImage } from '@/api'
import { KBFlex } from '@/components/atoms/KBFlex'
import { KBIcon, KBIconProps } from '@/components/atoms/KBIcon'
import { kbMessage } from '@/components/atoms/KBMessageGlobal'
import { KBSuspenseLoading } from '@/components/atoms/KBSuspenseLoading'
import { KBText } from '@/components/atoms/KBText'
import { KBTitle } from '@/components/atoms/KBTitle'
import { KBTypography } from '@/components/atoms/KBTypography'
import KBImagePreview from '@/components/atoms/KBUploadImage/KBImagePreview'
import { cn } from '@/utils/tailwind.helper'
const { useToken } = theme

interface CropperModalHeaderProps {
  title?: string
  subTitle?: string
}

function CropperModalHeader(props: CropperModalHeaderProps) {
  const { t } = useTranslation()
  const { title, subTitle } = props
  return (
    <KBFlex vertical>
      <KBTitle level={5}>{title}</KBTitle>
      <KBText>{subTitle}</KBText>
    </KBFlex>
  )
}

export interface UploadFileItem extends UploadFile {
  file?: File
}

export interface KBUploadImageProps extends CropperModalHeaderProps {
  onChange?: (
    fileList?: UploadFileItem | UploadFileItem[] | string | string[],
    uploadEntity?: UploadEntity
  ) => void
  value?: string | string[] // Array of image URLs
  /**
   * 是否预先上传模式. 注意 preUpload=true时，onChange返回的是图片的url而不是file对象。否则返回的是file对象
   *
   * 1. 预先上传，即在选择图片后立即上传，而不是在点击确认按钮后上传。返回的是图片的url而不是file对象。前端通过json传递
   *
   * 2. 后上传模式下，返回的是file对象。后端需要接收file对象，前端需要使用FormData上传
   *
   * @default false
   */
  preUpload?: boolean
  /**
   * 是否裁剪模式
   */
  crop?: boolean

  /**
   * 是否显示预览
   */
  showFileList?: boolean
  /**
   * 如果crop=true，则需要设置裁剪比例。默认1
   */
  aspectRatio?: number
  /**
   * 最大上传数量
   */
  maxCount?: number
  /**
   * 上传提示
   */
  uploadTips?: string
  /**
   * 上传图标
   */
  uploadIcon?: KBIconProps['name']
  /**
   * 是否显示上传提示
   */
  showUploadTips?: boolean
  /**
   * 是否显示上传描述文案
   */
  showUploadDescription?: boolean
  /**
   * 最大文件大小
   */
  maxFileSize?: number
  /**
   * 预览高度
   */
  previewHeight?: number
  /**
   * 预览宽度
   */
  previewWidth?: number
  listType?: UploadListType
  className?: string
  children?: React.ReactNode
  /**
   * onRemove回调
   * @param url
   * @returns
   */
  onRemove?: (url: string) => void
}

export function KBUploadImage(props: KBUploadImageProps) {
  const { t } = useTranslation()
  const {
    title = t('common.cropping'),
    subTitle,
    preUpload = true, // 是否预先上传
    crop = true, // 是否crop
    showFileList = true, // 是否显示预览
    onChange,
    aspectRatio,
    value,
    maxCount = 1,
    previewHeight = 90,
    previewWidth,
    maxFileSize = 1024 * 1024 * 10, // 10MB
    listType = 'picture',
    className,
    children,
    uploadIcon = 'plus',
    showUploadDescription = true,
    uploadTips,
    showUploadTips = true,
  } = props

  // console.log('showUploadTips:', showUploadTips)

  const [currentImage, setCurrentImage] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [fileList, setFileList] = useState<UploadFileItem[]>([])
  const cropperRef = useRef<CropperRef | null>(null)
  const { mutate: uploadImage, isPending, isError } = useUploadUploadImage()
  const [showCropper, setShowCropper] = useState<boolean>(false)
  const { token } = useToken()
  useEffect(() => {
    if (value) {
      let values: string[]
      if (Array.isArray(value)) {
        values = value
      } else {
        values = [value]
      }

      const initialFileList = values.map((url, index) =>
        // value有可能只是url字符串，也有可能是UploadFileItem对象
        typeof url === 'string'
          ? {
              uid: nanoid(),
              name: `image_${index}.png`,
              type: 'image/png',
              status: 'done' as const,
              url,
            }
          : url
      )

      setFileList(initialFileList)
    }
  }, [value])

  const onBeforeUpload: UploadProps['beforeUpload'] = (file) => {
    // 1. 文件后缀校验
    const allowedExtensions = ['.jpg', '.jpeg', '.png'] // 允许的文件后缀
    const fileExtension = file.name.toLowerCase().split('.').pop()

    if (!allowedExtensions.includes(`.${fileExtension}`)) {
      kbMessage.error(t('common.invalidFileType')) // 显示文件类型错误提示
      setIsModalOpen(false) // 关闭裁剪弹窗
      return false // 阻止裁剪弹窗开启
    }
    if (!crop) {
      const localImgUrl = URL.createObjectURL(file)
      // setFileList([
      //   ...fileList,
      //   {
      //     ...file,
      //     url: localImgUrl,
      //   },
      // ])
      if (file.size > maxFileSize) {
        kbMessage.error(t('common.fileTooLarge'))
        return
      }
      handleUpload(file)
      return false
    }
    setIsModalOpen(true)
    const localImgUrl = URL.createObjectURL(file)
    setCurrentImage(localImgUrl)

    return false
  }

  const handleUpload = (clipImgFile: File) => {
    const formData = new FormData()
    formData.append('file', clipImgFile)
    // const modal = kbModal.info({
    //   title: tr('common.uploading'),
    //   content: <KBSuspenseLoading />,
    //   footer: null,
    // })

    uploadImage(
      {
        data: formData,
      },
      {
        onSuccess: (res) => {
          const newFile: UploadFileItem = {
            uid: nanoid(),
            file: clipImgFile,
            type: 'image/png',
            name: `image_${Date.now()}.png`,
            url: res.url,
            status: 'done',
          }
          const newFileList = [...fileList, newFile].slice(-maxCount) // Ensure maxNumber of files
          setFileList(newFileList)
          maxCount === 1
            ? onChange?.(newFileList[0].url, res)
            : onChange?.(
                newFileList.map((item) => item.url!),
                res
              ) // Pass the updated
          setIsModalOpen(false)
          setCurrentImage('')
          setShowCropper(false)
        },
      }
    )
  }

  const onCropperModalOK = () => {
    cropperRef.current?.getCanvas()?.toBlob((clipImgBlob) => {
      if (clipImgBlob) {
        if (clipImgBlob.size > maxFileSize) {
          kbMessage.error(t('common.fileTooLarge'))
          return
        }
        const fileName = `image_${Date.now()}.png`
        const clipImgFile = new File([clipImgBlob], fileName, {
          type: clipImgBlob.type,
        })

        if (preUpload) {
          handleUpload(clipImgFile)
          return
        }

        const localImgUrl = URL.createObjectURL(clipImgBlob)
        const newFileList = [
          ...fileList,
          {
            uid: nanoid(),
            file: clipImgFile,
            type: 'image/png',
            name: fileName,
            url: localImgUrl,
          },
        ].slice(-maxCount) // Ensure maxNumber of files

        setFileList(newFileList)
        maxCount === 1 ? onChange?.(newFileList[0]) : onChange?.(newFileList) // Pass the updated file list to the parent
        setIsModalOpen(false)
        setCurrentImage('')
        setShowCropper(false)
      }
    })
  }

  const onRemove = (file: UploadFileItem) => {
    const newFileList = fileList.filter((item) => item.uid !== file.uid)
    setFileList(newFileList)
    if (preUpload) {
      maxCount === 1
        ? onChange?.(newFileList?.[0]?.url)
        : onChange?.(newFileList.map((item) => item.url!))
      props.onRemove?.(file.url!)
    } else {
      maxCount === 1 ? onChange?.(newFileList?.[0]) : onChange?.(newFileList)
      props.onRemove?.(file.url!)
    }
  }

  const calculateWidth = (height: number) => {
    return height * (aspectRatio || 1)
  }

  const _previewWidth = previewWidth
    ? previewWidth
    : calculateWidth(previewHeight)

  let _uploadTips = ''
  if (uploadTips) {
    _uploadTips = uploadTips
  } else {
    if (maxCount > 1) {
      if (crop) {
        _uploadTips = t('common.multipleUploadCropDescription')
      } else {
        _uploadTips = t('common.multipleUploadDescription')
      }
    } else {
      if (crop) {
        _uploadTips = t('common.singleUploadCropDescription')
      } else {
        _uploadTips = t('common.singleUploadDescription')
      }
    }
  }

  return (
    <>
      <KBFlex gap={8} align="center" className="ant-upload-images">
        {showFileList &&
          fileList.map((file) => (
            <KBImagePreview
              key={file.uid}
              previewHeight={previewHeight}
              previewWidth={_previewWidth}
              url={file.url || file.thumbUrl!}
              onRemove={() => onRemove(file as UploadFileItem)}
            />
          ))}
        <Upload
          accept="image/*"
          listType={listType}
          showUploadList={false}
          fileList={fileList}
          onRemove={(file) => onRemove(file as UploadFileItem)}
          // onPreview={(file) => onPreview(file as UploadFileItem)}
          beforeUpload={onBeforeUpload}
          className={className}
          style={{ width: `${_previewWidth}px`, height: `${previewHeight}px` }}
        >
          {children ||
            (fileList.length < maxCount && (
              <KBFlex
                vertical
                justify="center"
                align="center"
                gap={8}
                className={cn(
                  'tw-box-border tw-cursor-pointer tw-rounded-md tw-px-4 tw-text-center'
                )}
                style={{
                  width: `${_previewWidth}px`,
                  height: `${previewHeight}px`,
                  border: `1px dashed ${token.colorBorder}`,
                }}
              >
                {isPending ? (
                  <KBSuspenseLoading />
                ) : (
                  <>
                    <KBIcon name={uploadIcon} size={20} />
                    {showUploadDescription && (
                      <KBTypography.Text>
                        {t('common.upload')}
                      </KBTypography.Text>
                    )}
                  </>
                )}
              </KBFlex>
            ))}
        </Upload>
      </KBFlex>
      {showUploadTips && (
        <div className="tw-mt-[5px]">
          <KBTypography.Text className="tw-text-[var(--wox-color-text-description)]">
            {_uploadTips}
          </KBTypography.Text>
        </div>
      )}

      {currentImage && (
        <Modal
          maskClosable={false}
          open={isModalOpen}
          title={<CropperModalHeader title={title} subTitle={subTitle} />}
          onOk={onCropperModalOK}
          okButtonProps={{
            loading: isPending,
            className: 'tw-h-9',
          }}
          cancelButtonProps={{ type: 'text', className: 'tw-h-9' }}
          onCancel={() => setIsModalOpen(false)}
          afterOpenChange={() => {
            setShowCropper(isModalOpen)
          }}
          classNames={{
            wrapper: 'tw-z-[9999999]',
            mask: 'tw-z-[9999999]',
          }}
        >
          <div className="tw-relative">
            {isPending && (
              <KBFlex
                align="center"
                justify="center"
                className="tw-absolute tw-z-[9999999] tw-h-full tw-w-full tw-bg-white
              tw-bg-opacity-80"
              >
                <KBSuspenseLoading />
              </KBFlex>
            )}

            {showCropper ? (
              <Cropper
                ref={cropperRef}
                src={currentImage}
                className="cropper"
                stencilProps={{
                  aspectRatio,
                  responsive: true,
                }}
              />
            ) : (
              <KBSuspenseLoading />
            )}
          </div>
        </Modal>
      )}
    </>
  )
}
