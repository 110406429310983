// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { ResourceReservationAdminValidateRecurrenceTimeConflictMutationRequest, ResourceReservationAdminValidateRecurrenceTimeConflictMutationResponse, ResourceReservationAdminValidateRecurrenceTimeConflict401 } from "../models/ResourceReservationAdminValidateRecurrenceTimeConflict";
import type { UseMutationOptions } from "@tanstack/react-query";

 type ResourceReservationAdminValidateRecurrenceTimeConflictClient = typeof client<ResourceReservationAdminValidateRecurrenceTimeConflictMutationResponse, ResourceReservationAdminValidateRecurrenceTimeConflict401, ResourceReservationAdminValidateRecurrenceTimeConflictMutationRequest>;
type ResourceReservationAdminValidateRecurrenceTimeConflict = {
    data: ResourceReservationAdminValidateRecurrenceTimeConflictMutationResponse;
    error: ResourceReservationAdminValidateRecurrenceTimeConflict401;
    request: ResourceReservationAdminValidateRecurrenceTimeConflictMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: ResourceReservationAdminValidateRecurrenceTimeConflictMutationResponse;
    client: {
        parameters: Partial<Parameters<ResourceReservationAdminValidateRecurrenceTimeConflictClient>[0]>;
        return: Awaited<ReturnType<ResourceReservationAdminValidateRecurrenceTimeConflictClient>>;
    };
};
/**
 * @summary 验证周期性规则时间冲突
 * @link /api/v5/admin/resource-reservations/validate-recurrence-time-conflict
 */
export function useResourceReservationAdminValidateRecurrenceTimeConflict(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<ResourceReservationAdminValidateRecurrenceTimeConflict["response"], ResourceReservationAdminValidateRecurrenceTimeConflict["error"], {
        data?: ResourceReservationAdminValidateRecurrenceTimeConflict["request"];
    }>;
    client?: ResourceReservationAdminValidateRecurrenceTimeConflict["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({ data }) => {
            const res = await client<ResourceReservationAdminValidateRecurrenceTimeConflict["data"], ResourceReservationAdminValidateRecurrenceTimeConflict["error"], ResourceReservationAdminValidateRecurrenceTimeConflict["request"]>({
                method: "post",
                url: `/api/v5/admin/resource-reservations/validate-recurrence-time-conflict`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "admin", "resource-reservations"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}