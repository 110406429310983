import { atomWithStorage, createJSONStorage } from 'jotai/utils'

// const getDefaultLocale = () => {
//   let currentLocale = intl.determineLocale({
//     urlLocaleKey: 'lang',
//     cookieLocaleKey: 'lang',
//     localStorageLocaleKey: 'lang',
//   })
//   if (!SUPPORTED_LOCALES.find((x: ILocale) => x.value === currentLocale)) {
//     currentLocale = 'en-US'
//   }
//   return currentLocale
// }

export const localeAtom = atomWithStorage<string>(
  'lang',
  // default locale if not exist
  'en-US',
  createJSONStorage(),
  {
    getOnInit: true,
  }
)
