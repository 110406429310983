// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { ResourceReservationCheckoutMutationResponse, ResourceReservationCheckoutPathParams, ResourceReservationCheckout401, ResourceReservationCheckout406, ResourceReservationCheckout409 } from "../models/ResourceReservationCheckout";
import type { UseMutationOptions } from "@tanstack/react-query";

 type ResourceReservationCheckoutClient = typeof client<ResourceReservationCheckoutMutationResponse, ResourceReservationCheckout401 | ResourceReservationCheckout406 | ResourceReservationCheckout409, never>;
type ResourceReservationCheckout = {
    data: ResourceReservationCheckoutMutationResponse;
    error: ResourceReservationCheckout401 | ResourceReservationCheckout406 | ResourceReservationCheckout409;
    request: never;
    pathParams: ResourceReservationCheckoutPathParams;
    queryParams: never;
    headerParams: never;
    response: ResourceReservationCheckoutMutationResponse;
    client: {
        parameters: Partial<Parameters<ResourceReservationCheckoutClient>[0]>;
        return: Awaited<ReturnType<ResourceReservationCheckoutClient>>;
    };
};
/**
 * @summary checkout reservation
 * @link /api/v5/resource-reservations/:id/checkout
 */
export function useResourceReservationCheckout(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<ResourceReservationCheckout["response"], ResourceReservationCheckout["error"], {
        id: ResourceReservationCheckoutPathParams["id"];
        data?: ResourceReservationCheckout["request"];
    }>;
    client?: ResourceReservationCheckout["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({ id }) => {
            const res = await client<ResourceReservationCheckout["data"], ResourceReservationCheckout["error"], ResourceReservationCheckout["request"]>({
                method: "patch",
                url: `/api/v5/resource-reservations/${id}/checkout`,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "resource-reservations"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}