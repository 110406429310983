import { FormInstance } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { AuthUserEntity, useAuthResetPassword } from '@/api'
import {
  KBButton,
  KBFlex,
  KBForm,
  KBInput,
  KBTypography,
} from '@/components/atoms'
const { Title, Text, Link } = KBTypography

import { kbMessage } from '@/components/atoms/KBMessageGlobal'

import { Rule } from 'antd/es/form'
import { useNavigate } from 'react-router-dom'
import WoxIconLogo from '~icons/wox-icons/woxLogo.svg'

interface ResetPasswordProps {
  form: FormInstance
  authUser: AuthUserEntity
}

const ResetPassword: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  // get hash from url query string token
  const searchParams = new URLSearchParams(location.search)
  const hash = searchParams.get('token')
  const spaceId = searchParams.get('space_id')
  const [form] = KBForm.useForm()

  const { mutate: resetPassword, isPending } = useAuthResetPassword()

  const validateConfirmPassword = (rule: Rule, value: string) => {
    if (!value || value === form.getFieldValue('password')) {
      return Promise.resolve()
    }
    return Promise.reject(t('login.passwordNotMatch'))
  }

  const onSubmit = () => {
    form.validateFields().then((values) => {
      // 手动校验密码
      const password = values.password.trim()
      const confirmPassword = values.confirmPassword.trim()

      if (password !== confirmPassword) {
        kbMessage.error(t('login.passwordNotMatch'))
        return
      }
      // 提交表单
      resetPassword(
        {
          data: {
            password: password,
            hash: hash!,
          },
        },
        {
          onSuccess: () => {
            navigate('/reset-password-success')
          },
        }
      )
    })
  }

  return (
    <KBFlex
      vertical
      gap={32}
      justify="center"
      align="center"
      className="tw-mx-auto tw-h-screen tw-w-screen  tw-bg-[var(--wox-color-bg-container)]"
    >
      <KBFlex align="center" gap={24} vertical>
        <WoxIconLogo />
        <Title level={2} className="tw-m-0">
          {t('login.resetPassword')}
        </Title>
      </KBFlex>
      <KBFlex className=" tw-w-[350px]  tw-rounded-lg tw-border tw-border-solid tw-border-[var(--wox-color-border-secondary)]  tw-p-10 tw-shadow">
        <KBForm form={form} layout="vertical" className="tw-w-full">
          <KBForm.Item
            name="password"
            label={t('login.password')}
            rules={[
              {
                required: true,
                message: t('login.passwordDesc'),
              },
            ]}
            className="tw-mb-6"
          >
            <KBInput.Password
              size="large"
              placeholder={t('login.passwordDesc')}
            />
          </KBForm.Item>
          <KBForm.Item
            name="confirmPassword"
            label={t('login.confirmPassword')}
            rules={[
              {
                required: true,
                message: t('login.confirmPasswordDesc'),
              },
              { validator: validateConfirmPassword },
            ]}
          >
            <KBInput.Password
              size="large"
              placeholder={t('login.confirmPasswordDesc')}
            />
          </KBForm.Item>

          <KBForm.Item className="tw-mb-0 tw-mt-3">
            <KBButton
              type="primary"
              size="large"
              onClick={onSubmit}
              className="tw-w-full"
              loading={isPending}
            >
              {t('login.save')}
            </KBButton>
          </KBForm.Item>
        </KBForm>
      </KBFlex>
    </KBFlex>
  )
}

export default ResetPassword
