// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { ResourceReservationAdminCheckinMutationResponse, ResourceReservationAdminCheckinPathParams, ResourceReservationAdminCheckin401, ResourceReservationAdminCheckin406, ResourceReservationAdminCheckin409 } from "../models/ResourceReservationAdminCheckin";
import type { UseMutationOptions } from "@tanstack/react-query";

 type ResourceReservationAdminCheckinClient = typeof client<ResourceReservationAdminCheckinMutationResponse, ResourceReservationAdminCheckin401 | ResourceReservationAdminCheckin406 | ResourceReservationAdminCheckin409, never>;
type ResourceReservationAdminCheckin = {
    data: ResourceReservationAdminCheckinMutationResponse;
    error: ResourceReservationAdminCheckin401 | ResourceReservationAdminCheckin406 | ResourceReservationAdminCheckin409;
    request: never;
    pathParams: ResourceReservationAdminCheckinPathParams;
    queryParams: never;
    headerParams: never;
    response: ResourceReservationAdminCheckinMutationResponse;
    client: {
        parameters: Partial<Parameters<ResourceReservationAdminCheckinClient>[0]>;
        return: Awaited<ReturnType<ResourceReservationAdminCheckinClient>>;
    };
};
/**
 * @summary checkin reservation
 * @link /api/v5/admin/resource-reservations/:id/checkin
 */
export function useResourceReservationAdminCheckin(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<ResourceReservationAdminCheckin["response"], ResourceReservationAdminCheckin["error"], {
        id: ResourceReservationAdminCheckinPathParams["id"];
        data?: ResourceReservationAdminCheckin["request"];
    }>;
    client?: ResourceReservationAdminCheckin["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({ id }) => {
            const res = await client<ResourceReservationAdminCheckin["data"], ResourceReservationAdminCheckin["error"], ResourceReservationAdminCheckin["request"]>({
                method: "patch",
                url: `/api/v5/admin/resource-reservations/${id}/checkin`,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "admin", "resource-reservations"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}