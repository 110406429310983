// @ts-nocheck
import client from "../../utils/axios";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { EmployeeWorkStatusFindManyQueryResponse, EmployeeWorkStatusFindManyQueryParams, EmployeeWorkStatusFindMany401 } from "../models/EmployeeWorkStatusFindMany";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type EmployeeWorkStatusFindManyClient = typeof client<EmployeeWorkStatusFindManyQueryResponse, EmployeeWorkStatusFindMany401, never>;
type EmployeeWorkStatusFindMany = {
    data: EmployeeWorkStatusFindManyQueryResponse;
    error: EmployeeWorkStatusFindMany401;
    request: never;
    pathParams: never;
    queryParams: EmployeeWorkStatusFindManyQueryParams;
    headerParams: never;
    response: EmployeeWorkStatusFindManyQueryResponse;
    client: {
        parameters: Partial<Parameters<EmployeeWorkStatusFindManyClient>[0]>;
        return: Awaited<ReturnType<EmployeeWorkStatusFindManyClient>>;
    };
};
export const employeeWorkStatusFindManyQueryKey = (params?: EmployeeWorkStatusFindMany["queryParams"]) => ["api", "v5", "employee-work-statuses", ...(params ? [params] : [])] as const;
export type EmployeeWorkStatusFindManyQueryKey = ReturnType<typeof employeeWorkStatusFindManyQueryKey>;
export function employeeWorkStatusFindManyQueryOptions(params?: EmployeeWorkStatusFindMany["queryParams"], options: EmployeeWorkStatusFindMany["client"]["parameters"] = {}) {
    const queryKey = employeeWorkStatusFindManyQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<EmployeeWorkStatusFindMany["data"], EmployeeWorkStatusFindMany["error"]>({
                method: "get",
                url: `/api/v5/employee-work-statuses`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 获取EmployeeWorkStatusEntity分页, 传入limit=1000获取所有EmployeeWorkStatusEntity
 * @link /api/v5/employee-work-statuses
 */
export function useEmployeeWorkStatusFindMany<TData = EmployeeWorkStatusFindMany["response"], TQueryData = EmployeeWorkStatusFindMany["response"], TQueryKey extends QueryKey = EmployeeWorkStatusFindManyQueryKey>(params?: EmployeeWorkStatusFindMany["queryParams"], options: {
    query?: Partial<QueryObserverOptions<EmployeeWorkStatusFindMany["response"], EmployeeWorkStatusFindMany["error"], TData, TQueryData, TQueryKey>>;
    client?: EmployeeWorkStatusFindMany["client"]["parameters"];
} = {}): UseQueryResult<TData, EmployeeWorkStatusFindMany["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? employeeWorkStatusFindManyQueryKey(params);
    const query = useQuery({
        ...employeeWorkStatusFindManyQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, EmployeeWorkStatusFindMany["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const employeeWorkStatusFindManySuspenseQueryKey = (params?: EmployeeWorkStatusFindMany["queryParams"]) => ["api", "v5", "employee-work-statuses", ...(params ? [params] : [])] as const;
export type EmployeeWorkStatusFindManySuspenseQueryKey = ReturnType<typeof employeeWorkStatusFindManySuspenseQueryKey>;
export function employeeWorkStatusFindManySuspenseQueryOptions(params?: EmployeeWorkStatusFindMany["queryParams"], options: EmployeeWorkStatusFindMany["client"]["parameters"] = {}) {
    const queryKey = employeeWorkStatusFindManySuspenseQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<EmployeeWorkStatusFindMany["data"], EmployeeWorkStatusFindMany["error"]>({
                method: "get",
                url: `/api/v5/employee-work-statuses`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 获取EmployeeWorkStatusEntity分页, 传入limit=1000获取所有EmployeeWorkStatusEntity
 * @link /api/v5/employee-work-statuses
 */
export function useEmployeeWorkStatusFindManySuspense<TData = EmployeeWorkStatusFindMany["response"], TQueryKey extends QueryKey = EmployeeWorkStatusFindManySuspenseQueryKey>(params?: EmployeeWorkStatusFindMany["queryParams"], options: {
    query?: Partial<UseSuspenseQueryOptions<EmployeeWorkStatusFindMany["response"], EmployeeWorkStatusFindMany["error"], TData, TQueryKey>>;
    client?: EmployeeWorkStatusFindMany["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, EmployeeWorkStatusFindMany["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? employeeWorkStatusFindManySuspenseQueryKey(params);
    const query = useSuspenseQuery({
        ...employeeWorkStatusFindManySuspenseQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, EmployeeWorkStatusFindMany["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}