import { localeAtom } from '@/store'
import { convertLocaleNameToDayjsLocale } from '@/utils/datetime/diffTime'
import { cn } from '@/utils/tailwind.helper'
import dayjs, { Dayjs, formatStorage } from '@wox/dayjs'
import { DatePicker } from 'antd'
import { PickerPropsWithMultiple } from 'antd/es/date-picker/generatePicker/interface'
import { RangePickerProps } from 'antd/lib/date-picker'
import originalDayjs from 'dayjs'
import 'dayjs/locale/es'
import 'dayjs/locale/pt-br'
import 'dayjs/locale/zh-cn'
import 'dayjs/locale/zh-tw'
import { useAtom } from 'jotai'

export interface KBDatePickerProps extends PickerPropsWithMultiple {}
export function KBDatePicker(props: KBDatePickerProps) {
  const { size = 'large', className, value, onChange, ...others } = props
  const dateFormat = formatStorage.get()?.dateFormat || 'YYYY-MM-DD'
  const realValue = Array.isArray(value) || !value ? value : dayjs(value)
  const [locale] = useAtom(localeAtom)

  // console.log(
  //   '>>> dayjs.originalDayjs.locale()',
  //   originalDayjs.locale(),
  //   originalDayjs.localeData().firstDayOfWeek(),
  //   formatStorage.get()?.firstDayOfWeek
  // )
  // 因为dayjs只能通过更新locale来更新firstDayOfWeek，所以这里需要根据locale来更新
  // 目前必须每次render都设置，因为
  const firstDayOfWeek = formatStorage.get()?.firstDayOfWeek || 0
  useEffect(() => {
    const dayjsLocale = convertLocaleNameToDayjsLocale(locale)
    originalDayjs.locale(dayjsLocale)
    originalDayjs.updateLocale(dayjsLocale, {
      weekStart: firstDayOfWeek,
    })
  }, [firstDayOfWeek, locale])

  return (
    <DatePicker
      size={size}
      value={realValue}
      data-testid="date-picker"
      inputReadOnly // Prevent keyboard input
      format={dateFormat}
      getPopupContainer={(trigger) => document.getElementById('root')!}
      {...others}
      className={cn(size === 'large' && 'tw-h-9', 'kb-date-picker', className)}
      onChange={(date, dateString) => {
        let formattedDate = date
        if (date?.$isDayjsObject) {
          // 点击日期面板下的“今天”时，antd会返回一个dayjs对象，这个对象没有继承插件的方法，但直接点击日期就有方法，所以这里用自己的dayjs重新转一下
          formattedDate = dayjs((date as Dayjs).toDate())
        }
        onChange?.(formattedDate, dateString)
      }}
    />
  )
}

KBDatePicker.RangePicker = (props: RangePickerProps) => {
  const { onChange, ...others } = props
  return (
    <DatePicker.RangePicker
      {...others}
      onChange={(dates, dateStrings) => {
        let formattedDates = dates
        if (formattedDates?.length === 2) {
          const start = formattedDates[0]
          const end = formattedDates[1]

          // 跟上面一样，返回的dayjs对象没有继承插件的方法，重置一下
          formattedDates[0] = originalDayjs.isDayjs(start)
            ? dayjs(start.toDate())
            : start
          formattedDates[1] = originalDayjs.isDayjs(end)
            ? dayjs(end.toDate())
            : end
        }
        onChange?.(formattedDates, dateStrings)
      }}
    />
  )
}
