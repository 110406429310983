import { useAuthLogout } from '@/api'
import {
  IconName,
  KBAvatar,
  KBDivider,
  KBFlex,
  KBIcon,
  KBTypography,
} from '@/components/atoms'
import { KBUser } from '@/components/organisms'
import {
  currentSessionTokenAtom,
  currentUserDataAtom,
  hasAdminRoleAtom,
  localeAtom,
  logoutAtom,
} from '@/store'
import { getUserAvatar } from '@/utils/avatarUrl'
import { ADMIN_HELP_URL } from '@/utils/constants'
import { SUPPORTED_LOCALES } from '@/utils/localization/supported_locales'
import { Popover, theme } from 'antd'
import { useAtom, useSetAtom } from 'jotai'
import { useTranslation } from 'react-i18next'

const UserLayoutAvatar: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [locale, setLocale] = useAtom(localeAtom)
  const logout = useSetAtom(logoutAtom)
  const [currentUser] = useAtom(currentUserDataAtom)
  const [hasAdminRole] = useAtom(hasAdminRoleAtom)
  const [currentSessionToken] = useAtom(currentSessionTokenAtom)
  const { mutate: logoutUser } = useAuthLogout()

  const { useToken } = theme
  const { token } = useToken()

  interface ContentItemProps {
    label: string | JSX.Element
    icon: IconName
    itemKey: string
    onItemClick: () => void
  }

  const onChangeLanguage = (key: string) => {
    const selectedLocale = SUPPORTED_LOCALES.find((lang) => lang.value === key)
    setLocale(selectedLocale?.value!)
  }
  const LanguageContent: React.FC = () => {
    const { t } = useTranslation()
    return (
      <>
        {SUPPORTED_LOCALES.map((language, index) => (
          <KBTypography.Text
            key={index}
            onClick={() => onChangeLanguage(language.value)}
            className="tw-block tw-w-[189px] tw-cursor-pointer  tw-rounded tw-px-3 tw-py-[5px] hover:tw-bg-[var(--wox-color-bg-text-hover)]"
          >
            {language.name}
          </KBTypography.Text>
        ))}
      </>
    )
  }
  const items: ContentItemProps[] = [
    {
      label: t('user.settingForm.account'),
      icon: 'circle-user-round',
      itemKey: 'user',
      onItemClick() {
        navigate('/user/users/settings')
      },
    },
    {
      label: t('layout.user.link.helpTitle'),
      icon: 'life-buoy',
      itemKey: 'help',
      onItemClick() {
        window.open(ADMIN_HELP_URL, '_blank')
      },
    },
    // {
    //   label: t('menu.account.logout'),
    //   itemKey: 'logout',
    //   icon: 'log-out',
    //   onItemClick() {
    //     currentSessionToken &&
    //       logoutUser(
    //         {
    //           data: {
    //             sessionToken: currentSessionToken,
    //           },
    //         },
    //         {
    //           onSettled: () => {
    //             logout()
    //             window.location.assign('/login')
    //           },
    //         }
    //       )
    //   },
    // },
  ]

  const ContentItem: React.FC<ContentItemProps> = (props) => {
    const { t } = useTranslation()
    const { label, icon, itemKey: itemKey, onItemClick } = props

    return (
      <>
        {itemKey === 'logout' && <KBDivider className="tw-my-1" />}
        <KBFlex
          className="tw-cursor-pointer tw-rounded tw-px-3 tw-py-[5px] hover:tw-bg-[var(--wox-color-bg-text-hover)]"
          gap={8}
          align="center"
          onClick={() => onItemClick()}
        >
          <KBIcon name={icon} />
          <KBTypography.Text>{label}</KBTypography.Text>
        </KBFlex>
      </>
    )
  }
  return (
    <Popover
      placement="rightTop"
      arrow={false}
      trigger={'click'}
      content={
        <KBFlex
          vertical
          className="tw-shadow-[0px_2px_6px_-1px_rgba(0, 0, 0, 0.08)] tw-box-border tw-w-[222px] tw-py-1"
        >
          <div className="tw-px-4 tw-py-[5px]">
            <KBUser
              user={currentUser}
              size={32}
              showEmail={true}
              showTooltip={false}
            />
          </div>
          <KBDivider className="tw-my-1" />

          {items.map((item, index) => (
            <ContentItem {...item} key={index} />
          ))}
          {hasAdminRole && (
            <ContentItem
              label={t('menu.changeAdminSwitch')}
              icon="external-link"
              itemKey={'toAdmin'}
              onItemClick={() => {
                navigate('/admin/dashboard')
              }}
            />
          )}

          <KBTypography.Text className="tw-block tw-px-3 tw-py-1 tw-text-[11px] tw-leading-4 tw-text-[var(--wox-color-text-quaternary)]">
            {t('common.versionNumber', { version: '5.0.0' })}
          </KBTypography.Text>
          <ContentItem
            label={t('menu.account.logout')}
            icon="log-out"
            itemKey={'logout'}
            onItemClick={() => {
              currentSessionToken &&
                logoutUser(
                  {
                    data: {
                      sessionToken: currentSessionToken,
                    },
                  },
                  {
                    onSettled: () => {
                      logout()
                      window.location.assign('/login')
                    },
                  }
                )
            }}
          />
        </KBFlex>
      }
      align={{
        offset: [20, 10],
      }}
      overlayInnerStyle={{
        borderRadius: token.borderRadius,
        border: `1px solid ${token.colorBorderSecondary}`,
        backgroundColor: token.colorBgElevated,
        boxShadow: '0px 2px 6px -1px rgba(0, 0, 0, 0.08)',
        padding: '0px',
      }}
    >
      <div
      // onClick={(e) => {
      //   navigate('/user/users/settings')
      // }}
      >
        <KBAvatar
          size={32}
          src={getUserAvatar(currentUser)}
          className="tw-border-px tw-border-white"
        />
      </div>
    </Popover>
  )
}

export default UserLayoutAvatar
