// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { VisitorAdminCancelMutationRequest, VisitorAdminCancelMutationResponse, VisitorAdminCancelPathParams, VisitorAdminCancel401, VisitorAdminCancel406, VisitorAdminCancel409 } from "../models/VisitorAdminCancel";
import type { UseMutationOptions } from "@tanstack/react-query";

 type VisitorAdminCancelClient = typeof client<VisitorAdminCancelMutationResponse, VisitorAdminCancel401 | VisitorAdminCancel406 | VisitorAdminCancel409, VisitorAdminCancelMutationRequest>;
type VisitorAdminCancel = {
    data: VisitorAdminCancelMutationResponse;
    error: VisitorAdminCancel401 | VisitorAdminCancel406 | VisitorAdminCancel409;
    request: VisitorAdminCancelMutationRequest;
    pathParams: VisitorAdminCancelPathParams;
    queryParams: never;
    headerParams: never;
    response: VisitorAdminCancelMutationResponse;
    client: {
        parameters: Partial<Parameters<VisitorAdminCancelClient>[0]>;
        return: Awaited<ReturnType<VisitorAdminCancelClient>>;
    };
};
/**
 * @summary 访客取消
 * @link /api/v5/admin/visitors/:id/cancel
 */
export function useVisitorAdminCancel(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<VisitorAdminCancel["response"], VisitorAdminCancel["error"], {
        id: VisitorAdminCancelPathParams["id"];
        data?: VisitorAdminCancel["request"];
    }>;
    client?: VisitorAdminCancel["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({ id, data }) => {
            const res = await client<VisitorAdminCancel["data"], VisitorAdminCancel["error"], VisitorAdminCancel["request"]>({
                method: "post",
                url: `/api/v5/admin/visitors/${id}/cancel`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "admin", "visitors"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}