import { OnlineMeetingInfoMeetingProvider, ResourceEntity } from '@/api'
import {
  KBFlex,
  KBForm,
  KBIcon,
  KBInput,
  KBSwitch,
  KBText,
  KBTooltip,
} from '@/components/atoms'
import { KBResourceReservationFormProps } from '@/components/molecules/KBResourceReservationForm/KBResourceReservationForm'
import { useTranslation } from 'react-i18next'

/**
 * 云会议选择
 */
export interface CloudVideoValueProps {
  joinUrl?: string
  meetingProvider?: OnlineMeetingInfoMeetingProvider
  thirdPartyMeeting?: boolean
}
interface CloudVideoSelectProps {
  value?: CloudVideoValueProps
  resourcesList: KBResourceReservationFormProps['resourcesList']
  errorsMessages?: string
}

/**
 * 云会议选择组件
 */
const CloudVideoSelect: React.FC<CloudVideoSelectProps> = (props) => {
  const { t } = useTranslation()
  const { resourcesList, errorsMessages } = props

  const [isShowMeetLink, setIsShowMeetLink] = useState(
    props.value?.thirdPartyMeeting
  )

  const showCloudVideo = useMemo(() => {
    return (resourcesList || []).some((i) => {
      const rules = i.reservation_reserve_rule?.rules
      return rules?.connection_by_self
    })
  }, [resourcesList])

  const isExternal = useMemo(() => {
    return (resourcesList || []).some((i) => {
      const external_calendar_id = i.external_calendar_id

      return !!external_calendar_id
    })
  }, [resourcesList])

  const externalCalendar = useMemo(() => {
    return (
      (resourcesList || []).find(
        (item) => item.external_calendar_id
      ) as ResourceEntity
    )?.external_calendar
  }, [resourcesList])

  return showCloudVideo ? (
    !isExternal ? (
      <KBForm.Item
        name={'cloudVideoProviders.joinUrl'.split('.')}
        label={
          <KBTooltip title={t('common.cloudMeeting')}>
            <KBIcon name="video" size={24} />
          </KBTooltip>
        }
        dependencies={['resourceIds']}
        validateStatus={Boolean(errorsMessages?.length) ? 'error' : undefined}
        help={errorsMessages}
      >
        <KBInput placeholder={t('cloudMeeting.customLink')} />
      </KBForm.Item>
    ) : (
      <KBFlex align="center" gap={12} className="tw-mb-6">
        <KBForm.Item
          name={'cloudVideoProviders.thirdPartyMeeting'.split('.')}
          label={
            <KBTooltip title={t('common.cloudMeeting')}>
              <KBIcon name="video" size={24} />
            </KBTooltip>
          }
          className="tw-m-0"
        >
          <KBSwitch
            checked={isShowMeetLink}
            onChange={(checked) => setIsShowMeetLink(checked)}
          />
        </KBForm.Item>
        <KBFlex gap={8} align="center">
          <img
            width={20}
            height={20}
            src={
              externalCalendar?.calendar_type === 'Outlook'
                ? '/reservation/outlook.svg'
                : '/reservation/teams.svg'
            }
          />
          <KBText>
            {externalCalendar && externalCalendar.calendar_type === 'Outlook'
              ? t('cloudMeeting.outlookLink')
              : t('cloudMeeting.googleLink')}
          </KBText>
        </KBFlex>
      </KBFlex>
    )
  ) : null
}

export default CloudVideoSelect
