// @ts-nocheck
import client from "../../utils/axios";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { VisitorAdminFindManyWithSecurityTokenQueryResponse, VisitorAdminFindManyWithSecurityTokenQueryParams, VisitorAdminFindManyWithSecurityToken401 } from "../models/VisitorAdminFindManyWithSecurityToken";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type VisitorAdminFindManyWithSecurityTokenClient = typeof client<VisitorAdminFindManyWithSecurityTokenQueryResponse, VisitorAdminFindManyWithSecurityToken401, never>;
type VisitorAdminFindManyWithSecurityToken = {
    data: VisitorAdminFindManyWithSecurityTokenQueryResponse;
    error: VisitorAdminFindManyWithSecurityToken401;
    request: never;
    pathParams: never;
    queryParams: VisitorAdminFindManyWithSecurityTokenQueryParams;
    headerParams: never;
    response: VisitorAdminFindManyWithSecurityTokenQueryResponse;
    client: {
        parameters: Partial<Parameters<VisitorAdminFindManyWithSecurityTokenClient>[0]>;
        return: Awaited<ReturnType<VisitorAdminFindManyWithSecurityTokenClient>>;
    };
};
export const visitorAdminFindManyWithSecurityTokenQueryKey = (params?: VisitorAdminFindManyWithSecurityToken["queryParams"]) => ["api", "v5", "admin", "visitors", "with-security-token", ...(params ? [params] : [])] as const;
export type VisitorAdminFindManyWithSecurityTokenQueryKey = ReturnType<typeof visitorAdminFindManyWithSecurityTokenQueryKey>;
export function visitorAdminFindManyWithSecurityTokenQueryOptions(params?: VisitorAdminFindManyWithSecurityToken["queryParams"], options: VisitorAdminFindManyWithSecurityToken["client"]["parameters"] = {}) {
    const queryKey = visitorAdminFindManyWithSecurityTokenQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<VisitorAdminFindManyWithSecurityToken["data"], VisitorAdminFindManyWithSecurityToken["error"]>({
                method: "get",
                url: `/api/v5/admin/visitors/with-security-token`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 保安查看，获取VisitorEntity分页, 传入limit=1000获取所有VisitorEntity
 * @link /api/v5/admin/visitors/with-security-token
 */
export function useVisitorAdminFindManyWithSecurityToken<TData = VisitorAdminFindManyWithSecurityToken["response"], TQueryData = VisitorAdminFindManyWithSecurityToken["response"], TQueryKey extends QueryKey = VisitorAdminFindManyWithSecurityTokenQueryKey>(params?: VisitorAdminFindManyWithSecurityToken["queryParams"], options: {
    query?: Partial<QueryObserverOptions<VisitorAdminFindManyWithSecurityToken["response"], VisitorAdminFindManyWithSecurityToken["error"], TData, TQueryData, TQueryKey>>;
    client?: VisitorAdminFindManyWithSecurityToken["client"]["parameters"];
} = {}): UseQueryResult<TData, VisitorAdminFindManyWithSecurityToken["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? visitorAdminFindManyWithSecurityTokenQueryKey(params);
    const query = useQuery({
        ...visitorAdminFindManyWithSecurityTokenQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, VisitorAdminFindManyWithSecurityToken["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const visitorAdminFindManyWithSecurityTokenSuspenseQueryKey = (params?: VisitorAdminFindManyWithSecurityToken["queryParams"]) => ["api", "v5", "admin", "visitors", "with-security-token", ...(params ? [params] : [])] as const;
export type VisitorAdminFindManyWithSecurityTokenSuspenseQueryKey = ReturnType<typeof visitorAdminFindManyWithSecurityTokenSuspenseQueryKey>;
export function visitorAdminFindManyWithSecurityTokenSuspenseQueryOptions(params?: VisitorAdminFindManyWithSecurityToken["queryParams"], options: VisitorAdminFindManyWithSecurityToken["client"]["parameters"] = {}) {
    const queryKey = visitorAdminFindManyWithSecurityTokenSuspenseQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<VisitorAdminFindManyWithSecurityToken["data"], VisitorAdminFindManyWithSecurityToken["error"]>({
                method: "get",
                url: `/api/v5/admin/visitors/with-security-token`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 保安查看，获取VisitorEntity分页, 传入limit=1000获取所有VisitorEntity
 * @link /api/v5/admin/visitors/with-security-token
 */
export function useVisitorAdminFindManyWithSecurityTokenSuspense<TData = VisitorAdminFindManyWithSecurityToken["response"], TQueryKey extends QueryKey = VisitorAdminFindManyWithSecurityTokenSuspenseQueryKey>(params?: VisitorAdminFindManyWithSecurityToken["queryParams"], options: {
    query?: Partial<UseSuspenseQueryOptions<VisitorAdminFindManyWithSecurityToken["response"], VisitorAdminFindManyWithSecurityToken["error"], TData, TQueryKey>>;
    client?: VisitorAdminFindManyWithSecurityToken["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, VisitorAdminFindManyWithSecurityToken["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? visitorAdminFindManyWithSecurityTokenSuspenseQueryKey(params);
    const query = useSuspenseQuery({
        ...visitorAdminFindManyWithSecurityTokenSuspenseQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, VisitorAdminFindManyWithSecurityToken["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}