import React, { ChangeEventHandler } from 'react'
import { useTranslation } from 'react-i18next'

import { ReservationShowEntity } from '@/api'
import { KBForm, KBIcon, KBInput, KBTooltip } from '@/components/atoms'
import { KBResourceReservationFormProps } from '@/components/molecules/KBResourceReservationForm/KBResourceReservationForm'

/**
 * 备注
 */
interface NoteInputProps {
  value?: string
  resourcesList: KBResourceReservationFormProps['resourcesList']
  reservationInfo?: ReservationShowEntity
  errorsMessages?: string
  onChange?: ChangeEventHandler<HTMLTextAreaElement>
}
const NoteInput: React.FC<NoteInputProps> = (props) => {
  const { t } = useTranslation()
  const { resourcesList, reservationInfo, errorsMessages } = props

  const showNote = useMemo(() => {
    return (resourcesList || []).some((i) => {
      const rules = i.reservation_reserve_rule?.rules
      return rules?.notes
    })
  }, [resourcesList])

  return showNote ? (
    <KBForm.Item
      name="notes"
      label={
        <KBTooltip title={t('note')}>
          <KBIcon name="text" size={24} />
        </KBTooltip>
      }
      dependencies={['resourceIds']}
      validateStatus={Boolean(errorsMessages?.length) ? 'error' : undefined}
      help={errorsMessages}
    >
      <KBInput.TextArea placeholder={t('common.remarksPlaceholder')} rows={4} />
    </KBForm.Item>
  ) : null
}

export default NoteInput
