import NiceModal from '@ebay/nice-modal-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  ReservationShowEntity,
  ResourceEntity,
  ResourceServiceTypeEntity,
  SimpleServiceTypeEntity,
  TReservationServiceRule,
  useResourceAdminFindServiceTypes,
  useResourceFindServiceTypes,
} from '@/api'
import {
  KBButton,
  KBCheckbox,
  KBFlex,
  KBForm,
  KBIcon,
  KBSkeleton,
  KBSpace,
  KBTag,
  KBText,
  KBTooltip,
} from '@/components/atoms'
import { kbModal } from '@/components/atoms/KBMessageGlobal'
import AddServiceModal, {
  TotalInfo,
} from '@/components/molecules/KBResourceReservationForm/components/AddServiceModal'
import AddServiceNoteModal from '@/components/molecules/KBResourceReservationForm/components/AddServiceNoteModal'
import { KBResourceReservationFormProps } from '@/components/molecules/KBResourceReservationForm/KBResourceReservationForm'
import KBServiceTimeIcon from '@/components/organisms/KBServiceReservation/KBServiceTimeIcon'
import { cn } from '@/utils/tailwind.helper'
import { isUserClient } from '@/utils/userEnvironment'

export interface ServiceValueItem {
  notes: string
  resourceId: number
  resourceName?: string
  serviceItems: {
    serviceItemId: number
    quantity: number
    total?: number
  }[]
  serviceTypeId: number
  name: string
  service_time: string
  required_minutes?: number
  has_service_items: boolean
  required: boolean
  disabled: boolean
  selected: boolean
}

interface ServiceSelectProps {
  id?: string
  reservationInfo?: ReservationShowEntity
  resourcesList: KBResourceReservationFormProps['resourcesList']
  value?: ServiceValueItem[]
  errorsMessages?: string
  onChange?: (value: ServiceValueItem[]) => void
  onServiceChange?: (value: boolean) => void
}

/**
 * Form.Item包裹的组件
 */
const Services: React.FC<ServiceSelectProps> = (props) => {
  const { t } = useTranslation()
  const {
    value = [],
    onChange,
    resourcesList,
    onServiceChange,
    reservationInfo,
    id,
  } = props
  const [dataList, setDataList] = useState<ServiceValueItem[]>([])

  const {
    data: resourceFilters,
    refetch,
    isPending,
  } = (
    isUserClient()
      ? useResourceFindServiceTypes
      : useResourceAdminFindServiceTypes
  )(
    {
      ids: resourcesList.map((i) => i.id),
    },
    {
      query: {
        enabled: false,
      },
    }
  )

  function onServiceConfirm(totalInfoItem: TotalInfo) {
    const data = dataList.find(
      (i) =>
        i.resourceId === totalInfoItem.resourceId &&
        i.serviceTypeId === totalInfoItem.serviceTypeId
    )
    if (data) {
      data.serviceItems = totalInfoItem.serviceItems.map((i) => {
        return {
          ...i,
        }
      })
    }

    setDataList([...dataList])
  }

  function onServiceNoteConfirm(
    value: string,
    resourceId: number,
    serviceTypeId: number
  ) {
    const data = dataList.find(
      (i) => i.resourceId === resourceId && i.serviceTypeId === serviceTypeId
    )

    if (data) {
      data.notes = value
    }

    setDataList([...dataList])
  }

  const getServiceIsRequired = (
    serviceItemId: SimpleServiceTypeEntity['id'],
    resourceId: ResourceEntity['id']
  ) => {
    const resource = resourcesList.find((i) => i.id === resourceId)
    if (resource) {
      return (
        resource?.reservation_service_rule?.rules as TReservationServiceRule
      )?.service_types?.some(
        (i) => i.service_type_id === serviceItemId && i.required
      )
    }
    return false
  }

  const getDisabledByStatus = (serviceTypeId: number, resourceId: number) => {
    if (reservationInfo?.service_reservations?.length) {
      return reservationInfo?.service_reservations?.some(
        (i) =>
          i.service_type_id === serviceTypeId &&
          i.resource_id === resourceId &&
          i.task?.state &&
          ['Completed', 'Rejected', 'Expired', 'Pending'].includes(
            i.task?.state
          )
      )
    }

    return false
  }

  const onSelectedChange = (
    val: boolean,
    serviceTypeId: number,
    resourceId: number
  ) => {
    const data = dataList.find(
      (i) => i.resourceId === resourceId && i.serviceTypeId === serviceTypeId
    )

    if (data) {
      if (!val && data.serviceItems.length > 0) {
        kbModal.confirm({
          title: t('common.prompt'),
          content: t('reservation.clearShoppingCart'),
          onOk() {
            data.selected = val
            data.serviceItems = []
            setDataList([...dataList])
          },
        })
      } else {
        data.selected = val
        setDataList([...dataList])
      }
    }
  }

  const getTotalItemNumber = (serviceValueItem: ServiceValueItem) => {
    return serviceValueItem.serviceItems.reduce((a, b) => a + b.quantity, 0)
  }

  useEffect(() => {
    const arr =
      resourceFilters
        ?.flatMap(({ service_types, id }: ResourceServiceTypeEntity) => {
          const result = service_types?.map((simpleServiceType) => {
            const selectedItem = value.find(
              (i) => i.serviceTypeId === simpleServiceType.id
            )

            return {
              ...simpleServiceType,
              resourceName: resourcesList.find((i) => i.id === id)?.name,
              resourceId: id,
              notes: selectedItem?.notes || '',
              serviceTypeId: simpleServiceType.id,
              serviceItems: selectedItem?.serviceItems || [],
              selected: selectedItem
                ? true
                : getServiceIsRequired(simpleServiceType.id, id),
              required: getServiceIsRequired(simpleServiceType.id, id),
              disabled: getDisabledByStatus(simpleServiceType.id, id),
            }
          })
          return result!
        })
        .filter(Boolean) || []

    setDataList(arr)
  }, [resourceFilters])

  useEffect(() => {
    // 只传递已勾选的服务项
    onChange?.(dataList.filter((i) => i.selected))
    onServiceChange?.(dataList.length > 0)
  }, [dataList])

  useEffect(() => {
    if (resourcesList.length > 0) {
      refetch()
    }
  }, [resourcesList])

  return (
    <KBSkeleton
      loading={isPending}
      title={false}
      paragraph={{ rows: 3, width: '100%' }}
    >
      {dataList.length > 0 ? (
        <>
          <KBFlex id={id} align="start" gap={6}>
            <KBFlex vertical gap={4} className="tw-flex-1">
              {dataList.map((serviceItem) => {
                return (
                  <KBFlex
                    key={`${serviceItem.resourceId}-${serviceItem.serviceTypeId}}`}
                    align="center"
                    justify="space-between"
                    className="tw-h-9"
                  >
                    <KBSpace>
                      <KBTooltip
                        title={
                          serviceItem.required
                            ? t('reservation.requiredService')
                            : ''
                        }
                      >
                        <div className="tw-relative">
                          <KBCheckbox
                            disabled={
                              serviceItem.required || serviceItem.disabled
                            }
                            checked={serviceItem.selected}
                            onChange={(e) =>
                              onSelectedChange(
                                e.target.checked,
                                serviceItem.serviceTypeId,
                                serviceItem.resourceId
                              )
                            }
                          ></KBCheckbox>
                        </div>
                      </KBTooltip>
                      <KBText>{serviceItem.name}</KBText>
                      <KBTag size="small" bordered={false} className="tw-h-5">
                        {serviceItem.resourceName}
                      </KBTag>
                    </KBSpace>
                    <KBSpace size={12}>
                      <KBServiceTimeIcon serviceType={serviceItem} />

                      {serviceItem.has_service_items && (
                        <KBButton
                          type="link"
                          size="small"
                          disabled={serviceItem.disabled}
                          className="tw-p-0"
                          onClick={() =>
                            NiceModal.show('addServiceModal', {
                              serviceTypeId: serviceItem.serviceTypeId,
                              resourceId: serviceItem.resourceId,
                              value: serviceItem.serviceItems,
                              onConfirm: onServiceConfirm,
                            })
                          }
                        >
                          <KBFlex gap={4} align="center">
                            <KBIcon name="shopping-cart" size={18} />
                            {getTotalItemNumber(serviceItem) > 0
                              ? getTotalItemNumber(serviceItem)
                              : t('common.addServiceInReservationForm')}
                          </KBFlex>
                        </KBButton>
                      )}

                      <KBButton
                        size="small"
                        type="link"
                        className={cn(
                          serviceItem.notes ? 'tw-text-gray-800' : '',
                          ' tw-p-0'
                        )}
                        onClick={() =>
                          NiceModal.show('addServiceNoteModal', {
                            serviceTypeId: serviceItem.serviceTypeId,
                            resourceId: serviceItem.resourceId,
                            value: serviceItem.notes,
                            onConfirm: onServiceNoteConfirm,
                          })
                        }
                      >
                        <KBFlex gap={4} align="center">
                          <KBIcon name="file-text" size={18} />
                          {t('note')}
                        </KBFlex>
                      </KBButton>
                    </KBSpace>
                  </KBFlex>
                )
              })}
            </KBFlex>
          </KBFlex>
          <AddServiceModal id="addServiceModal" />
          <AddServiceNoteModal id="addServiceNoteModal" />
        </>
      ) : null}
    </KBSkeleton>
  )
}

/**
 * 选择服务组件
 */
const ServiceSelect: React.FC<
  Pick<
    ServiceSelectProps,
    'reservationInfo' | 'resourcesList' | 'errorsMessages'
  >
> = (props) => {
  const { t } = useTranslation()
  const { reservationInfo, resourcesList, errorsMessages } = props
  const [showService, setShowService] = useState(false)

  const onServiceChange = (hasLength: boolean) => {
    setShowService(hasLength)
  }

  const validateService = (services?: ServiceValueItem[]) => {
    const hasThresholdResources = resourcesList.filter(
      (i) =>
        (i.reservation_service_rule?.rules as TReservationServiceRule)
          ?.service_threshold
    )

    const overSizeResources = hasThresholdResources.filter((i) => {
      const currentResourceServices = (services || []).filter(
        (j) => i.id === j.resourceId
      )
      const total = currentResourceServices.reduce((prev, curr) => {
        return (
          prev +
          curr.serviceItems.reduce((prev, curr) => {
            return prev + Number(curr.total)
          }, 0)
        )
      }, 0)
      const max = (i.reservation_service_rule?.rules as TReservationServiceRule)
        ?.service_threshold
      return max !== undefined && total > max
    })

    if (overSizeResources?.length) {
      return Promise.reject(
        new Error(
          `${overSizeResources.map((i) => i.name).join('、')}${t('reservation.exceedTips')}`
        )
      )
    }
    return Promise.resolve()
  }

  const canEditService = useMemo(() => {
    return !reservationInfo
  }, [reservationInfo])

  return (
    <KBForm.Item
      name="services"
      dependencies={['resourceIds']}
      validateStatus={Boolean(errorsMessages?.length) ? 'error' : undefined}
      help={errorsMessages}
      style={{ display: showService && canEditService ? '' : 'none' }}
      label={
        <KBTooltip title={t('common.service')}>
          <KBIcon name="coffee" size={24} />
        </KBTooltip>
      }
    >
      <Services
        resourcesList={resourcesList}
        reservationInfo={reservationInfo}
        onServiceChange={onServiceChange}
      />
    </KBForm.Item>
  )
}

export default ServiceSelect
