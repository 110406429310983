// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { AuthLogoutMutationRequest, AuthLogoutMutationResponse, AuthLogout401 } from "../models/AuthLogout";
import type { UseMutationOptions } from "@tanstack/react-query";

 type AuthLogoutClient = typeof client<AuthLogoutMutationResponse, AuthLogout401, AuthLogoutMutationRequest>;
type AuthLogout = {
    data: AuthLogoutMutationResponse;
    error: AuthLogout401;
    request: AuthLogoutMutationRequest;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: AuthLogoutMutationResponse;
    client: {
        parameters: Partial<Parameters<AuthLogoutClient>[0]>;
        return: Awaited<ReturnType<AuthLogoutClient>>;
    };
};
/**
 * @summary 登出当前用户
 * @link /api/v5/auth/logout
 */
export function useAuthLogout(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<AuthLogout["response"], AuthLogout["error"], {
        data?: AuthLogout["request"];
    }>;
    client?: AuthLogout["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({ data }) => {
            const res = await client<AuthLogout["data"], AuthLogout["error"], AuthLogout["request"]>({
                method: "post",
                url: `/api/v5/auth/logout`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "auth"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}