import React from 'react'
import { useTranslation } from 'react-i18next'

import { KBButton, KBFlex, KBText, KBTypography } from '@/components/atoms'
const { Title, Text, Link } = KBTypography

import WoxIconLogo from '~icons/wox-icons/woxLogo.svg'

const ResetPasswordSuccess: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <KBFlex
      vertical
      gap={32}
      justify="center"
      align="center"
      className="tw-mx-auto  tw-h-screen tw-w-screen tw-bg-[var(--wox-color-bg-container)]"
    >
      <KBFlex align="center" gap={24} vertical>
        <WoxIconLogo />
      </KBFlex>
      <KBFlex
        vertical
        gap={32}
        justify="center"
        align="center"
        className="tw-h-[460px] tw-w-[928px] tw-rounded-lg tw-border tw-border-solid tw-border-[var(--wox-color-border-secondary)]  tw-p-12 tw-shadow"
      >
        <KBFlex vertical align="center" gap={8}>
          <Title level={2} className="tw-m-0">
            {t('login.resetPasswordSuccessTitle')}
          </Title>
          <KBText>{t('login.resetPasswordSuccessDescription')}</KBText>
        </KBFlex>
        <KBButton type="primary" onClick={() => navigate('/login')}>
          {t('login.toLogin')}
        </KBButton>
      </KBFlex>
    </KBFlex>
  )
}

export default ResetPasswordSuccess
