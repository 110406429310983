import { Dayjs } from '@wox/dayjs'
import { t } from 'i18next'
/**
 * 计算两个时间之间的差值, 返回格式化后的字符串, 如: 1小时30分钟
 * @param from 开始时间
 * @param to 结束时间
 * @returns 差值
 */
export function diffTime(from: Dayjs, to: Dayjs) {
  const totalMinutes = Math.abs(to.diff(from, 'minute'))
  const hour = Math.floor(totalMinutes / 60)
  const minute = totalMinutes % 60

  if (hour > 0 && minute > 0) {
    return `${t('common.hourMinutes', { hour: hour, minute: minute })}`
  } else if (hour > 0 && minute === 0) {
    return `${t('common.hours', { hours: hour })}`
  } else {
    return `${t('common.minutes', { minutes: minute })}`
  }
}

export const convertLocaleNameToDayjsLocale = (localeName: string) => {
  const dayjsLocaleName = localeName ? localeName.toLowerCase() : 'en-us'
  // 目前只区分中、葡，其他都返回第一层
  if (dayjsLocaleName.startsWith('zh') || dayjsLocaleName.startsWith('pt')) {
    return dayjsLocaleName
  }
  return dayjsLocaleName.slice(0, 2)
}
