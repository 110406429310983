import { WoxTheme } from '@/themes/antd/lightTheme'
import { theme } from 'antd'

export const darkTheme: WoxTheme = {
  token: {
    colorTextSecondary: '#d4d4d8',
    colorTextTertiary: '#71717a',
    colorTextQuaternary: '#52525b',
    colorBorder: '#3f3f46',
    colorBorderSecondary: '#27272a',
    colorFill: '#3f3f46',
    colorFillSecondary: '#27272a',
    colorFillTertiary: '#18181b',
    colorFillQuaternary: '#18181b',
    colorBgTextHover: '#27272A',
    controlHeightLG: 36,
    lineWidthFocus: 2,
    lineHeightHeading1: 1.111111111111111,
    fontSizeHeading5: 18,
    fontSizeHeading1: 36,
    lineHeightHeading2: 1.2,
    lineHeightHeading5: 1.5555555555555556,
    lineHeight: 1.498571428571429,
    fontFamily:
      "'Geist Sans', Sans,sans-serif,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,apple color emoji,segoe ui emoji,Segoe UI Symbol,noto color emoji",
    fontFamilyCode:
      "'Geist Sans', Sans,sans-serif,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,apple color emoji,segoe ui emoji,Segoe UI Symbol,noto color emoji",
    boxShadowSecondary: '0px 2px 6px -1px rgba(0, 0, 0, 0.08)',
    colorPrimary: '#20929a',
    colorInfo: '#20929a',
    colorSuccess: '#10b981',
    colorWarning: '#f97316',
    colorText: '#f4f4f5',
    colorBgContainer: '#09090b',
    colorBgElevated: '#09090b',
    colorBgLayout: '#09090b',
    colorBgSpotlight: '#3f3f46',
    colorLink: 'rgb(32,146,154)',
    colorFillQuinary: '#121216',
    colorAdminMenuLayout: '#18181B',
    colorAlertInfo: 'rgb(37,99,235)',
    colorAlertError: 'rgb(239,68,68)',
    colorAlertSuccess: 'rgb(5,150,105)',
    colorAlertWarning: 'rgb(234,88,12)',
    colorAlertInfoBg: 'rgb(23,37,84)',
    colorAlertErrorBg: 'rgb(69,10,10)',
    colorAlertWarningBg: 'rgb(66,32,6)',
    colorAlertSuccessBg: 'rgb(2,44,34)',
    colorStateIconYellow: 'rgba(245, 158, 11, 1)',
    colorStateIconBlue: 'rgba(37, 99, 235, 1)',
    colorStateIconGray: 'rgba(82, 82, 91, 1)',
    colorStateIconEmerald: 'rgba(5, 150, 105, 1)',
  },
  components: {
    Button: {
      paddingInline: 12,
      paddingInlineLG: 16,
      paddingInlineSM: 8,
      dangerShadow: '0 0 0 rgba(255, 38, 5, 0.06)',
      defaultShadow: '0 0 0 rgba(0, 0, 0, 0.02)',
      primaryShadow: '0 0 0 rgba(5, 145, 255, 0.1)',
      fontWeight: 500,
      onlyIconSizeSM: 16,
      onlyIconSize: 18,
      onlyIconSizeLG: 20,
      contentFontSizeLG: 14,
      textHoverBg: 'rgb(39,39,42)',
    },
    Dropdown: {
      paddingBlock: 7.5,
      controlItemBgActive: 'rgb(63,63,70)',
      controlItemBgActiveHover: 'rgb(63,63,70)',
    },
    Select: {
      fontSizeLG: 14,
      multipleItemBg: 'rgb(39,39,42)',
      optionSelectedBg: 'rgb(39,39,42)',
      optionPadding: '6px 12px',
    },
    Table: {
      cellPaddingInline: 8,
      headerBorderRadius: 6,
      rowHoverBg: 'rgb(18,18,22)',
      rowSelectedBg: 'rgb(39,39,42)',
      rowSelectedHoverBg: 'rgb(39,39,42)',
    },
    DatePicker: {
      cellHeight: 32,
      cellWidth: 36,
      withoutTimeCellHeight: 50,
      fontSizeLG: 14,
      inputFontSizeLG: 14,
    },
    Badge: {
      statusSize: 7,
    },
    Segmented: {
      trackPadding: 3,
      trackBg: 'rgb(24,24,27)',
      fontSizeLG: 14,
    },
    Tooltip: {
      fontSize: 13,
      colorBgSpotlight: 'rgb(48,48,51)',
    },
    Alert: {
      lineWidth: 0,
      defaultPadding: '10px 12px',
      withDescriptionPadding: '12px 12px',
      withDescriptionIconSize: 20,
      fontSizeLG: 14,
      colorInfo: 'rgb(37,99,235)',
      colorError: 'rgb(239,68,68)',
      colorSuccess: 'rgb(5,150,105)',
      colorWarning: 'rgb(234,88,12)',
      colorInfoBg: 'rgb(23,37,84)',
      colorErrorBg: 'rgb(69,10,10)',
      colorWarningBg: 'rgb(66,32,6)',
      colorSuccessBg: 'rgb(2,44,34)',
    },
    Message: {
      colorInfo: 'rgb(37,99,235)',
      contentPadding: '10px 12px',
      colorError: 'rgb(239,68,68)',
      colorSuccess: 'rgb(5,150,105)',
      colorWarning: 'rgb(234,88,12)',
      contentBg: 'rgb(63,63,70)',
    },
    Menu: {
      itemBorderRadius: 6,
      itemHeight: 36,
      iconMarginInlineEnd: 8,
      iconSize: 16,
      itemMarginInline: 12,
      collapsedWidth: 64,
      itemMarginBlock: 4,
      itemSelectedBg: 'rgb(39,39,42)',
      itemSelectedColor: 'rgb(250,250,250)',
      itemHoverBg: 'rgb(24,24,27)',
      subMenuItemBg: 'rgba(0,0,0,0)',
      itemColor: 'rgb(244,244,245)',
      itemActiveBg: 'rgb(39,39,42)',
      dangerItemSelectedBg: 'rgb(69,10,10)',
    },
    Cascader: {
      optionPadding: '7px 12px',
      fontSizeLG: 14,
    },
    Transfer: {
      itemHeight: 36,
    },
    Drawer: {
      colorBgMask: 'rgba(3, 7, 18, 0.45)',
    },
    Modal: {
      colorBgMask: 'rgba(0,0,0,0.45)',
      contentBg: 'rgb(32,32,35)',
      headerBg: 'rgba(255,255,255,0)',
    },
    Pagination: {
      itemSize: 36,
      colorBgTextHover: 'rgb(24,24,27)',
      itemActiveBgDisabled: 'rgb(39,39,42)',
    },
    Form: {
      fontSizeLG: 14,
    },
    Input: {
      inputFontSizeLG: 14,
    },
    InputNumber: {
      inputFontSizeLG: 14,
      fontSizeLG: 14,
    },
    Mentions: {
      inputFontSizeLG: 14,
    },
    Notification: {
      colorInfo: 'rgb(37, 99, 235)',
      colorBgElevated: 'rgb(39,39,42)',
    },
    Tree: {
      titleHeight: 20,
      controlInteractiveSize: 16,
      nodeSelectedBg: 'rgba(255, 255, 255, 0)',
      nodeHoverBg: 'rgba(255, 255, 255, 0)',
      marginXXS: 8,
    },
    Calendar: {
      controlHeightSM: 40,
      paddingXXS: 1.2,
    },
    Collapse: {
      headerBg: 'rgb(24,24,27)',
    },
    Tag: {
      'magenta-1': 'rgba(80, 7, 36, 1)',
      'magenta-3': 'rgba(157, 23, 77, 1)',
      'magenta-7': 'rgba(236, 72, 153, 1)',
      'red-1': 'rgba(69, 10, 10, 1)',
      'red-3': 'rgba(153, 27, 27, 1)',
      'red-7': 'rgba(239, 68, 68, 1)',
      'volcano-1': 'rgba(76, 5, 25, 1)',
      'volcano-3': 'rgba(159, 18, 57, 1)',
      'volcano-7': 'rgba(244, 63, 94, 1)',
      'orange-1': 'rgba(67, 20, 7, 1)',
      'orange-3': 'rgba(154, 52, 18, 1)',
      'orange-7': 'rgba(249, 115, 22, 1)',
      'gold-1': 'rgba(66, 32, 6, 1)',
      'gold-3': 'rgba(133, 77, 14, 1)',
      'gold-7': 'rgba(202, 138, 4, 1)',
      'lime-1': 'rgba(26, 46, 5, 1)',
      'lime-3': 'rgba(63, 98, 18, 1)',
      'lime-7': 'rgba(132, 204, 22, 1)',
      'green-1': 'rgba(2, 44, 34, 1)',
      'green-3': 'rgba(6, 95, 70, 1)',
      'green-7': 'rgba(16, 185, 129, 1)',
      'cyan-1': 'rgba(8, 51, 68, 1)',
      'cyan-3': 'rgba(21, 94, 117, 1)',
      'cyan-7': 'rgba(6, 182, 212, 1)',
      'blue-1': 'rgba(23, 37, 84, 1)',
      'blue-3': 'rgba(30, 67, 175, 1)',
      'blue-7': 'rgba(59, 130, 246, 1)',
      'geekblue-1': 'rgba(30, 27, 75, 1)',
      'geekblue-3': 'rgba(55, 48, 163, 1)',
      'geekblue-7': 'rgba(99, 102, 241, 1)',
      'purple-1': 'rgba(59, 7, 100, 1)',
      'purple-3': 'rgba(107, 33, 168, 1)',
      'purple-7': 'rgba(168, 85, 247, 1)',
    },
    Result: {
      marginXS: 4,
      titleFontSize: 16,
      paddingLG: 20,
    },
    Switch: {
      trackMinWidth: 40,
    },
    TreeSelect: {
      nodeSelectedBg: 'rgb(229,231,235)',
    },
    Upload: {
      colorFillAlter: 'rgb(24,24,27)',
    },
    Timeline: {
      itemPaddingBottom: 40,
      dotBorderWidth: 2,
      tailWidth: 1,
    },
    Typography: {
      colorLink: 'rgb(32,169,179)',
    },
  },
  algorithm: theme.darkAlgorithm,
}
