import { KBDivider } from '@/components/atoms/KBDivider'
import { KBFlex } from '@/components/atoms/KBFlex'
import { Input, InputRef, Select, SelectProps } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import { ChevronDown, Search } from 'lucide-react'

interface Option {
  value: string | number
  label: string
}

export interface KBSelectProps extends SelectProps {
  /**
   * 模式，与antd select的模式一致
   */
  mode?: 'multiple' | 'tags'
  /**
   * 是否显示搜索框，如果是，可以通过searchFn来自定义搜索逻辑。否则默认搜索label
   */
  showSearchInput?: boolean

  /**
   * 是否动态搜索。静态搜索时，主动获取所有用户，然后进行搜索。动态搜索时，每次输入都会请求后端。当预计用户数量较多时，建议使用动态搜索。
   */
  remoteSearch?: boolean
  /**
   *
   * @param value
   * @param callback
   * @returns
   */
  remoteSearchFn?: (value: string, callback?: (data: Option[]) => void) => void
  /**
   * 搜索回调
   */
  searchFn?: (option: DefaultOptionType, searchValue: string) => boolean

  /**
   * 输入回车时的回调
   * @param value
   * @returns true if can accept the value and close the dropdown
   */
  onEnter?: (value?: string) => boolean
}

const defaultSearchFn = (option: DefaultOptionType, searchValue: string) => {
  return option.label
    ?.toLocaleString()
    .toLocaleLowerCase()
    .includes(searchValue)
}

/**
 * Select组件，支持搜索
 *
 * showSearchInput: 是否显示搜索框，如果是，可以通过searchFn来自定义搜索逻辑。否则默认搜索label。当搜索label时，label需要是string类型。否则需要自定义searchFn
 *
 * @param props
 * @returns
 */
export function KBSelect(props: KBSelectProps) {
  const {
    options,
    showSearchInput,
    remoteSearch,
    mode,
    onSelect,
    onEnter,
    remoteSearchFn,
    searchFn = defaultSearchFn,
    ...restProps
  } = props
  const inputRef = useRef<InputRef>(null)
  const [searchName, setSearchName] = useState<string | undefined>(undefined)

  const [filteredOptions, setFilteredOptions] = useState(options)

  useEffect(() => {
    if (searchName) {
      if (remoteSearch) {
        const searchNameLowerCase = searchName.toLocaleLowerCase()
        // execute remote search
        remoteSearchFn?.(searchNameLowerCase)
        return
      }
    }
  }, [searchName, remoteSearch, remoteSearchFn])

  useEffect(() => {
    if (searchName && !remoteSearch) {
      const searchNameLowerCase = searchName.toLocaleLowerCase()
      const filtered = options?.filter((option) =>
        searchFn(option, searchNameLowerCase)
      )
      setFilteredOptions(filtered)
    } else {
      setFilteredOptions(options)
    }
  }, [searchName, options])

  // console.log('filteredOptions', filteredOptions)
  return (
    <Select
      size="large"
      dropdownRender={(menu) => (
        <>
          {showSearchInput && (
            <div className="tw-mb-1">
              <KBFlex className="tw-p-1 tw-pl-0">
                <Input
                  className="tw-w-full"
                  placeholder="Search"
                  variant="borderless"
                  prefix={
                    <Search size={16} color="#6B7280" className="tw-mr-1" />
                  }
                  allowClear
                  ref={inputRef}
                  value={searchName}
                  onKeyDown={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    const value = e.target.value
                    setSearchName(value)
                  }}
                  onPressEnter={() => {
                    if (onEnter?.(searchName)) {
                      // clear input
                      setSearchName(undefined)
                    }
                  }}
                />
              </KBFlex>
              <KBDivider className="tw-my-0" />
            </div>
          )}
          {menu}
        </>
      )}
      suffixIcon={<ChevronDown size={16} />}
      options={filteredOptions}
      onDropdownVisibleChange={(open) => {
        if (open && mode !== 'tags') {
          setTimeout(() => {
            inputRef.current?.focus()
          }, 200) // add 200 for the dropdown to open
        }
      }}
      // showSearch={false}
      // onSearch={(value) => {
      //   if (!showSearchInput) {
      //     setSearchName(value)
      //   }
      // }}
      onSelect={(value, option) => {
        setSearchName(undefined)
        onSelect?.(value, option)
      }}
      mode={mode}
      {...restProps}
    ></Select>
  )
}

KBSelect.Option = Select.Option
