import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { KBButton } from '@/components/atoms/KBButton'
import { KBCol } from '@/components/atoms/KBCol'
import { KBFlex } from '@/components/atoms/KBFlex'
import { KBForm } from '@/components/atoms/KBForm'
import { KBInput } from '@/components/atoms/KBInput'
import { KBRow } from '@/components/atoms/KBRow'
import { KBSpace } from '@/components/atoms/KBSpace'
import { KBText } from '@/components/atoms/KBText'
import { KBTitle } from '@/components/atoms/KBTitle'
import { KBTypography } from '@/components/atoms/KBTypography'
import WoxLogo from '~icons/wox-icons/woxLogo.svg'

const Signup: React.FC = () => {
  const { t } = useTranslation()
  const [form] = KBForm.useForm<{ name: string; age: number }>()
  const [isRegistered, SetIsRegistered] = useState(false)

  const TERMS_AND_PRIVACY = {
    terms: `${import.meta.env.VITE_TERMS_URL}`,
    privacy: `${import.meta.env.VITE_PRIVACY_URL}`,
  }

  return (
    <KBFlex
      vertical
      style={{ height: 'calc(100% - 129px)' }}
      gap={24}
      justify="center"
      align="center"
    >
      <WoxLogo />
      {isRegistered ? (
        <KBFlex
          justify="center"
          align="center"
          className="tw-h-3/6 tw-h-[556px] tw-w-[1024px] tw-w-full tw-rounded-xl tw-bg-white tw-p-8"
        >
          <KBSpace
            className="tw-flex tw-w-3/5 tw-flex-col tw-text-center"
            size={0}
          >
            <KBTitle level={2}>{t('signUp.interest.title')}</KBTitle>
            <KBText type="secondary">{t('signUp.interest.text')}</KBText>
          </KBSpace>
        </KBFlex>
      ) : (
        <KBRow justify="center">
          <KBCol
            xs={20}
            sm={20}
            md={20}
            lg={22}
            xl={24}
            className="tw-rounded-xl tw-bg-[var(--ant-color-white)]"
          >
            <KBRow>
              <KBCol
                xs={0}
                sm={0}
                md={0}
                lg={12}
                xl={12}
                className="tw-bg-[var(--wox-color-fill-quinary)] tw-px-[48px] tw-py-[68px]"
              >
                <img src="/registerBg.svg" alt="" />
              </KBCol>
              <KBCol
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                className="tw-px-[48px] tw-pb-[40px] tw-pt-[16px]"
              >
                <KBTitle level={3} className="tw-mb-[8px]">
                  {t('signUp.isRegister.title')}
                </KBTitle>
                <KBText type="secondary">{t('signUp.isRegister.text')}</KBText>
                <KBForm
                  form={form}
                  layout="vertical"
                  autoComplete="off"
                  className="tw-pt-[36px]"
                >
                  <KBForm.Item name="name" label={t('common.username')}>
                    <KBInput />
                  </KBForm.Item>
                  <KBRow gutter={24}>
                    <KBCol span={12}>
                      <KBForm.Item label={t('common.firstName')}>
                        <KBInput />
                      </KBForm.Item>
                    </KBCol>
                    <KBCol span={12}>
                      <KBForm.Item label={t('common.lastName')}>
                        <KBInput />
                      </KBForm.Item>
                    </KBCol>
                  </KBRow>
                  <KBForm.Item
                    name="name"
                    label={t('register.form.companyName')}
                  >
                    <KBInput size="large" />
                  </KBForm.Item>
                  <KBForm.Item name="name">
                    <KBButton
                      size="large"
                      type="primary"
                      htmlType="submit"
                      className="tw-w-full"
                      onClick={() => {
                        SetIsRegistered(!isRegistered)
                      }}
                    >
                      {t('common.trial')}
                    </KBButton>
                  </KBForm.Item>
                  <KBFlex>
                    <KBText>{t('common.loginText')}</KBText>{' '}
                    <KBTypography.Link>
                      {t('common.loginText')}
                    </KBTypography.Link>
                  </KBFlex>

                  <KBText type="secondary">
                    <Trans
                      i18nKey="register.form.desc"
                      components={{
                        a: (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className="tw-text-[var(--wox-color-primary)]"
                          />
                        ),
                      }}
                      values={{
                        terms_url: TERMS_AND_PRIVACY['terms'],
                        terms: t('login.authorizationStatement'),
                        privacy_url: TERMS_AND_PRIVACY['privacy'],
                        privacy: t('login.privacyProtection'),
                      }}
                    />
                  </KBText>
                </KBForm>
              </KBCol>
            </KBRow>
          </KBCol>
        </KBRow>
      )}
    </KBFlex>
  )
}

export default Signup
