import React from 'react'
import { useTranslation } from 'react-i18next'

import { ReservationShowEntity } from '@/api'
import { KBForm, KBIcon, KBSelect, KBText, KBTooltip } from '@/components/atoms'
import { KBResourceReservationFormProps } from '@/components/molecules/KBResourceReservationForm/KBResourceReservationForm'

interface ReminderSelectProps {
  resourcesList: KBResourceReservationFormProps['resourcesList']
  reservationInfo?: ReservationShowEntity
  errorsMessages?: string
}
/**
 * 预定提醒组件
 */
const ReminderSelect: React.FC<ReminderSelectProps> = (props) => {
  const { t } = useTranslation()
  const { resourcesList, reservationInfo, errorsMessages } = props

  const ReminderOptions = [
    {
      value: 0,
      label: <KBText>{t('reservation.reminder.noReminder')} </KBText>,
    },
    {
      value: 5,
      label: (
        <KBText>{t('reservation.reminderMinutes', { minute: 5 })} </KBText>
      ),
    },
    {
      value: 10,
      label: (
        <KBText>{t('reservation.reminderMinutes', { minute: 10 })} </KBText>
      ),
    },
    {
      value: 15,
      label: (
        <KBText>{t('reservation.reminderMinutes', { minute: 15 })} </KBText>
      ),
    },
    {
      value: 30,
      label: (
        <KBText>{t('reservation.reminderMinutes', { minute: 30 })} </KBText>
      ),
    },
    {
      value: 60,
      label: <KBText>{t('reservation.reminderHours', { hour: 1 })} </KBText>,
    },
  ]

  const showReminder = useMemo(() => {
    return (resourcesList || []).some((i) => {
      const rules = i.reservation_reserve_rule?.rules
      return rules?.reminder
    })
  }, [resourcesList])

  return showReminder ? (
    <KBForm.Item
      name="reminderBefore"
      label={
        <KBTooltip title={t('common.reminder')}>
          <KBIcon name="alarm-clock" size={24} />
        </KBTooltip>
      }
      dependencies={['resourceIds']}
      validateStatus={Boolean(errorsMessages?.length) ? 'error' : undefined}
      help={errorsMessages}
    >
      <KBSelect options={ReminderOptions} className="tw-w-full" />
    </KBForm.Item>
  ) : null
}

export default ReminderSelect
