// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { VisitorSignInMutationRequest, VisitorSignInMutationResponse, VisitorSignInPathParams, VisitorSignIn401, VisitorSignIn406, VisitorSignIn409 } from "../models/VisitorSignIn";
import type { UseMutationOptions } from "@tanstack/react-query";

 type VisitorSignInClient = typeof client<VisitorSignInMutationResponse, VisitorSignIn401 | VisitorSignIn406 | VisitorSignIn409, VisitorSignInMutationRequest>;
type VisitorSignIn = {
    data: VisitorSignInMutationResponse;
    error: VisitorSignIn401 | VisitorSignIn406 | VisitorSignIn409;
    request: VisitorSignInMutationRequest;
    pathParams: VisitorSignInPathParams;
    queryParams: never;
    headerParams: never;
    response: VisitorSignInMutationResponse;
    client: {
        parameters: Partial<Parameters<VisitorSignInClient>[0]>;
        return: Awaited<ReturnType<VisitorSignInClient>>;
    };
};
/**
 * @summary 签到访客
 * @link /api/v5/visitors/:id/sign-in
 */
export function useVisitorSignIn(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<VisitorSignIn["response"], VisitorSignIn["error"], {
        id: VisitorSignInPathParams["id"];
        data?: VisitorSignIn["request"];
    }>;
    client?: VisitorSignIn["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({ id, data }) => {
            const res = await client<VisitorSignIn["data"], VisitorSignIn["error"], VisitorSignIn["request"]>({
                method: "patch",
                url: `/api/v5/visitors/${id}/sign-in`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "visitors"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}