import { useTranslation } from 'react-i18next'
import { KBButton, KBFlex, KBLayout, KBText, KBTitle } from '@/components/atoms'

// 目前没有国际化
const EXCEPTIONS = {
  '403': {
    title: '403',
    desc: 'You are not authorized to access this page',
    img: '/exception/403.svg',
  },
  '404': {
    title: '404',
    desc: 'The page you visited does not exist',
    img: '/exception/404.svg',
  },
  '500': {
    title: '500',
    desc: 'An error occurred while processing your request',
    img: '/exception/500.svg',
  },
}

interface ExceptionProps {
  type: keyof typeof EXCEPTIONS
}

function Exception(props: ExceptionProps) {
  const { t } = useTranslation()
  const { type } = props
  const navigate = useNavigate()
  const [params] = useSearchParams()
  // const isAdmin = params.get('user') === '0' || isAdminClient()

  const { title, desc, img } = EXCEPTIONS[type]

  return (
    <KBLayout>
      {/* <KBLayout.Header className="tw-bg-[var(--wox-color-bg-layout)] tw-text-right">
          <KBLanguageDropdown />
         </KBLayout.Header> */}
      <KBLayout.Content className="tw-flex tw-h-[calc(100vh-64px)] tw-items-center tw-justify-center tw-px-2.5">
        <KBFlex
          vertical
          className="tw-w-full"
          justify="center"
          align="center"
          gap={20}
        >
          <img src={img} />
          <KBFlex vertical gap={4} align="center">
            <KBTitle level={4}>{title}</KBTitle>
            <KBText className="tw-text-[var(--wox-color-text-description)]">
              {desc}
            </KBText>
          </KBFlex>

          <div>
            <KBButton
              type="primary"
              onClick={() => {
                navigate('/user/dashboard')
              }}
            >
              {t('common.goHome')}
            </KBButton>
          </div>
        </KBFlex>
      </KBLayout.Content>
    </KBLayout>
  )
}

export default Exception
