import { API_VERSION } from '@/utils/constants'
import { useQueryClient } from '@tanstack/react-query'

/**
 * 使缓存失效, 重新请求数据
 * 
 * @param queryKeyPart - 请求的key, 会拼接在`api/v5`之后, 通常是模块名，例如`tasks`
 *
 * @example
 * ```tsx
    import React from 'react';
    import useInvalidate from './hooks/useInvalidate';

    const SomeComponent: React.FC = () => {
      const invalidateTasks = useInvalidate('tasks');

      const handleInvalidate = () => {
        invalidateTasks();
      };

      return (
        <button onClick={handleInvalidate}>Invalidate Tasks</button>
      );
    };

    export default SomeComponent;
 * ```
 * @returns
 */
const useInvalidate = (queryKeyPart: unknown | unknown[]) => {
  const queryClient = useQueryClient()

  const invalidate = () => {
    queryClient.invalidateQueries({
      queryKey: [
        'api',
        API_VERSION,
        ...(Array.isArray(queryKeyPart) ? queryKeyPart : [queryKeyPart]),
      ],
    })
  }

  return invalidate
}

export default useInvalidate
