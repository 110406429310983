// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { ResourceReservationAdminCheckoutMutationResponse, ResourceReservationAdminCheckoutPathParams, ResourceReservationAdminCheckout401, ResourceReservationAdminCheckout406, ResourceReservationAdminCheckout409 } from "../models/ResourceReservationAdminCheckout";
import type { UseMutationOptions } from "@tanstack/react-query";

 type ResourceReservationAdminCheckoutClient = typeof client<ResourceReservationAdminCheckoutMutationResponse, ResourceReservationAdminCheckout401 | ResourceReservationAdminCheckout406 | ResourceReservationAdminCheckout409, never>;
type ResourceReservationAdminCheckout = {
    data: ResourceReservationAdminCheckoutMutationResponse;
    error: ResourceReservationAdminCheckout401 | ResourceReservationAdminCheckout406 | ResourceReservationAdminCheckout409;
    request: never;
    pathParams: ResourceReservationAdminCheckoutPathParams;
    queryParams: never;
    headerParams: never;
    response: ResourceReservationAdminCheckoutMutationResponse;
    client: {
        parameters: Partial<Parameters<ResourceReservationAdminCheckoutClient>[0]>;
        return: Awaited<ReturnType<ResourceReservationAdminCheckoutClient>>;
    };
};
/**
 * @summary checkout reservation
 * @link /api/v5/admin/resource-reservations/:id/checkout
 */
export function useResourceReservationAdminCheckout(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<ResourceReservationAdminCheckout["response"], ResourceReservationAdminCheckout["error"], {
        id: ResourceReservationAdminCheckoutPathParams["id"];
        data?: ResourceReservationAdminCheckout["request"];
    }>;
    client?: ResourceReservationAdminCheckout["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({ id }) => {
            const res = await client<ResourceReservationAdminCheckout["data"], ResourceReservationAdminCheckout["error"], ResourceReservationAdminCheckout["request"]>({
                method: "patch",
                url: `/api/v5/admin/resource-reservations/${id}/checkout`,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "admin", "resource-reservations"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}