import { atom } from 'jotai'
import { atomWithStorage, createJSONStorage } from 'jotai/utils'

import {
  AuthUserEntity,
  RoleEntityWithPermissions,
  SimpleSpaceEntity,
} from '@/api'
import { locationAtom } from '@/store/location.atom'

/**
 * 当前空间
 */
export const currentSpaceAtom = atomWithStorage<SimpleSpaceEntity | undefined>(
  'currentSpace',
  undefined,
  createJSONStorage(),
  {
    getOnInit: true,
  }
)

/**
 * 当前空间的ID
 */
export const currentSpaceIdAtom = atom<number | undefined>((get) => {
  return get(currentSpaceAtom)?.id
})

/**
 * 当前空间的级别,分为Basic, Premium, Enterprise版本
 */
export const currentSpaceLevelAtom = atom<
  SimpleSpaceEntity['level'] | undefined
>((get) => {
  return get(currentSpaceAtom)?.level
})

/**
 * 是否有高级版权限
 */
export const hasPremiumAtom = atom<boolean>((get) => {
  return (
    get(currentSpaceLevelAtom) === 'Premium' ||
    get(currentSpaceLevelAtom) === 'Enterprise'
  )
})

/**
 * 当前分店ID
 */
export const currentLocationIdAtom = atom<number | undefined>((get) => {
  return get(locationAtom)?.id
})

/**
 * 当前用户的Token
 */
export const currentUserTokenAtom = atomWithStorage<string | undefined>(
  'currentUserToken',
  undefined,
  createJSONStorage(),
  {
    getOnInit: true,
  }
)

/**
 * 当前用户的RefreshToken
 */
export const currentRefreshTokenAtom = atomWithStorage<string | undefined>(
  'currentRefreshToken',
  undefined,
  createJSONStorage(),
  {
    getOnInit: true,
  }
)

/**
 * 当前用户的SessionToken
 */
export const currentSessionTokenAtom = atomWithStorage<string | undefined>(
  'currentSessionToken',
  undefined,
  createJSONStorage(),
  {
    getOnInit: true,
  }
)

/**
 * 是否已经登录
 */
export const isAuthenticatedAtom = atom<boolean>((get) => {
  return get(currentUserTokenAtom) !== undefined
})

export const logoutAtom = atom(null, (_, set) => {
  // Clear the authentication tokens
  set(currentUserTokenAtom, undefined)
  set(currentRefreshTokenAtom, undefined)
  set(currentSessionTokenAtom, undefined)
  set(currentUserDataAtom, undefined)
  set(currentRolesAtom, [])
  set(currentSpaceAtom, undefined)
  set(locationAtom, undefined)
  // Perform any additional cleanup if necessary
})

/**
 * 当前用户数据
 */
export const currentUserDataAtom = atomWithStorage<AuthUserEntity | undefined>(
  'currentUserData',
  undefined
)

/**
 * 当前用户ID
 */
export const currentUserIdAtom = atom<number | undefined>((get) => {
  const currentUserData = get(currentUserDataAtom)
  return currentUserData?.id
})

/**
 * Current user roles
 */
export const currentRolesAtom = atomWithStorage<RoleEntityWithPermissions[]>(
  'currentRoles',
  [],
  createJSONStorage(),
  {
    getOnInit: true,
  }
)

/**
 * 当前用户的权限集合。如果是分店，返回分店和空间的权限集合。如果是空间，只返回空间的权限集合。
 *
 * Return in the format of ['subject.action', ...]
 */
export const currentPermissionsAtom = atom<string[]>((get) => {
  const roles = get(currentRolesAtom)
  const currentLocationId = get(currentLocationIdAtom)

  return roles
    .filter(
      (role) => role.location_id === currentLocationId || !role.location_id
    )
    .flatMap((role) =>
      role.permissions.map(
        (permission) => `${permission.subject}:${permission.action}`
      )
    )
})

/**
 * 有其中之一的权限
 */
export const hasPermissionsAtom = atom(
  (get) => (requiredPermissions: string[]) => {
    const currentPermissions = get(currentPermissionsAtom)
    return requiredPermissions.some((permission) =>
      currentPermissions.includes(permission)
    )
  }
)

/**
 * 当前用户是否有任何管理端权限
 */
export const hasAdminRoleAtom = atom<boolean>((get) => {
  const roles = get(currentRolesAtom)
  return roles?.length > 0
})
