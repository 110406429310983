import {
  ReservationShowEntity,
  ResourceEntity,
  SimpleResourceWithPolicyEntity,
} from '@/api'
import { KBButton, KBCheckbox, KBFlex, KBText } from '@/components/atoms'
import { cn } from '@/utils/tailwind.helper'
import NiceModal from '@ebay/nice-modal-react'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

interface ReservationFormFooterProps {
  resourcesList: (ResourceEntity | SimpleResourceWithPolicyEntity)[]
  reservationInfo?: ReservationShowEntity
  onOk: (agreeErrored?: boolean, isAgree?: boolean) => void
  onCancel: () => void
}

const ReservationFormFooter: React.FC<ReservationFormFooterProps> = (props) => {
  const { reservationInfo, resourcesList, onOk, onCancel } = props

  const { t } = useTranslation()
  const [isAgree, setIsAgree] = useState<boolean>(false)
  const [isWarning, setIsWarning] = useState<boolean>(false)

  const rules = resourcesList[0].reservation_reserve_rule.rules
  const isShowAgreeCheckbox = rules.notice_enable && rules.notice
  const checkRequired = Boolean(isShowAgreeCheckbox && rules.notice_sign)

  const showRuleModal = () => {
    NiceModal.show('SchedulerReservationNoticeModal', {
      data: reservationInfo?.resource?.reservation_reserve_rule.rules || rules,
    })
  }

  const handleClickOk = () => {
    const warning = !isAgree && checkRequired
    setIsWarning(warning)
    onOk(warning, isAgree)
  }
  console.log('reservationInfo', reservationInfo)

  useEffect(() => {
    if (reservationInfo?.is_check_protocol) {
      setIsAgree(true)
    }
  }, [reservationInfo])

  return (
    <KBFlex justify="space-between" align="center">
      {isShowAgreeCheckbox ? (
        <KBCheckbox
          checked={isAgree}
          onChange={(e) => {
            const { checked } = e.target
            setIsAgree(checked)
            setIsWarning(checked ? false : checkRequired)
          }}
          disabled={!!reservationInfo}
        >
          <KBText
            weight="middle"
            className={cn(isWarning && 'tw-text-[var(--wox-color-error)]')}
          >
            <Trans
              i18nKey="common.iAgreeToTheReservationInstructions"
              components={{
                Link: (
                  <KBText
                    weight="bold"
                    className={cn(
                      isWarning
                        ? 'tw-text-[var(--wox-color-error)]'
                        : 'tw-text-[var(--wox-color-primary)]'
                    )}
                    onClick={showRuleModal}
                  />
                ),
              }}
            />
          </KBText>
        </KBCheckbox>
      ) : (
        <div />
      )}

      <KBFlex gap={8} align="center">
        <KBButton type="text" onClick={onCancel}>
          {t('common.cancel')}
        </KBButton>
        <KBButton type="primary" onClick={handleClickOk}>
          {t('common.save')}
        </KBButton>
      </KBFlex>
    </KBFlex>
  )
}

export default ReservationFormFooter
