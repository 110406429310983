import { useTranslation } from 'react-i18next'
import React from 'react'

import { VisitorEntityUnifiedStatus } from '@/api'
import { IconName, KBIcon } from '@/components/atoms'
import { VisitorUnifiedStatusEnum } from '@/enums/modelEnums'
import { cn } from '@/utils/tailwind.helper'
import { Tooltip } from 'antd'

interface VisitorStateIconProps {
  visitorState?: VisitorEntityUnifiedStatus
  showTooltip?: boolean
}

const VisitorStateIcons = {
  [VisitorUnifiedStatusEnum.signed_in]: 'check',
  [VisitorUnifiedStatusEnum.signed_out]: 'check',
  [VisitorUnifiedStatusEnum.approving]: 'loader-circle',
  [VisitorUnifiedStatusEnum.cancelled]: 'x',
  [VisitorUnifiedStatusEnum.waiting_for_acceptance]: 'ellipsis',
  [VisitorUnifiedStatusEnum.waiting_for_sign]: 'ellipsis',
}

const VisitorStateIcon: React.FC<VisitorStateIconProps> = ({
  visitorState,
  showTooltip,
}) => {
  const { t } = useTranslation()
  const state = visitorState as VisitorUnifiedStatusEnum
  const element = (
    // 外边包一层div 解决闪动问题
    <div>
      <KBIcon
        size={14}
        name={(VisitorStateIcons[state] || 'x') as IconName}
        className={cn(
          'tw-flex tw-justify-center tw-rounded-[50px] tw-align-middle tw-text-white tw-opacity-80 hover:tw-opacity-100',
          {
            'tw-bg-[var(--wox-color-state-icon-yellow)]':
              state === VisitorUnifiedStatusEnum.approving ||
              state === VisitorUnifiedStatusEnum.waiting_for_sign,
            'tw-bg-[var(--wox-color-state-icon-emerald)]':
              state === VisitorUnifiedStatusEnum.signed_in,
            'tw-bg-[var(--wox-color-state-icon-blue)]':
              state === VisitorUnifiedStatusEnum.waiting_for_acceptance,
            'tw-bg-[var(--wox-color-state-icon-gray)]':
              state === VisitorUnifiedStatusEnum.signed_out ||
              state === VisitorUnifiedStatusEnum.cancelled,
          }
        )}
      />
    </div>
  )

  return showTooltip ? (
    <Tooltip title={t(`state.${state}`)}>{element}</Tooltip>
  ) : (
    element
  )
}

export default VisitorStateIcon
