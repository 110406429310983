import { KBButton, KBFlex } from '@/components/atoms'
import {
  getWeekDayInMonthIndex,
  RecurrenceByMonthInterval,
} from '@/components/organisms/KBRecurrenceEditModal/constants'
import {
  KBRecurrenceEditModalProps,
  RecurrenceInfo,
} from '@/components/organisms/KBRecurrenceEditModal/KBRecurrenceEditModal'
import { localeAtom } from '@/store'
import dayjs, { nameOfDay } from '@wox/dayjs'
import { useAtom } from 'jotai'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

interface KBRecurrenceByMonthProps extends KBRecurrenceEditModalProps {
  recurrenceInfo: RecurrenceInfo
  setRecurrenceInfo: (info: RecurrenceInfo) => void
}

const KBRecurrenceByMonth: FunctionComponent<KBRecurrenceByMonthProps> = (
  props
) => {
  const { t } = useTranslation()
  const { recurrenceInfo, setRecurrenceInfo } = props
  const startTime = recurrenceInfo.startDate || dayjs()
  const [locale] = useAtom(localeAtom)
  return (
    <KBFlex justify="flex-start" wrap className="tw-mt-3 tw-pl-[88px]" gap={6}>
      <KBButton
        type={
          recurrenceInfo.monthRecurrenceByMonthInterval ===
          RecurrenceByMonthInterval.day
            ? 'primary'
            : 'text'
        }
        className={
          recurrenceInfo.monthRecurrenceByMonthInterval ===
          RecurrenceByMonthInterval.day
            ? ''
            : 'tw-bg-[var(--wox-color-fill-tertiary)]'
        }
        onClick={() => {
          // setAllValues({
          //   ...allValues,
          //   monthDay: startTime.date(),
          //   monthWeekNumber: undefined,
          //   monthWeekDay: undefined,
          // })
          setRecurrenceInfo({
            ...recurrenceInfo,
            monthDay: startTime.date(),
            monthWeekNumber: undefined,
            monthWeekDay: undefined,
            intervalType: recurrenceInfo.intervalType,
            monthRecurrenceByMonthInterval: RecurrenceByMonthInterval.day,
          })
        }}
      >
        {t('recurrence.monthDay', { date: startTime.date() })}
      </KBButton>
      <KBButton
        type={
          recurrenceInfo.monthRecurrenceByMonthInterval ===
          RecurrenceByMonthInterval.week
            ? 'primary'
            : 'text'
        }
        className={
          recurrenceInfo.monthRecurrenceByMonthInterval ===
          RecurrenceByMonthInterval.week
            ? ''
            : 'tw-bg-[var(--wox-color-fill-tertiary)]'
        }
        onClick={() => {
          setRecurrenceInfo({
            ...recurrenceInfo,
            monthDay: undefined,
            monthWeekNumber: getWeekDayInMonthIndex(startTime),
            monthWeekDay: startTime.day(),
            intervalType: recurrenceInfo.intervalType,
            monthRecurrenceByMonthInterval: RecurrenceByMonthInterval.week,
          })
        }}
      >
        {t('recurrence.monthWeekDay', {
          no: getWeekDayInMonthIndex(startTime),
          weekDay: nameOfDay(startTime.day(), {
            weekdayFormat: 'short',
            locale,
          }),
        })}
      </KBButton>
    </KBFlex>
  )
}

export default KBRecurrenceByMonth
