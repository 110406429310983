import { QueryCache, QueryClient } from '@tanstack/react-query'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { isUserClient } from '@/utils/userEnvironment'
// import { useLogout } from '../store';

export const useReactQueryClient = () => {
  const navigate = useNavigate()
  // const logout = useLogout()

  return React.useRef(
    new QueryClient({
      queryCache: new QueryCache({
        // eslint-disable-next-line typescriptEslint/no-explicit-any
        onError: (error: any) => {
          if (error?.response?.status === 401) {
            // global intercept 401 and redirect to login page
            const redirectUri = window.location.href
            window.location.assign(
              `/login?error=access_denied&redirect_uri=${encodeURIComponent(
                redirectUri
              )}`
            )
          } else if (error?.response?.status === 403) {
            navigate(`/exceptions/403?user=${isUserClient() ? 1 : 0}`)
          } else if (error?.response?.status === 404) {
            navigate(`/exceptions/404?user=${isUserClient() ? 1 : 0}`)
          }
        },
      }),
      defaultOptions: {
        queries: {
          refetchOnReconnect: true, // refetch data when reconnect
          refetchOnWindowFocus: true, // refetch data when window focus
          // staleTime: 0, // default keep data for 15 minutes
          // cacheTime: 1000 * 60 * 20, // default keep data for 20 minutes
          retry: false,
          // eslint-disable-next-line typescriptEslint/no-explicit-any
          // (failureCount, error: any) => {
          //   if (error?.response?.status === 404) {
          //     return false
          //   }
          //   const retryCount = isProdEnv ? 3 : 0
          //   return failureCount < retryCount
          // }
        },
      },
    })
  )
}
