// @ts-nocheck
import client from "../../utils/axios";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { UserBookFindManyQueryResponse, UserBookFindManyQueryParams, UserBookFindMany401 } from "../models/UserBookFindMany";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type UserBookFindManyClient = typeof client<UserBookFindManyQueryResponse, UserBookFindMany401, never>;
type UserBookFindMany = {
    data: UserBookFindManyQueryResponse;
    error: UserBookFindMany401;
    request: never;
    pathParams: never;
    queryParams: UserBookFindManyQueryParams;
    headerParams: never;
    response: UserBookFindManyQueryResponse;
    client: {
        parameters: Partial<Parameters<UserBookFindManyClient>[0]>;
        return: Awaited<ReturnType<UserBookFindManyClient>>;
    };
};
export const userBookFindManyQueryKey = (params?: UserBookFindMany["queryParams"]) => ["api", "v5", "user_books", ...(params ? [params] : [])] as const;
export type UserBookFindManyQueryKey = ReturnType<typeof userBookFindManyQueryKey>;
export function userBookFindManyQueryOptions(params?: UserBookFindMany["queryParams"], options: UserBookFindMany["client"]["parameters"] = {}) {
    const queryKey = userBookFindManyQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<UserBookFindMany["data"], UserBookFindMany["error"]>({
                method: "get",
                url: `/api/v5/user_books`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary Find many UserBookEntity with query
 * @link /api/v5/user_books
 */
export function useUserBookFindMany<TData = UserBookFindMany["response"], TQueryData = UserBookFindMany["response"], TQueryKey extends QueryKey = UserBookFindManyQueryKey>(params?: UserBookFindMany["queryParams"], options: {
    query?: Partial<QueryObserverOptions<UserBookFindMany["response"], UserBookFindMany["error"], TData, TQueryData, TQueryKey>>;
    client?: UserBookFindMany["client"]["parameters"];
} = {}): UseQueryResult<TData, UserBookFindMany["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? userBookFindManyQueryKey(params);
    const query = useQuery({
        ...userBookFindManyQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, UserBookFindMany["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const userBookFindManySuspenseQueryKey = (params?: UserBookFindMany["queryParams"]) => ["api", "v5", "user_books", ...(params ? [params] : [])] as const;
export type UserBookFindManySuspenseQueryKey = ReturnType<typeof userBookFindManySuspenseQueryKey>;
export function userBookFindManySuspenseQueryOptions(params?: UserBookFindMany["queryParams"], options: UserBookFindMany["client"]["parameters"] = {}) {
    const queryKey = userBookFindManySuspenseQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<UserBookFindMany["data"], UserBookFindMany["error"]>({
                method: "get",
                url: `/api/v5/user_books`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary Find many UserBookEntity with query
 * @link /api/v5/user_books
 */
export function useUserBookFindManySuspense<TData = UserBookFindMany["response"], TQueryKey extends QueryKey = UserBookFindManySuspenseQueryKey>(params?: UserBookFindMany["queryParams"], options: {
    query?: Partial<UseSuspenseQueryOptions<UserBookFindMany["response"], UserBookFindMany["error"], TData, TQueryKey>>;
    client?: UserBookFindMany["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, UserBookFindMany["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? userBookFindManySuspenseQueryKey(params);
    const query = useSuspenseQuery({
        ...userBookFindManySuspenseQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, UserBookFindMany["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}