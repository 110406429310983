import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { ColumnsType } from 'antd/es/table'
import { Key } from 'react'
import { useTranslation } from 'react-i18next'

import { ResourceEntity, useResourceAdminIndex, useResourceIndex } from '@/api'
import {
  KBFlex,
  KBModal,
  KBTable,
  KBTag,
  KBTypography,
} from '@/components/atoms'
import { KBSearchInput } from '@/components/atoms/KBSearchInput'
import {
  KBResourceCategorySelect,
  KBResourceTypeSelect,
} from '@/components/organisms'
import KBResourceImage from '@/components/organisms/KBResourceImage/KBResourceImage'
import { MAX_PAGINATION_LIMIT } from '@/utils/constants'
import { isUserClient } from '@/utils/userEnvironment'
import { Search } from 'lucide-react'

interface BatchSelectResourceProps {
  onSelectChange?: (selectedRowKeys: Key[]) => void
  selectedKeys?: number[]
  locationId?: number
}

/**
 * 批量选择资源
 * @param props
 * @returns
 */
function BatchSelectResource(props: BatchSelectResourceProps) {
  const { t } = useTranslation()
  const { onSelectChange, selectedKeys, locationId } = props

  const modal = useModal()

  const selectedKeysRef = useRef<Key[]>([])

  const [page, setPage] = useState(1)
  const [searchName, setSearchName] = useState<string | undefined>()
  const [resourceTypeId, setResourceTypeId] = useState<number | undefined>()
  const [resourceCategoryId, setResourceCategoryId] = useState<
    number | undefined
  >()

  const { data: resourceListData, isLoading } = isUserClient()
    ? useResourceIndex({
        page,
        name: searchName,
        resource_type_id: resourceTypeId,
        resource_category_id: resourceCategoryId,
        limit: MAX_PAGINATION_LIMIT,
        location_id: locationId,
      })
    : useResourceAdminIndex({
        page,
        name: searchName,
        resource_type_id: resourceTypeId,
        resource_category_id: resourceCategoryId,
        limit: MAX_PAGINATION_LIMIT,
        location_id: locationId,
      })

  const resources = (resourceListData?.result || []) as ResourceEntity[]

  function onSelect(selectedRowKeys: Key[]) {
    console.log(13, selectedRowKeys, resources)

    selectedKeysRef.current = selectedRowKeys
  }

  function onOk() {
    onSelectChange?.(selectedKeysRef.current)
    modal.hide()
  }

  const columns: ColumnsType<ResourceEntity> = [
    {
      title: '',
      key: 'photo',
      render(record: ResourceEntity) {
        return (
          <KBResourceImage resource={record} disabledOperate={isUserClient()} />
        )
      },
    },
    {
      title: t('intl.name'),
      key: 'name',
      render(record: ResourceEntity) {
        return (
          <KBTypography.Text className={`tw-text-base tw-font-bold`}>
            {`${record.name}`}
          </KBTypography.Text>
        )
      },
    },
    {
      title: t('common.category'),
      key: 'type',
      render(record: ResourceEntity) {
        return <KBTag>{record.resource_type?.name}</KBTag>
      },
    },
    {
      title: t('common.type'),
      key: 'category',
      render(record: ResourceEntity) {
        return <KBTag>{record.resource_category?.name}</KBTag>
      },
    },
    {
      title: t('common.floor'),
      key: 'floor',
      render(record: ResourceEntity) {
        return record.floor_info?.layer_description
      },
    },
  ]

  useEffect(() => {
    selectedKeysRef.current = []
  }, [page])

  return (
    <KBModal
      width={800}
      title={t('common.batchAddResource')}
      modal={modal}
      onOk={onOk}
    >
      <KBFlex justify="space-between" gap={8} className="tw-mb-5">
        <KBFlex gap={8}>
          <KBSearchInput
            placeholder={t('common.searchResource')}
            prefix={<Search size={14} />}
            className="tw-h-9 tw-w-[150px]"
            allowClear
            onChange={(event) => {
              setSearchName(
                event.target.value === '' ? undefined : event.target.value
              )
              setPage(1)
            }}
          />

          <KBResourceTypeSelect
            className="tw-w-[150px]"
            placeholder={t('common.resourceCategory')}
            allowClear
            onChange={(value: number) => {
              setResourceTypeId(value)
              setPage(1)
            }}
          />

          {resourceTypeId && (
            <KBResourceCategorySelect
              className="tw-w-[150px]"
              placeholder={t('common.resourceType')}
              resourceTypeId={resourceTypeId}
              allowClear
              onChange={(value: number) => {
                setResourceCategoryId(value)
                setPage(1)
              }}
            />
          )}
        </KBFlex>
      </KBFlex>
      <div className=" tw-min-h-[450px]">
        <KBTable
          columns={columns}
          enableRowSelection
          dataSource={resources}
          loading={isLoading}
          showSelectAll={false}
          rowSelection={{ onChange: onSelect, selectedRowKeys: selectedKeys }}
        />

        {/* <KBPagination
            current={page}
            total={resourceListData?.meta?.count}
            onChange={setPage}
           /> */}
      </div>
    </KBModal>
  )
}

export default NiceModal.create(BatchSelectResource)
