// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { VisitorAdminApproveVisitorMutationRequest, VisitorAdminApproveVisitorMutationResponse, VisitorAdminApproveVisitorPathParams, VisitorAdminApproveVisitor401 } from "../models/VisitorAdminApproveVisitor";
import type { UseMutationOptions } from "@tanstack/react-query";

 type VisitorAdminApproveVisitorClient = typeof client<VisitorAdminApproveVisitorMutationResponse, VisitorAdminApproveVisitor401, VisitorAdminApproveVisitorMutationRequest>;
type VisitorAdminApproveVisitor = {
    data: VisitorAdminApproveVisitorMutationResponse;
    error: VisitorAdminApproveVisitor401;
    request: VisitorAdminApproveVisitorMutationRequest;
    pathParams: VisitorAdminApproveVisitorPathParams;
    queryParams: never;
    headerParams: never;
    response: VisitorAdminApproveVisitorMutationResponse;
    client: {
        parameters: Partial<Parameters<VisitorAdminApproveVisitorClient>[0]>;
        return: Awaited<ReturnType<VisitorAdminApproveVisitorClient>>;
    };
};
/**
 * @summary 访客审批通过
 * @link /api/v5/admin/visitors/:id/visitor-approval
 */
export function useVisitorAdminApproveVisitor(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<VisitorAdminApproveVisitor["response"], VisitorAdminApproveVisitor["error"], {
        id: VisitorAdminApproveVisitorPathParams["id"];
        data?: VisitorAdminApproveVisitor["request"];
    }>;
    client?: VisitorAdminApproveVisitor["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({ id, data }) => {
            const res = await client<VisitorAdminApproveVisitor["data"], VisitorAdminApproveVisitor["error"], VisitorAdminApproveVisitor["request"]>({
                method: "post",
                url: `/api/v5/admin/visitors/${id}/visitor-approval`,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "admin", "visitors"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}