import {
  useCustomerSubscriptionAdminFindCalculateCount,
  useLocationAdminFindById,
  useVisitorConfigAdminEnableVisitorConfigByLocation,
} from '@/api'
import { KBButton } from '@/components/atoms'
import { kbMessage } from '@/components/atoms/KBMessageGlobal'
import { KBSuspenseLoading } from '@/components/atoms/KBSuspenseLoading'
import KBUpgrade from '@/components/molecules/KBUpgrade/KBUpgrade'
import useCurrentLocationId from '@/hooks/useCurrentLocationId'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const VisitorLayout: React.FC = () => {
  const { t } = useTranslation()
  const currentLocationId = useCurrentLocationId()
  const {
    data: location,
    isLoading,
    refetch,
  } = useLocationAdminFindById(currentLocationId!)

  const { data: purchaseList, isLoading: purchaseLoading } =
    useCustomerSubscriptionAdminFindCalculateCount()

  const { mutate: enableVisitor, isPending } =
    useVisitorConfigAdminEnableVisitorConfigByLocation()

  if (isLoading || purchaseLoading) {
    return <KBSuspenseLoading />
  }
  // 访客未购买
  if (purchaseList?.visitor?.subscribed_count === 0) {
    return (
      <KBUpgrade
        title={t('common.visitorCheckIn')}
        subtitle={t('common.visitorNotSubscribedTitle')}
        description={t('common.visitorNotSubscribedDescription')}
        items={[
          {
            icon: 'leaf',
            itemTile: t('common.visitorItemOneTitle'),
            itemDescription: t('common.visitorItemOneDescription'),
          },
          {
            icon: 'handshake',
            itemTile: t('common.visitorItemTwoTitle'),
            itemDescription: t('common.visitorItemTwoDescription'),
          },
          {
            icon: 'users',
            itemTile: t('common.visitorItemThreeTitle'),
            itemDescription: t('common.visitorItemThreeDescription'),
          },
        ]}
      />
    )
  }

  // 访客已购买就显示下面   如果使用了就显示Outlet
  return (
    <>
      {!location?.visitor_enabled ? (
        <KBUpgrade
          type="visitor"
          title={t('common.visitorTitle')}
          subtitle={t('common.visitorNotUsedTitle')}
          description={t('common.visitorNotUsedDescription')}
          items={[
            {
              icon: 'leaf',
              itemTile: t('common.visitorNotUsedItemOneTitle'),
              itemDescription: t('common.visitorNotUsedItemOneDescription'),
            },
            {
              icon: 'handshake',
              itemTile: t('common.visitorNotUsedItemTwoTitle'),
              itemDescription: t('common.visitorNotUsedItemTwoDescription'),
            },
            {
              icon: 'users',
              itemTile: t('common.visitorNotUsedItemThreeTitle'),
              itemDescription: t('common.visitorNotUsedItemThreeDescription'),
            },
          ]}
          button={
            <KBButton
              type="primary"
              onClick={() => {
                enableVisitor(
                  {
                    params: {
                      location_id: currentLocationId!,
                    },
                  },
                  {
                    onSuccess: () => {
                      kbMessage.success(t('common.updateSuccess'))
                      refetch()
                    },
                  }
                )
              }}
            >
              {t('common.useStart')}
            </KBButton>
          }
        />
      ) : (
        <Outlet />
      )}
    </>
  )
}
